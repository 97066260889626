import { Module } from 'vuex'
import elementState from '@/store/modules/elements/elementState'
import elementGetters from '@/store/modules/elements/elementGetters'
import elementMutations from '@/store/modules/elements/elementMutations'
import elementActions from '@/store/modules/elements/elementActions'
import { EaseStore } from '@/store'
import { EaseElement } from '@/types/interfaces/EaseElement'

export interface ElementStore {
  // Elements
  // currently active Element
  activeElement: EaseElement | null
  // selected element for dataViewer
  selectedElement: EaseElement | null
  activeAttribute?: {
    name: string
    comboUuid?: string
  }
  elements: EaseElement[]
  // number of trashed elements as reported by the API - this may be different from the length of trashedElements
  trashedElementCount: number
  // the elements that are currently being edited in the Entity Relation Builder
  dndEntityEditorElements: EaseElement[]

  // track which elements are awaiting updates
  pendingElementUpdates: string[]
}

export const elementsModule: Module<ElementStore, EaseStore> = {
  state: elementState,
  getters: elementGetters,
  mutations: elementMutations,
  actions: elementActions,
}
