import {
  EaseElement,
  ElementData,
  ElementDataImageArray,
  ElementDataStringObjArray,
} from '@/types/interfaces/EaseElement'
import { PromptSettings } from '@/types/interfaces/General'
import EaseStore from '@/store'

export interface AiGeneratorConfig<InputData, OutputData> {
  generateFn: AiGenerateFn<InputData, OutputData>
  afterGenerate: (outputData: OutputData) => void
}

export type AiGenerateFn<InputData, OutputData> = (
  prompt: string,
  settings: PromptSettings,
  inputData: InputData,
  requestUuid: string
) => Promise<OutputData>

export type AiGenerator<InputData> = (
  prompt: string,
  settings: PromptSettings,
  inputData: InputData,
  requestUuid: string,
  callback?: () => void
) => Promise<void>

export interface InjectGeneratorInput {
  element: EaseElement
  links?: string[]
}

/**
 * Create an AiGenerator which can be passed onto the AiGenerator component
 * @param config
 */
export function createAiGenerator<InputData, OutputData>(
  config: AiGeneratorConfig<InputData, OutputData>
): AiGenerator<InputData> {
  return async (prompt, settings, inputData, requestUuid, callback) => {
    config
      .generateFn(prompt, settings, inputData, requestUuid)
      .then(output => {
        if (callback) callback()
        config.afterGenerate(output)
      })
      .catch(err => {
        EaseStore.dispatch('setAlert', {
          text: 'Could not generate: ' + err,
          type: 'error',
        })
        console.error()
      })
  }
}

export type AiEndpoint = 'entity' | 'document'

export interface ReviewAiInput {
  satisfactory: boolean | null
  title: string
}

// States
export enum Status {
  DIALOG,
  LOADING,
  SETTINGS,
  REVIEW,
  GENERATED,
}

export interface InjectGeneratorInfo {
  prompt: string
  element: EaseElement
  links: string[]
}

export interface InjectGeneratorReview {
  review: { satisfactory: boolean | null; title: string }[]
  settings: PromptSettings
  prompt: string
  content: string | ElementDataStringObjArray[] | ElementDataImageArray[] | ElementData[] | null
  comment: string
  request_uuid: string
}
