import { EaseStore } from '@/store'
import { Module } from 'vuex'
import { fetchExercise, fetchExUsers } from '@/mixins/api/exercises'
import { fetchTemplates } from '@/mixins/api/templates'
import { fetchElements } from '@/mixins/api/elements'
import { fetchBuilderElements } from '@/mixins/api/dndEntityEditor'
import { fetchUser } from '@/mixins/api/users'

export type InitStore = typeof state

const state = {
  loadingProgress: {
    elements: 0,
    templates: 0,
    exercises: 0,
    users: 0,
  },
}

export const initModule: Module<InitStore, EaseStore> = {
  namespaced: true,
  state,
  getters: {
    /**
     * Check if any initial call is still in progress
     * @param state
     */
    isLoading(state) {
      return Object.values(state.loadingProgress).some(p => p < 100)
    },
    /**
     * Get the total loading progress as a percentage
     * @param state
     */
    totalLoadingProgress(state): number {
      const divider = Object.keys(state.loadingProgress).length
      return Object.values(state.loadingProgress).reduce(
        (total: number, progress: number): number => total + progress / divider,
        0
      )
    },
  },
  mutations: {
    setLoadingProgress(
      state,
      { progress, api }: { api: keyof InitStore['loadingProgress']; progress: number }
    ): void {
      state.loadingProgress[api] = progress
    },
  },
  actions: {
    /**
     * Load initial data from the server
     */
    async loadEaseData({ commit }) {
      // callback to update the loading bar state on each request
      const updateLoadingBar =
        (api: string) =>
        (progressEvent: ProgressEvent): void => {
          const httpRequest = progressEvent.target as XMLHttpRequest
          const contentHeader = httpRequest.getResponseHeader('X-Actual-Content-Length')

          if (!contentHeader) {
            throw new Error('X-Actual-Content-Length header value does not exist')
          }

          const total = parseFloat(contentHeader)

          const current = httpRequest.response.length
          commit('setLoadingProgress', {
            api,
            progress: Math.floor((current / total) * 100), // percentage of request completed
          })
        }

      // Gets the userLook up information of the exercise to store in state
      await Promise.all([
        fetchExercise({ onDownloadProgress: updateLoadingBar('exercises') }).then(ex =>
          commit('setEx', ex, { root: true })
        ),
        fetchTemplates({ onDownloadProgress: updateLoadingBar('templates') }).then(templates =>
          commit('setTemplates', templates, { root: true })
        ),
        fetchElements({ onDownloadProgress: updateLoadingBar('elements') }).then(
          elementApiResponse => commit('setElements', elementApiResponse.elements, { root: true })
        ),
        fetchBuilderElements().then(builderElements => {
          commit('setDndEntityEditorElements', builderElements.elements, { root: true })
        }),
        fetchUser({ onDownloadProgress: updateLoadingBar('users') }).then(user =>
          commit('setUser', user, { root: true })
        ),
        fetchExUsers().then(exUsers => commit('setExUsers', exUsers, { root: true })),
      ])
    },
  },
}
