// types
import { ApiExercises, Exercise } from '@/types/interfaces/Exercise'
import { PortalStore } from '@/types/interfaces/Portal'
import { EaseStore } from '@/store'
import { GetterTree } from 'vuex'

const portalGetters: GetterTree<PortalStore, EaseStore> = {
  /*
      ======================================================
                  E X E R C I S E S
      ======================================================
    */
  getExercises: (state): ApiExercises => state.exercises,
  getOwnerExercises: (state): Exercise[] => state.exercises.owned,
  getSharedExercises: (state): Exercise[] => state.exercises.shared,
  getExerciseByUuid:
    state =>
    (exerciseUuid: string): Exercise | null => {
      const exercises = [...state.exercises.owned, ...state.exercises.shared]
      return (
        exercises.find((exercise: Exercise) => exercise?.metadata?.uuid === exerciseUuid) || null
      )
    },

  getActiveEx: (state): Exercise | null => state.ActiveEx,

  /*
      ======================================================
                  S E S S I O N S
      ======================================================
    */
  getPortalLoaded: (state): boolean => state.portalLoaded,
}

export default portalGetters
