
import { Component, Vue, Prop } from 'vue-property-decorator'
import CardTitleBar from '@/components/Common/General/CardTitleBar.vue'

// types
import { ElementData } from '@/types/interfaces/EaseElement'

@Component({ components: { CardTitleBar } })
export default class ImageContent extends Vue {
  @Prop() elementData!: ElementData
  @Prop({ default: '100px' }) width!: string
  @Prop({ default: '100%' }) height!: string

  // dialog for the full images is false be default
  showFullImageDialog = false

  // ZOOM STUFF
  zoom = 1
  x = 0
  y = 0
  startX = 0
  startY = 0

  // the selected image to display in the full image dialog
  selectedImage = ''

  // display the full image in a dialog
  displayFullImage(image: string): void {
    // set the selected image to the image clicked and open the dialog
    this.showFullImageDialog = true
    this.selectedImage = image
  }

  handleScroll(event: WheelEvent): void {
    if (event.deltaY > 0) {
      this.zoom -= 0.25
    } else {
      this.zoom += 0.25
    }
  }

  // init the zoom
  zoomIn(mouseEvent: MouseEvent): void {
    this.startX = mouseEvent.clientX
    this.startY = mouseEvent.clientY
  }

  // reset the zoom
  zoomOut(): void {
    this.startX = 0
    this.startY = 0
  }

  // drag the image
  drag(mouseEvent: MouseEvent): void {
    if (this.startX || this.startY) {
      this.x += mouseEvent.clientX - this.startX
      this.y += mouseEvent.clientY - this.startY
      this.startX = mouseEvent.clientX
      this.startY = mouseEvent.clientY
    }
  }
}
