// types
import { Exercise, ExerciseSettings } from '@/types/interfaces/Exercise'

// store
import Store from '@/store'

// npm
import { AxiosRequestConfig } from 'axios'
import { saveAs } from 'file-saver'

import { getDateInFormat } from '@/mixins/general/converters'

import { User } from '@/types/interfaces/General'
import { easeApi } from '@/api/ease.api'

/*
      ======================================================
                  A P I  -   E X E R C I S E S
      ======================================================
*/

/**
 * Get the current exercise from EASE API
 */
export const fetchExercise = async (config?: AxiosRequestConfig): Promise<Exercise> => {
  return easeApi.get<Exercise>('exercise', config).then(res => res.data)
}

/*
      ======================================================
                 H E L P E R S  -   E X E R C I S E S
      ======================================================
*/

/**
 * Submit update exercise settings fields to the API and update them
 * in state
 */
export const submitUpdateExerciseSettings = (
  existingExSettings: ExerciseSettings,
  newExSettings: ExerciseSettings
): void => {
  easeApi
    .put('exercises/settings/update', {
      ...existingExSettings,
      ...newExSettings,
    })
    .then(() => {
      // Update ex settings to the store
      Store.state.exercise.ex.settings = {
        ...existingExSettings,
        ...newExSettings,
      }
      // close modal
      Store.dispatch('setCloseModal')
      // send an alert that the ex setting was successful
      Store.dispatch('setAlert', {
        text: 'ExSettings Updated Successfully!',
        type: 'success',
      })
    })
    .catch(err => {
      console.error('Error Validating Ex Settings', err)
      // send an alert that the ex setting failed
      Store.dispatch('setAlert', {
        text: 'Updating ExSettings Failed!',
        type: 'error',
      })
    })
}

/**
 * Update excon settings
 */

export const updateExconSettings = (
  exerciseUUID: string,
  exconSettings: { email: string; uuid: string }[]
) => {
  return easeApi
    .put(`exercise/${exerciseUUID}/excon-settings`, {
      exconSettings,
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.error('Error Validating Ex Settings', err)
      Store.dispatch('setAlert', {
        text: 'Excon Update Failed!',
        type: 'error',
      })
    })
}

/**
 * Update a key of the exericise in state
 */
export const downloadAllExFiles = (): void => {
  Store.dispatch('setAlert', {
    text: 'Assembling Exercise Files: Please be patient...',
    type: 'success',
  })

  let filename
  const date = new Date().toISOString()
  const dateString = getDateInFormat(date, 'yyMMdd-HHmm')

  Store.dispatch('setAlert', {
    text: 'Downloading Files... Please Wait',
    type: 'info',
  })
  easeApi
    .get('files', { responseType: 'blob' })
    .then(res => {
      filename = `${dateString}-${res.headers['x-filename']}`
      return res.data
    })
    .then(res => {
      saveAs(res, filename)
      Store.dispatch('setAlert', {
        text: 'Downloading Files Complete!',
        type: 'success',
      })
    })
    .catch(err => {
      console.warn(err)
      Store.dispatch('setAlert', {
        text: 'Downloading Files Failed!',
        type: 'error',
      })
    })
}

export const fetchExUsers = async (): Promise<User[]> => {
  return await easeApi.get('users/lookup').then(res => res.data)
}
