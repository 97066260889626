import { Notification } from '@/modules/notifications/model/notification-model'
import { easeApi } from '@/api/ease.api'

/*
    ======================================================
                          G E T
    ======================================================

  */

export const api = {
  async fetchAllNotifications() {
    const response = await easeApi.get<Notification[]>('notifications')
    return {
      notifications: response.data.sort((a, b): number => {
        return b.created > a.created ? 1 : -1
      }),
    }
  },

  /*
    ======================================================
                        C R E A T E
    ======================================================

  */

  async createNewNotification(): Promise<void> {
    // Random Text
    const randomStrings: string[] = ['Hello World', 'Random Text', 'Testing', 'Placeholder text']

    // Generic Notification Types
    const notifTypes: string[] = ['info', 'success', 'warning', 'error']

    // message
    const randMessage: string = randomStrings[Math.floor(Math.random() * randomStrings.length)]

    // type
    const randType: string = notifTypes[Math.floor(Math.random() * notifTypes.length)]
    await easeApi.put('notifications/genRandom', {
      data: {
        rand_message: randMessage,
        rand_type: randType,
      },
    })
  },

  /*
    ======================================================
                        U P D A T E
    ======================================================

  */

  /**
   * Get all of the current users previously accepted notifications from EASE API
   */
  async getAcceptedNotifications(): Promise<Notification[]> {
    return easeApi.get<{ notifications: Notification[] }>('notifications/accepted').then(res => {
      const responseData: Notification[] = res.data.notifications
      // change each date to localString
      responseData.forEach(notification => {
        const date = new Date(notification.created)
        notification.created = date.toLocaleString('en-US')
      })
      return responseData
    })
  },

  /**
   * Set the selected notifcation to read
   */
  async ackOne(notificationUUID: string): Promise<void> {
    // getUUIDNotificationAck(event)
    await easeApi.put(`notifications/${notificationUUID}`)
  },

  /**
   * Set all of the current users notifications to read
   */
  async ackAll(): Promise<void> {
    await easeApi.put('notifications/all')
  },
}
