
import { Component, Prop, Vue } from 'vue-property-decorator'

// T Y P E S
import { Notification } from '@/types/interfaces/Notifications'

import NotificationHistoryModal from './NotificationHistoryModal.vue'
import { reformatDate } from '@/mixins/general/helpers'
import { useNotificationStore } from '@/modules/notifications/store/notification-store'

@Component({
  components: {
    NotificationHistoryModal,
  },
})
export default class Notifications extends Vue {
  @Prop({ default: false }) closeOnOutsideClick!: boolean
  isOpen = false
  acceptedNotifications: Notification[] | null = null

  store = useNotificationStore()

  toggleNotificationHistoryModal(): void {
    this.isOpen = !this.isOpen
  }

  handleOutsideClick(event: MouseEvent): void {
    const target: HTMLElement = event.target as HTMLElement
    const excludeElements: HTMLElement[] = Array.from(
      document.querySelectorAll(
        '.v-list-item, .v-list-item__title, .v-dialog--active, .v-icon, .v-btn'
      )
    )

    let shouldClose = true
    excludeElements.forEach(excludeElement => {
      if (excludeElement.contains(target)) {
        shouldClose = false
      }
    })

    if (this.isOpen && shouldClose) {
      this.toggleNotificationHistoryModal()
    } else if (this.closeOnOutsideClick && shouldClose) {
      this.$emit('closePreview')
    }
  }

  // calendar date (import reformatDate() from helpers.ts)
  reformatDate = reformatDate

  get notifications(): Notification[] {
    return this.store.notifications
  }

  async updateNotificationHistoryTable(): Promise<void> {
    this.acceptedNotifications = await this.store.getAcceptedNotifications()
  }

  async ackAll(): Promise<void> {
    await this.store.ackAll()
    await this.updateNotificationHistoryTable()
  }

  async ackOne(notificationUUID: string): Promise<void> {
    await this.store.ackOne(notificationUUID)
    await this.updateNotificationHistoryTable()
  }

  async created(): Promise<void> {
    this.acceptedNotifications = await this.store.getAcceptedNotifications()
  }
}
