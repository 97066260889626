
import { Component, Vue } from 'vue-property-decorator'

import ExSettingsBtn from './Buttons/ExSettingsBtn.vue'
import UserSettings from './UserSettings/UserSettings.vue'
import Notifications from './Notifications/Notifications.vue'
import TrashBtn from '@/components/Nav/Buttons/Trashbtn.vue'
import DevToolsBtn from '@/components/Nav/Buttons/DevToolsBtn.vue'
import { useNotificationStore } from '@/modules/notifications/store/notification-store'

@Component({
  components: {
    ExSettingsBtn,
    UserSettings,
    Notifications,
    TrashBtn,
    DevToolsBtn,
  },
})
export default class Nav extends Vue {
  readonly ALLOWED_ROUTES = ['ease', 'portal']
  notificationPopout = false
  isDev = process.env.NODE_ENV === 'development'
  isOpen = false

  get currentRouteName(): string {
    return this.$router.currentRoute.name as string
  }

  get canAccessRoutes(): boolean {
    return this.ALLOWED_ROUTES.includes(this.currentRouteName)
  }

  get notificationStore() {
    return this.canAccessRoutes ? useNotificationStore() : null
  }

  get notificationCount(): number | null {
    return this.notificationStore ? this.notificationStore.notifications.length : null
  }

  get navLabel(): string {
    return this.canAccessRoutes
      ? this.$store.getters?.getEx?.settings?.Name
      : this.currentRouteName.toUpperCase()
  }

  get showButtons(): boolean {
    return this.canAccessRoutes
  }
}
