import { ActionTree } from 'vuex'
import { EaseStore } from '@/store'
import { Alert } from '@/types/interfaces/General'
import { MiscStore } from '@/store/modules/misc/index'

const miscActions: ActionTree<MiscStore, EaseStore> = {
  /*
      ======================================================
                          G E O  P I C K E R
      ======================================================
        @openGeoPicker: open geo picker view
        @closeGeoPicker: hide the geo picker view
  */

  // open the geopicker
  openGeoPicker(context): void {
    context.commit('setGeoPicker', true)
  },

  //close the geopicker
  closeGeoPicker(context): void {
    context.commit('setGeoPicker', false)
  },

  /*
      ======================================================
                E N T I T Y  F I E L D  M A N A G  E R
      ======================================================
        @openEntityFieldManager: open entity field manager
        @closeEntityFieldManager: hide the entity field manager
  */

  // open the Entity Field Manager
  openEntityFieldManager(context): void {
    context.commit('setEntityFieldManager', true)
  },

  //close the Entity Field Manager
  closeEntityFieldManager(context): void {
    context.commit('setEntityFieldManager', false)
  },

  /*
        ======================================================
                            M O D A L
        ======================================================

    */

  // payload: { activeModal: string, modalVisible: boolean }
  setModal(context, payload: { activeModal: string; modalVisible: boolean }): void {
    context.commit('setModal', { ...payload })
  },

  clearActiveElement(context): void {
    context.commit('clearActiveElement')
    context.rootState.exercise.exUpdates++
  },

  setCloseModal(context, payload = { activeModal: '', modalVisible: false }): void {
    context.commit('setModal', payload)
    context.commit('clearActiveElement')
  },

  /*
      ======================================================
                            D A T E S
      ======================================================
  */

  // payload: string
  setActiveDate(context, payload: string): void {
    context.commit('setActiveDate', payload)
  },

  /*
        ======================================================
                            A L E R T S
        ======================================================
      */

  // payload: { color: string, text: string,  }
  setAlert(context, payload: Alert): void {
    // check if the payload is valid
    // by making sure both keys exist and are not empty
    // the type must be one of the following: success, info, warning, error
    // and the text must be a string
    if (
      !Object.prototype.hasOwnProperty.call(payload, 'text') ||
      !Object.prototype.hasOwnProperty.call(payload, 'type') ||
      payload.text === '' ||
      !payload.type ||
      typeof payload.type !== 'string' ||
      !['success', 'info', 'warning', 'error'].includes(payload.type) ||
      typeof payload.text !== 'string'
    ) {
      console.error(payload)
      console.trace(`Bad <setAlert> Payload!`)
      throw new Error('Alert must have a text and type property')
    }
    context.commit('setAlert', payload)
  },

  /*
        ======================================================
                        L O C A L   S T O R A G E
        ======================================================
      */

  initializeEase(context, payload: string): void {
    context.commit('initializeEase', payload)
  },

  /*
      ======================================================
                  N O T I F I C A T I O N S
      ======================================================
  */
  setNotifications(context, payload: Notification[]): void {
    context.commit('setNotifications', payload)
  },

  /*
        ======================================================
                              T O O L S
        ======================================================
    */

  setActiveTab(context, payload: string): void {
    context.commit('setActiveTab', payload)
  },

  /*
    ======================================================
                    L O A D I N G   B A R
    ======================================================
    */

  setLoadingBar(context, payload: { progress: number; api: string }): void {
    context.commit('setLoadingBar', payload)
  },

  /*
    ======================================================
                   W I N D O W   W I D T H
    ======================================================
    */

  setWindowWidth(context, payload: number): void {
    context.commit('setWindowWidth', payload)
  },
}

export default miscActions
