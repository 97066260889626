import { Ref, ref } from 'vue'
import { useEventListener } from '@vueuse/core'

export enum CommandPaletteKey {
  Backtick = '`',
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
}

export function useCommandPalette(
  targetElement: Ref<HTMLElement | null>,
  validKeys: CommandPaletteKey[],
  onOpen?: () => void
) {
  const isPaletteOpen = ref(false)
  const isLinkingOpen = ref(false)
  const isSimulationOpen = ref(false)

  /**
   * Opens the dialog for the CommandPalette
   */
  const handleOpenCommandPalette = (event: KeyboardEvent): void => {
    // only process valid keys
    if (!validKeys.some(key => key === event.key)) return

    onOpen?.()

    switch (event.key) {
      case CommandPaletteKey.Backtick:
        event.preventDefault()
        isPaletteOpen.value = true
        isLinkingOpen.value = false
        isSimulationOpen.value = false
        break
      case CommandPaletteKey.F1:
        event.preventDefault()
        isPaletteOpen.value = true
        isLinkingOpen.value = true
        isSimulationOpen.value = false
        break
      case CommandPaletteKey.F4:
        event.preventDefault()
        isPaletteOpen.value = true
        isLinkingOpen.value = false
        isSimulationOpen.value = true
        break
    }
  }

  useEventListener(targetElement, 'keydown', handleOpenCommandPalette)

  return {
    isPaletteOpen,
    isLinkingOpen,
    isSimulationOpen,
  }
}
