import {
  TrainingAudience,
  TrainingEvaluation,
  TrainingGoal,
  TrainingObjective,
} from '@/modules/training/model/training-model'
import { easeApi } from '@/api/ease.api'

/*
    ======================================================
                          G E T
    ======================================================

  */

export const api = {
  async fetchAllTrainingData() {
    const response = await easeApi.get<{
      audiences: TrainingAudience[]
      goals: TrainingGoal[]
      objectives: TrainingObjective[]
      evaluations: TrainingEvaluation[]
    }>('training')
    return {
      audiences: response.data.audiences,
      goals: response.data.goals.map(goal => {
        return {
          ...goal,
          description: goal.description ?? '',
        }
      }),
      objectives: response.data.objectives,
      evaluations: response.data.evaluations,
    }
  },

  async downloadTrainingData(): Promise<Blob> {
    return easeApi.get('training/export', { responseType: 'blob' }).then(res => {
      return new Blob([res.data])
    })
  },

  async fetchTrainingEvaluations() {
    const response = await easeApi.get<TrainingEvaluation[]>('training/evaluations')
    return response.data
  },

  /*
    ======================================================
                        C R E A T E
    ======================================================

  */

  async createTrainingAudience(audience: OmitServerProps<TrainingAudience>) {
    const response = await easeApi.post<MessageResponse>('training/audiences', audience)
    return response.data
  },

  async createTrainingGoal(goal: OmitServerProps<TrainingGoal>) {
    const response = await easeApi.post<MessageResponse>(`training/goals`, goal)
    return response.data
  },

  /*
    ======================================================
                      U P D A T E
    ======================================================

  */

  async updateTrainingAudience(audience: TrainingAudience) {
    const response = await easeApi.put<MessageResponse>(
      `training/audiences/${audience.uuid}`,
      audience
    )
    return response.data
  },

  async updateTrainingGoal(goal: TrainingGoal) {
    const response = await easeApi.put<MessageResponse>(`training/goals/${goal.uuid}`, goal)
    return response.data
  },
  // currently acting as a post and update (if no evaluation create it, if it exists update it)
  async updateTrainingEvaluation(evaluation: TrainingEvaluation) {
    const response = await easeApi.put<MessageResponse>(
      `training/evaluations/${evaluation.audienceUuid}_${evaluation.goalUuid}_${evaluation.objectiveUuid}`,
      evaluation
    )
    return response.data
  },

  async updateTrainingGoalObjective(goalUuid: string, objective: TrainingObjective) {
    const response = await easeApi.put<MessageResponse>(
      `training/goals/${goalUuid}/objectives/${objective.uuid}`,
      objective
    )
    return response.data
  },

  /*
    ======================================================
                      D E L E T E
    ======================================================

  */
  async deleteTrainingAudience(audienceUuid: string) {
    const response = await easeApi.delete<MessageResponse>(`training/audiences/${audienceUuid}`)
    return response.data
  },

  async deleteTrainingGoal(goalUuid: string) {
    const response = await easeApi.delete<MessageResponse>(`training/goals/${goalUuid}`)
    return response.data
  },

  async deleteTrainingGoalObjective(goalUuid: string, objectiveUuid: string) {
    const response = await easeApi.delete<MessageResponse>(
      `training/goals/${goalUuid}/objectives/${objectiveUuid}`
    )
    return response.data
  },
}

type MessageResponse = { message: string }

/**
 * Omit server generated properties from the type. Useful for creating new objects
 */
export type OmitServerProps<T> = {
  [P in keyof T]: P extends 'created' | 'updated' ? never : T[P]
}
