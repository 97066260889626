import { ref, Ref, watch } from 'vue'
import { useEventListener } from '@vueuse/core'

export interface UseContentEditableOptions {
  initialValue?: string
  onInput?: (e: InputEvent) => void
  onClick?: (e: MouseEvent) => void
  onKeyup?: (e: KeyboardEvent) => void
}

export function useContentEditable(
  target: Ref<HTMLDivElement | null>,
  options: UseContentEditableOptions = {}
) {
  const { onInput, onClick, onKeyup } = options

  const text = ref<string>(options.initialValue || '')

  const cursorPosition = ref(0)

  // useEventListener is a VueUse function that adds an event listener to the target element,
  // and will handle removing the event listener when the component is unmounted.
  useEventListener(target, 'input', (e: InputEvent) => {
    if (!target.value) throw new Error('Target element is null')
    onInput?.(e)
    cursorPosition.value = getCaretPosition(target.value)
    text.value = target.value.innerHTML
  })

  useEventListener(target, 'keyup', (e: KeyboardEvent) => {
    if (!target.value) throw new Error('Target element is null')
    onKeyup?.(e)
    cursorPosition.value = getCaretPosition(target.value)
  })

  useEventListener(target, 'click', (e: MouseEvent) => {
    if (!target.value) throw new Error('Target element is null')
    onClick?.(e)
    cursorPosition.value = getCaretPosition(target.value)
  })

  watch(text, newText => {
    if (!target.value || !newText) return
    if (target.value.innerHTML !== newText) {
      target.value.innerHTML = newText
    }
  })

  watch(target, (newTarget, oldTarget) => {
    if (!oldTarget && newTarget) {
      newTarget.innerHTML = text.value
    }
  })

  function insertAtCursor(textToInsert: string): Promise<void> {
    return new Promise(resolve => {
      if (!target.value) throw new Error('Target element is null')
      const html = target.value.innerHTML.replace(/\s/g, ' ').replace(/&nbsp;/g, ' ')
      const index = getHtmlIndex(html, cursorPosition.value)
      const textBefore = html.slice(0, index)
      const textAfter = html.slice(index)

      setTimeout(() => {
        if (!target.value) throw new Error('Target element is null')
        target.value.innerHTML = textBefore + textToInsert + textAfter
        target.value.focus()
        focusCursor(target, cursorPosition.value)
        text.value = target.value.innerHTML
        resolve()
      }, 0)
    })
  }

  return {
    text,
    cursorPosition,
    insertAtCursor,
  }
}

function getCaretPosition(element: HTMLDivElement | null): number {
  if (!element) return 0
  const selection = window.getSelection()
  if (!selection || !selection.rangeCount) return 0
  const range = selection.getRangeAt(0)
  const preCaretRange = range.cloneRange()
  preCaretRange.selectNodeContents(element)
  preCaretRange.setEnd(range.endContainer, range.endOffset)
  return preCaretRange.toString().length
}

function getHtmlIndex(html: string, textIndex: number): number {
  let i = 0
  let tag = false
  let count = 0

  while (i < html.length) {
    if (html[i] === '<') {
      tag = true
    } else if (html[i] === '>') {
      tag = false
    } else if (!tag) {
      count++
    }
    i++
    if (count === textIndex) break
  }
  return i
}

function focusCursor(element: Ref<HTMLDivElement | null>, position: number) {
  if (!element.value) throw new Error('Target element is null')
  const range = document.createRange()
  const selection = window.getSelection()
  let counter = 0
  for (const node of element.value.childNodes) {
    counter += node.textContent?.length || 0
    if (counter > position && node.nodeType === Node.TEXT_NODE) {
      range.setStart(node, 0)
      range.collapse(true)
      selection?.removeAllRanges()
      selection?.addRange(range)
      return
    }
  }
  // when the counter is > position, we've found the node where the cursor should be
  // then go the next node and set the cursor to the beginning of that node
  element.value.innerHTML += ' '
  focusCursor(element, position)
}

export type UseContentEditableReturn = ReturnType<typeof useContentEditable>
