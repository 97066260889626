import { GetterTree } from 'vuex'
import { EaseStore } from '@/store'
import { ElementStore } from '@/store/modules/elements/index'
import {
  EaseElement,
  Link,
  ElementData,
  ElementDataStringObjArray,
} from '@/types/interfaces/EaseElement'
import { DynamicField, TemplateMetadata } from '@/types/interfaces/Template'

const elementGetters: GetterTree<ElementStore, EaseStore> = {
  /*
  ======================================================
                D&D ENTITY EDITOR
  ======================================================
    @getDndEntityEditorElement: Get D&D Entity Editor Element by its UUID
    @getDndEntityEditorElements: Get D&D Entity Editor Elements
    @getInvalidDndEntityEditorElements: Get invalid D&D Entity Editor Elements
    @getValidDndEntityEditorElementsStripped: Get valid D&D Entity Editor Elements
      stripped of their coordinates
*/

  getDndEntityEditorElement:
    state =>
    (uuid: string): EaseElement | undefined =>
      state.dndEntityEditorElements.find((element: EaseElement) => element.metadata.uuid === uuid),

  // get the D&D Entity Editor Elements
  getDndEntityEditorElements: (state): EaseElement[] => state.dndEntityEditorElements,

  // get the invalid D&D Entity Editor Elements
  getInvalidDndEntityEditorElements: (state): EaseElement[] => {
    // check for elements that are missing a name and/or intent
    return state.dndEntityEditorElements.filter((element: EaseElement) => {
      return !element.metadata.name || !element.metadata.excon.intent
    })
  },

  // get the D&D Entity Editor Elements as EaseElements
  getValidDndEntityEditorElementsStripped: (state): EaseElement[] =>
    // get valid D&D Entity Editor Elements and strip them of their coordinates
    state.dndEntityEditorElements.filter((element: EaseElement) => {
      return element.metadata.name && element.metadata.excon.intent
    }),

  /*
    ======================================================
                 E L E M E N T S
    ======================================================
      @getElements: Get not trashed elements sorted alphabetically
      @getElementUUIDs: Get element UUIDs
      @getElementLinks: Get element links
      @getElementTypes: Get element types, sorted
      @getElementSupertypes: Get element supertypes, sorted
      @getElementSubtypes: Get element subtypes, sorted
      @getElementByUUID: Get element by UUID

  */

  getAllElements: (state): EaseElement[] => {
    const elements = [...state.elements, ...state.dndEntityEditorElements].filter(
      element => !element.metadata.trashed
    )
    return structuredClone(
      elements.sort((a: EaseElement, b: EaseElement) => {
        if (a.metadata.name < b.metadata.name) return -1
        if (a.metadata.name > b.metadata.name) return 1
        return 0
      })
    )
  },

  // autoTest || getElements || EaseElement[]
  getElements: (state): EaseElement[] => {
    return structuredClone(
      state.elements
        .filter(element => !element.metadata.trashed)
        .sort((a: EaseElement, b: EaseElement) => {
          if (a.metadata.name < b.metadata.name) return -1
          if (a.metadata.name > b.metadata.name) return 1
          return 0
        })
    )
  },

  getInjectElements: (state): EaseElement[] => {
    return state.elements.filter(
      element =>
        element.template.metadata.type.supertype === 'Document' && !element.metadata.trashed
    )
  },

  getTrashedElementCount: (state): number => state.trashedElementCount,

  getElementUUIDs: (state): string[] => {
    return state.elements.map((element: EaseElement) => element.metadata.uuid)
  },

  getElementLinks: (state): Link[] => {
    const links: Link[] = []

    state.elements
      .filter((element: EaseElement) => !element.metadata.trashed)
      .forEach((element: EaseElement) => {
        element.links?.forEach((link: Link) => {
          if (!links.includes(link)) {
            links.push(link)
          }
        })
      })
    state.dndEntityEditorElements.forEach((element: EaseElement) => {
      element.links?.forEach((link: Link) => {
        if (!links.includes(link)) {
          links.push(link)
        }
      })
    })

    return links
  },

  getElementsPendingUpdates: (state): string[] => {
    return state.pendingElementUpdates
  },

  getLastGetElementsRequestTime: (_, __, rootState): string | null =>
    rootState.misc.misc.lastGetElementsRequest,

  getElementTypes: (state): string[] => {
    const elements = state.elements
    const types: TemplateMetadata['type']['type'][] = []

    elements.forEach(element => {
      if (!types.includes(element.template.metadata.type.type)) {
        types.push(element.template.metadata.type.type)
      }
    })

    types.sort()

    return types
  },

  getElementSupertypes: (state): string[] => {
    const elements = state.elements
    const supertypes: TemplateMetadata['type']['supertype'][] = []

    elements.forEach(element => {
      if (!supertypes.includes(element.template.metadata.type.supertype)) {
        supertypes.push(element.template.metadata.type.supertype)
      }
    })

    supertypes.sort()

    return supertypes
  },

  getElementSubtypes: (state): string[] => {
    const elements = state.elements
    const substypes: TemplateMetadata['type']['subtype'][] = []

    elements.forEach(element => {
      if (!substypes.includes(element.template.metadata.type.subtype)) {
        substypes.push(element.template.metadata.type.subtype)
      }
    })

    substypes.sort()

    return substypes
  },

  getElementByUUID:
    state =>
    (elementUUID: string): EaseElement | undefined => {
      return state.elements.find((element: EaseElement) => element.metadata.uuid === elementUUID)
    },

  getElemenetAttributeByUuid:
    state =>
    (elementUuid: string, attributeUuid: string): ElementData | undefined => {
      return (
        state.elements
          ?.find((element: EaseElement) => element.metadata.uuid === elementUuid)
          ?.data.find((attribute: ElementData) => attribute.uuid === attributeUuid) ?? undefined
      )
    },

  /*
    ======================================================
                  A C T I V E   T O O L
    ======================================================
      @getActiveElement: get the entire active tool
      @getActiveElementDataByName: get the active tool data by name
  */

  // A C T I V E   T O O L
  // get the entire active tool
  getActiveElement: (state): EaseElement | null => state.activeElement,

  // get active attribute
  getActiveAttribute: (state): { name: string; comboUuid?: string } | undefined => {
    return state.activeAttribute
  },

  // get all the active fields from an element
  getActiveElementFields: (state): DynamicField[] | undefined => {
    return state.activeElement?.template.fields
  },

  /**
   * get the active Element Links
   */
  getActiveElementLinks: (state): Link[] | undefined => state.activeElement?.links,

  // get the selected element for the dataviewer
  getSelectedElement: (state): EaseElement | null => state.selectedElement,

  // autoTest || getActiveElementDataByName || ()=>ElementData
  getActiveElementDataByUUID:
    state =>
    (fieldUUID: string): ElementData | undefined =>
      state.activeElement?.data.find((field: ElementData) => field.uuid === fieldUUID),

  // autoTest || getActiveElementDataByName || ()=>ElementData
  getActiveElementDataByName:
    state =>
    (fieldName: string): ElementData | undefined =>
      state.activeElement?.data.find((field: ElementData) => field.name === fieldName),

  getElementDataAttributeByUuid:
    state =>
    (elementUuid: string, attributeUuid: string): ElementData | undefined => {
      const allElements = [...state.elements, ...state.dndEntityEditorElements]
      const foundElement = allElements.find(
        (element: EaseElement) => element.metadata.uuid === elementUuid
      )

      if (foundElement) {
        const foundAttribute = foundElement.data.find(
          (attribute: ElementData) =>
            attribute.uuid === attributeUuid ||
            (Array.isArray(attribute.content) &&
              attribute.content.find(
                (ElementDataStringObjArray: ElementDataStringObjArray) =>
                  ElementDataStringObjArray.uuid === attributeUuid
              ))
        )

        if (foundAttribute) {
          return foundAttribute
        }
      }

      throw new Error(
        `Element with UUID '${elementUuid}' or attribute with UUID '${attributeUuid}' not found.`
      )
    },

  // returns a key value pair of the active element's data and their validity status
  getActiveElementFieldValidityStatus: state => (): Record<string, boolean | undefined> => {
    const validityStatus = {}
    state.activeElement?.data.forEach((field: ElementData) => {
      if (!field.valid) {
        validityStatus[field.name] = field.valid
      }
    })
    return validityStatus
  },
}

export default elementGetters
