
import { Component, Vue, Prop } from 'vue-property-decorator'

// types
import { Exercise } from '@/types/interfaces/Exercise'

// components
import Banner from '@/components/Portal/Portal/General/Banner.vue'
import ExerciseCardOptions from '@/components/Portal/Portal/General/Cards/CardButtons/ExerciseCardOptions.vue'
import Classification from '@/components/Common/General/Classification.vue'

// mixins
import { handleOpenExercise } from '@/mixins/portal/exercises'
import { reformatDate } from '@/mixins/general/helpers'

@Component({
  components: {
    Banner,
    ExerciseCardOptions,
    Classification,
  },
})
export default class ExerciseCard extends Vue {
  @Prop() exerciseUuid!: string

  reformatDate = reformatDate

  get userUUID(): string {
    return this.$store.getters.getUser.uuid
  }

  get exercise(): Exercise {
    return this.$store.getters.getExerciseByUuid(this.exerciseUuid)
  }

  get owned(): boolean {
    return this.exercise.metadata?.owned === this.userUUID
  }

  handleOpenExercise(): void {
    if (!this.exercise.metadata?.uuid) return
    handleOpenExercise(this.exercise.metadata?.uuid)
  }
}
