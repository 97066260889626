
import { Component, Vue, Watch } from 'vue-property-decorator'

// types
import { SharedUser, User } from '@/types/interfaces/General'
import { Exercise } from '@/types/interfaces/Exercise'

// mixins
import { transferOwnership, addUsersToEx, removeUserFromEx } from '@/mixins/portal/exercises'
import { getUserDetails } from '@/mixins/api/users'

@Component
export default class ShareModal extends Vue {
  usernames = [] as string[]
  dialog = false
  sharedUsers = [] as User[]

  exName = ''

  emailValid = () => {
    return this.emailsValid() || 'E-mail must be valid'
  }

  @Watch('sharedUsers')
  onSharedUsersChange(): void {
    this.$store.getters.getExercises
  }

  get currentUser(): User {
    // Get the uuid of the session user
    return this.$store.getters.getUser
  }

  get exerciseOwnerUUID(): string {
    // Get the uuid of the exercise owner
    if (!this.exercise.metadata?.owned) throw new Error('Exercise owner not found')
    return this.exercise.metadata?.owned
  }

  get exercise(): Exercise {
    return this.$store.getters.getActiveEx
  }

  get exerciseUUID(): string {
    if (!this.exercise.metadata?.uuid) throw new Error('Exercise uuid not found')
    return this.exercise.metadata?.uuid
  }

  userActions = [
    { disabled: false, type: 'btn', label: 'Remove', action: this.removeUserFromEx },
    { disabled: false, type: 'btn', label: 'Transfer Ownership', action: this.transferOwnership },
    { disabled: false, type: 'divider' },
    { disabled: true, type: 'btn', label: 'Editor', on: 'remove' },
    { disabled: true, type: 'btn', label: 'Viewer', on: 'remove' },
    { disabled: true, type: 'btn', label: 'Candidate', on: 'remove' },
  ]

  mounted(): void {
    this.exName = this.exercise.settings.Name as string

    this.exercise.metadata?.shared.forEach((sharedUser: SharedUser) => {
      if (sharedUser.accepted === true) {
        getUserDetails(sharedUser.uuid).then((user: User) => {
          this.sharedUsers.push(user)
        })
      }
    })

    // Add the owner of the exercise to the shared users list
    getUserDetails(this.exerciseOwnerUUID).then((user: User) => {
      this.sharedUsers.unshift(user)
    })
  }

  transferOwnership(userUUID: string): void {
    transferOwnership(userUUID, this.exerciseUUID)
    if (this.exercise.metadata) {
      this.exercise.metadata.owned = userUUID
    }
  }

  addUsersToEx(): void {
    addUsersToEx(this.usernames, this.exerciseUUID)
    this.closeModal()
  }

  removeUserFromEx(userUUID: string): void {
    removeUserFromEx(userUUID, this.exerciseUUID)
    this.sharedUsers = this.sharedUsers.filter((user: User) => user.uuid !== userUUID)
    this.$store.commit('setExercises', this.sharedUsers)
  }

  // Iterate through all of the emails and check if they are valid using the emailValid function
  emailsValid(): boolean {
    // Iterate through the emails
    for (let i = 0; i < this.usernames.length; i++) {
      // Check if the email is valid
      // if (this.ruleMustBeEmail(this.emails[i]) === true) {
      //   continue
      // } else {
      // // Generate an alert that the email is invalid
      // Store.dispatch("setAlert", {
      //   text: "Email " + this.emails[i] + " is invalid",
      //   type: "error",
      // })
      // Remove the email from the list
      // this.emails.splice(i, 1)
      // return false
      // }
    }

    return true
  }

  // Close modal by setting store variable
  closeModal(): void {
    this.$store.dispatch('setCloseModal')
  }

  ruleMustBeEmail(email: string): boolean | string {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return Boolean(pattern.test(email))
  }
}
