import { Exercise, ApiExercises } from '@/types/interfaces/Exercise'
import { PortalStore } from '@/types/interfaces/Portal'
import { EaseStore } from '@/store'
import { ActionTree } from 'vuex'

const portalActions: ActionTree<PortalStore, EaseStore> = {
  /*
      ======================================================
                  E X E R C I S E S
      ======================================================
    */
  setExercises(context, payload: ApiExercises): void {
    context.commit('setExercises', payload)
  },

  addNewOwnedExercise(context, payload: Exercise): void {
    context.commit('addNewOwnedExercise', payload)
  },

  removeExercise(context, payload: string): void {
    context.commit('removeExercise', payload)
  },

  /*
      ======================================================
                  A C T I V E  T O O L
      ======================================================
    */

  setActiveEx(context, payload: Exercise): void {
    context.commit('setActiveEx', payload)
  },

  /*
      ======================================================
                  S E S S I O N
      ======================================================
  */

  setPortalLoaded(context, payload: boolean): void {
    context.commit('setPortalLoaded', payload)
  },
}

export default portalActions
