
import { Component, Vue, Provide } from 'vue-property-decorator'
import BaseModal from '@/components/Modals/BaseModal.vue'
import ShareModal from '@/components/Portal/Modals/ModalComponents/ShareModal/ShareModal.vue'
import ConfirmDeleteModal from '@/components/Portal/Modals/ModalComponents/DeleteModal/ConfirmDeleteModal.vue'
import ConfirmDuplicateModal from '@/components/Portal/Modals/ModalComponents/ConfirmDuplicateModal/ConfirmDuplicateModal.vue'
import UserSettingsModal from '@/components/Nav/UserSettings/UserSettingsModal.vue'
import H2Portal from '@/components/Portal/Portal/H2Portal.vue'
import ExSettingsModal from '@/modules/ex-settings/ExSettingsModal.vue'

// mixins
import { PortalHeartBeat } from '@/mixins/heartbeats/portal'

// composables
import useHeartbeat from '@/modules/common/composables/useHeartbeat'
import { getEaseConfig } from '@/easeConfig'
import { easeApi } from '@/api/ease.api'

@Component({
  components: {
    ConfirmDuplicateModal,
    ConfirmDeleteModal,
    UserSettingsModal,
    ExSettingsModal,
    BaseModal,
    ShareModal,
    H2Portal,
  },

  setup() {
    useHeartbeat(
      async () => {
        await PortalHeartBeat()
      },
      10000,
      {
        ignoredUrls: ['exercise/set', 'user/validate', 'user', 'exercises', 'notifications'],
        axiosInstance: easeApi,
        immediate: true,
      }
    )
  },
})
export default class App extends Vue {
  portalInitialized = false
  versionNumber: string | null = null

  apiURL = getEaseConfig().EASE_API_URL
  @Provide() easeApiURL = this.apiURL

  mounted(): void {
    this.versionNumber = getEaseConfig().EASE_VERSION
  }

  get getActiveModal(): string {
    return this.$store.getters.getActiveModal
  }

  get portalLoaded(): boolean {
    return this.$store.getters.getPortalLoaded
  }

  modals = [
    {
      name: 'Exercise Settings',
      component: ExSettingsModal,
      binds: { view: 'portal' },
      closePopoutEnabled: true,
    },
    {
      name: 'Manage Shared Users',
      component: ShareModal,
      binds: {},
      closePopoutEnabled: true,
    },
    {
      name: 'Manage User Settings',
      component: UserSettingsModal,
      binds: {},
      closePopoutEnabled: true,
    },
    {
      name: 'Confirm Duplicate Exercise',
      component: ConfirmDuplicateModal,
      binds: {},
      closePopoutEnabled: true,
    },
    {
      name: 'Confirm Delete Exercise',
      component: ConfirmDeleteModal,
      binds: {},
      closePopoutEnabled: true,
    },
  ]

  // Deafault to not show alert
  showAlert = false
}
