
import { Component, Vue } from 'vue-property-decorator'

//   M I X I N S
import {
  devSeedDB,
  entityExists,
  generateRandomElement,
  linkRandomElements,
  generateRandomAlert,
} from '@/mixins/api/devTools'

import { Alert } from '@/types/interfaces/General'
import { useNotificationStore } from '@/modules/notifications/store/notification-store'

@Component
export default class DevToolsBtn extends Vue {
  isDev = process.env.NODE_ENV === 'development'
  showDevDialog = false

  store = useNotificationStore()

  /**
   * description: generates a random alert for testing
   * @param type: Alert['color'] = null
   */
  generateRandomAlert(type: Alert['type'] | null = null, malformed = false): void {
    // for testing alert error handling
    if (malformed) {
      this.$store.dispatch('setAlert', {
        type: "This must be a valid alert type: <'success' | 'info' | 'warning' | 'error'>",
        text: ['this', 'must', 'be', 'a', 'string'],
      })

      return
    }

    // invoke the dev mixin
    if (type) generateRandomAlert(type)

    // if it's a random alert, close the dialog
    if (!type) this.showDevDialog = false
  }

  generateRandomElement(): void {
    generateRandomElement()
  }

  generateRandomNotification(): void {
    this.store.addNewRandomNotifcation()
  }

  linkRandomElements(): void {
    linkRandomElements()
  }

  devSeedDB(): void {
    devSeedDB()
  }

  entityExists(): boolean {
    return entityExists()
  }
}
