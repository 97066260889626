import { NewUser, User } from '@/types/interfaces/General'
import Store from '@/store'
import { easeApi } from '@/api/ease.api'

export const filterUser = (filter: string, user: User): boolean => {
  const params = filter.toLowerCase().split(' ')
  const userString = JSON.stringify(user).toLowerCase()
  return params.every((p: string) => userString.includes(p))
}

export const adminGetUsers = async (filter: string | null = null): Promise<User[]> => {
  const res = await easeApi.get('admin/users')
  return filter ? res.data.filter((user: User) => filterUser(filter, user)) : res.data
}

export const adminUpdateUserPassword = async (
  userUuid: string,
  password: string
): Promise<void> => {
  await easeApi.post('admin/user/update/password', { uuid: userUuid, password: password })
  Store.dispatch('setAlert', {
    text: 'Successfully Updated User Password!',
    type: 'success',
  })
}

export const adminAddUserToAllExercises = async (userUuid: string): Promise<void> => {
  await easeApi.post('admin/user/all-exercises', { uuid: userUuid })
  Store.dispatch('setAlert', {
    text: 'Successfully Added User to All Exercises!',
    type: 'success',
  })
}

export const adminUpdateUser = async (userData: User): Promise<void> => {
  await easeApi.put('admin/user/update', userData)
  Store.dispatch('setAlert', {
    text: 'Updated User!',
    type: 'success',
  })
}

export const adminDeleteUser = async (userUuid: string): Promise<void> => {
  await easeApi.delete(`admin/user/delete/${userUuid}`)
  Store.dispatch('setAlert', {
    text: 'Successfully Deleted User!',
    type: 'success',
  })
}

export const adminAddUser = async (userData: NewUser): Promise<void> => {
  try {
    const res = await easeApi.post('admin/user/add', userData)
    console.log(res.data)
  } catch {
    Store.dispatch('setAlert', {
      text: `User Already Exists: ${userData.username}`,
      type: 'error',
    })
  }
}

export const adminBulkAddUsers = (file: File) => {
  const formData = new FormData()
  formData.append('csv', file)
  return easeApi
    .post('admin/user/add/bulk', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(() => {
      return Store.dispatch('setAlert', {
        text: `Successfully Added Users`,
        type: 'success',
      })
    })
    .catch((err: string) => {
      Store.dispatch('setAlert', {
        text: `CSV parsing error: ${err}`,
        type: 'error',
      })
    })
}
