import { ActionTree } from 'vuex'
import { EaseStore } from '@/store'
import { ExStore } from '@/store/modules/exercise/index'

// V A R S
const exerciseRequiredKeys = [
  'Name',
  'Classification',
  'Short Classification',
  'Start Day',
  'End Day',
  'Host Unit',
  'Short Classification',
  'Analyst Report Title',
]

const exerciseActions: ActionTree<ExStore, EaseStore> = {
  /*
      ======================================================
                        E X E R C I S E
      ======================================================
        @setEx: set an exercise from payload, set Ex loaded to true, and update
        @updateEx: update a key in the ex
  */

  submitActiveElement(context): void {
    context.commit('submitActiveElement')
  },

  // payload: { exerciseData }
  setEx(context, payload: boolean): void {
    context.commit('setEx', payload)
  },

  incrementExUpdates(context): void {
    context.commit('incrementExUpdates')
  },

  // payload: { [key: string]: string}
  updateEx(context, payload: { [key: string]: string }): void {
    if (exerciseRequiredKeys.includes(payload.name) && !payload.value) {
      const msg = `${payload.name} must have a value.`
      context.commit('setAlert', {
        text: msg,
        type: 'error',
      })
    } else {
      context.commit('updateEx', payload)
      context.dispatch('setAlert', {
        text: `Successfully updated ${payload.name} to ${payload.value}`,
        type: 'success',
      })
    }
  },
}

export default exerciseActions
