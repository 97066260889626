// types
import { ApiExercises } from '@/types/interfaces/Exercise'
import { User } from '@/types/interfaces/General'

// mixins
import { getExercises } from '@/mixins/portal/exercises'
import { fetchUser } from '@/mixins/api/users'
import { validateSession } from '@/mixins/api/session'

// store
import store from '@/store'
import { useNotificationStore } from '@/modules/notifications/store/notification-store'
import { catchHeartBeatError } from '@/mixins/heartbeats/util'

export function PortalHeartBeat(): Promise<void> {
  const notificationStore = useNotificationStore()
  const apiCalls = [
    getExercises().then((exercises: ApiExercises) => store.dispatch('setExercises', exercises)),
    fetchUser().then((user: User) => store.dispatch('setUser', user)),
    notificationStore.fetchNotifications(),
    validateSession().then((validSession: boolean) =>
      !validSession ? catchHeartBeatError(new Error('Could not validate Portal Session')) : null
    ),
  ]

  return Promise.all(apiCalls).then(() => {
    store.dispatch('setPortalLoaded', true)
  })
}
