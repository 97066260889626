import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import PortalView from '@/views/PortalView.vue'
import LoginView from '@/views/LoginView.vue'
import ErrorView from '@/views/ErrorView.vue'

import { validateSession } from '@/mixins/api/session'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'root',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/portal',
    name: 'portal',
    component: PortalView,
  },
  {
    path: '/ease',
    name: 'ease',
    component: () => import('@/views/EaseView.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: ErrorView,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  validateSession().then((validSession: boolean) => {
    // If logged in -> redirect to portal
    if (validSession && to.name === 'login' && from.name !== 'portal') next({ name: 'portal' })
    // If not logged in -> redirect to login
    else if (!validSession && to.name !== 'login') next({ name: 'login' })
    // Default
    else next()
  })
})

export default router
