import Store from '@/store'

import { AxiosRequestConfig } from 'axios'

import {
  ApiTemplates,
  DynamicField,
  FieldTypeOptions,
  Template,
  TemplateResponse,
} from '@/types/interfaces/Template'

// mixins
import { useNotificationStore } from '@/modules/notifications/store/notification-store'
import { genUUID } from '@/mixins/general/helpers'

// data
import { iconOptions } from '@/data/icons/iconsOptions'

import { Type, User } from '@/types/interfaces/General'
import { easeApi } from '@/api/ease.api'

/*
      ======================================================
                  T E M P L A T E S  -   S H A R E
      ======================================================
*/

/**
 * Accept, decline or leave a shared template
 *
 * @param {string} templateUUID
 * @param {string} action
 */
export const modifyTemplateShare = (templateUUID: string, action: string) => {
  return easeApi.put(`templates/share/${templateUUID}/${action}`)
}

/*
      ======================================================
                  A P I  -  T E M P L A T E S
      ======================================================
*/

/**
 * Pull all owned and shared templates of a user, validate them
 * and set them in state
 */
export const fetchTemplates = async (config?: AxiosRequestConfig): Promise<ApiTemplates> => {
  return easeApi.get<TemplateResponse>('templates', config).then(res => res.data.templates)
}

export const mergeTemplateResponse = (
  templateRes: TemplateResponse,
  currentTemplates: ApiTemplates
): ApiTemplates => {
  // validate api templates
  const apiTemplates: ApiTemplates = templateRes.templates

  //get current templates from the store
  const easeTemplates: ApiTemplates = JSON.parse(JSON.stringify(currentTemplates))

  // taking our current templates and validating them against the api templates
  Object.keys(templateRes.templates).forEach(key => {
    // by major section, built in, owned, shared
    easeTemplates[key] = easeTemplates[key].filter((template: Template) => {
      // remove templates that have been updated
      if (templateRes.updated.includes(template.metadata.uuid)) {
        return false
      }
      // remove templates that are not valid
      return templateValid(template)
    })
    // add new templates
    easeTemplates[key] = [...easeTemplates[key], ...apiTemplates[key]]
  })
  return easeTemplates
}

/*
      ======================================================
                T E M P L A T E   B U I L D E R
      ======================================================
*/

/**
 * Submit a new template from the template builder and
 * reset the active template to null after submission
 *
 * @param {Template} template
 * @param {string} method
 * @param {string} url
 *
 */
export const submitActiveTemplateBuilder = (
  method: 'post' | 'put',
  url: string,
  template: Template
): Promise<void> => {
  return easeApi
    .request<Template>({ method, url, data: template })
    .then(res => {
      Store.dispatch('setAlert', {
        text:
          method === 'put'
            ? 'Template Updated Successfully!'
            : `Successfully Created Template ${template.metadata.name}!`,
        type: 'success',
      })
      Store.dispatch('setModal', null)
      Store.dispatch('setActiveTemplate', null)
      Store.dispatch('setCloseModal', false)
      fetchTemplates()
      //only when updating a template, update the template in state
      if (method === 'put' && res.data) {
        Store.dispatch('updateTemplate', { template: res.data, action: 'edit' })
      }
    })
    .catch(() => {
      Store.dispatch('setAlert', {
        text: method === 'put' ? 'Failed to Update Template' : 'Failed to create New Template...',
        type: 'error',
      })
    })
}

/**
 * Add a user to a template
 *
 * @param {string[]} name
 * @param {string} templateUUID
 */
export const addUsersToTemplate = (name: string[], templateUUID: string, users: User[]): void => {
  const store = useNotificationStore()
  easeApi
    .post('template/share/add', {
      name: name,
      template_uuid: templateUUID,
    })
    .then(() => {
      // Set alert
      Store.dispatch('setAlert', {
        text: 'Template Invitation(s) Sent to Valid Account names',
        type: 'success',
      })
      // fetch notifications
      store.fetchNotifications()

      // Update the front end for the shared users
      const template: Template = Store.getters.getTemplateByUUID(templateUUID)
      const newSharedUsers = name.map(name => {
        const uuid = users.find(user => user.name === name)?.uuid
        if (!uuid) throw Error(`User ${name} not found`)
        return { uuid: uuid, accepted: false }
      })
      template.metadata.shared = [...template.metadata.shared, ...newSharedUsers]
      Store.dispatch('updateTemplate', { template: template, action: 'edit' })
    })
    .catch(error => {
      console.error(error)
      // Set alert
      Store.dispatch('setAlert', {
        text: 'Failed to Add Users To Template',
        type: 'error',
      })
    })
}

/**
 * Delete a template by its UUID
 *
 * @param {string} templateUUID
 */
export const deleteUserTemplate = (templateUUID: string): void => {
  easeApi
    .delete(`template/delete/${templateUUID}`)
    .then(() => {
      // remove template from state
      Store.dispatch('deleteTemplate', templateUUID)
      Store.dispatch('setAlert', {
        text: 'Successfully deleted Template...',
        type: 'success',
      })
    })
    .catch(() => {
      Store.dispatch('setAlert', {
        text: 'Failed to delete Template...',
        type: 'error',
      })
    })
}

/**
 * Initialize the template builder depending on if a template
 * is being created or edited, if the template is a document or entity
 *
 * @param {Template} template
 * @param {'Document' | 'Entity' | 'Data'} supertype
 * @param {boolean} clear
 *
 */
export const handleTemplateBuilder = (
  template: Template | null,
  supertype: Type['supertype'] = 'Document',
  clear = false
): void => {
  if (clear) {
    Store.dispatch('setActiveTemplate', null)
  } else if (template) {
    // set edited template to state
    const editableTemplate = {
      ...template,
      metadata: {
        ...template.metadata,
        version: template.metadata.version + 1,
      },
    }
    Store.dispatch('setActiveTemplate', structuredClone(editableTemplate))
  } else {
    const user = Store.getters.getUser
    const newTemplate: Template = {
      metadata: {
        uuid: genUUID(),
        name: '',
        tags: [],
        builtin: false,
        version: 1,
        description: {
          short: '',
          long: '',
        },
        owned: user.uuid,
        shared: [],
        type: {
          supertype: supertype,
          subtype: '',
          type: '',
        },
        style: {
          color: '#000000',
          icon:
            supertype === 'Document'
              ? 'file-document'
              : iconOptions[Math.floor(Math.random() * iconOptions.length)].slice(4),
        },
        resources: [],
      },
      fields: [],
    }
    if (supertype === 'Document') {
      newTemplate.html = { content: '', style: undefined }
      newTemplate.metadata.resources = [
        {
          description: 'Download DOCX',
          label: 'Download',
          type: 'docx',
        },
      ]
      newTemplate.metadata.init = ['general_document']
    } else {
      newTemplate.fields = [
        {
          name: '',
          fieldType: FieldTypeOptions.TEXT,
          tags: [],
          hint: '',
        },
      ]
    }

    Store.dispatch('setActiveTemplate', newTemplate)
  }
}

/**
 * Check if a field in an entity is valid
 *
 * @param {DynamicField} field
 */
export const dynamicFieldValid = (field: DynamicField): boolean => {
  const validation = {
    name: !!field.name && field.name !== '' && typeof field.name === 'string',
    type: field.type !== null && typeof field.fieldType === 'string',
    tags: field.tags.length > 0,
  }

  return Object.values(validation).every((test: boolean) => test)
}

/**
 * Checks whether a template is valid by checking
 * a) its metadata
 * b) its fields if its an Entity
 * c) its html if its a Document
 * @param Template - the template to test
 * @param boolean - whether to log errors
 * @returns boolean | object - true if valid, object of errors if not
 */
export const templateValid = (
  template: Template,
  log = false
): boolean | { [key: string]: boolean | string } => {
  const metadata = template?.metadata
  const isDocument = metadata?.type?.supertype === 'Document'

  try {
    const validation: { [key: string]: boolean | string } = {
      uuid:
        (metadata?.uuid && typeof metadata?.uuid === 'string') ||
        'The UUID of the Template is not valid or unset',

      name:
        (metadata?.name && typeof metadata?.name === 'string') ||
        'The Name of the Template is not valid or unset',

      tags:
        (metadata?.tags && Array.isArray(metadata?.tags)) ||
        'The Tags of the Template are not valid or unset',

      description:
        (metadata?.description && typeof metadata?.description === 'object') ||
        'The Description of the Template is not valid or unset',

      shortDescription:
        (metadata?.description?.short && typeof metadata?.description?.short === 'string') ||
        'The Short Description of the Template is not valid or unset',

      longDescription:
        (metadata?.description?.long && typeof metadata?.description?.long === 'string') ||
        'The Long Description of the Template is not valid or unset',

      owned:
        (metadata?.owned && typeof metadata?.owned === 'string') ||
        'The Onwer of the Template is not valid',

      type:
        ['supertype', 'subtype', 'type'].every((type: string) => {
          return metadata?.type?.type && !!metadata?.type[type]
        }) || 'The Types of the Template are not valid',

      builtin:
        typeof metadata?.builtin === 'boolean' ||
        'The Builtin Property of the Template is not valid or unset',

      version:
        typeof metadata?.version === 'number' ||
        'The Version of the Template is not valid or unset',

      color:
        (metadata?.style?.color && typeof metadata?.style?.color === 'string') ||
        'The Color of the Template is not valid or unset',

      icon:
        (metadata?.style?.icon && typeof metadata?.style?.icon === 'string') ||
        'The Icon of the Template is not valid or unset',

      shared:
        Array.isArray(metadata?.shared) || 'The Shared Array of the Template is not valid or unset',
    }

    if (isDocument) {
      validation.html =
        (template?.html &&
          typeof template?.html === 'object' &&
          typeof template?.html?.content === 'string') ||
        'The HTML section of the Template is not valid'
    } else {
      validation.fields =
        (template.fields &&
          template.fields.every((field: DynamicField) => {
            return (
              field?.name &&
              typeof field?.name === 'string' &&
              field?.fieldType &&
              typeof field?.fieldType === 'string' &&
              field?.tags &&
              Array.isArray(field?.tags) &&
              field?.tags.length
            )
          })) ||
        'The Fields of the Template are not valid or unset'
    }

    const templateValid = Object.values(validation).every(
      (value: boolean | string) => typeof value === 'boolean'
    )

    if (log) {
      !templateValid &&
        console.warn('Template INVALID', template.metadata.name, validation, template)
    }

    return templateValid || validation
  } catch (error) {
    console.error(`Error validating Template, ${template?.metadata?.name} Error: ${error}`)
    return false
  }
}
