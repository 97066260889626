import Vue from 'vue'
import { MutationTree } from 'vuex'

// T Y P E S
import { PortalStore } from '@/types/interfaces/Portal'
import { Exercise, ApiExercises } from '@/types/interfaces/Exercise'

const portalMutations: MutationTree<PortalStore> = {
  /*
      ======================================================
                  E X E R C I S E S
      ======================================================
    */
  setExercises(state: PortalStore, payload: ApiExercises): void {
    Vue.set(state.exercises, 'owned', payload.owned)
    Vue.set(state.exercises, 'shared', payload.shared)
  },

  addNewOwnedExercise(state: PortalStore, payload: Exercise): void {
    state.exercises.owned.push(payload)
  },

  removeExercise(state: PortalStore, payload: string): void {
    state.exercises.owned = state.exercises.owned.filter(
      (ex: Exercise) => ex?.metadata?.uuid !== payload
    )
    state.exercises.shared = state.exercises.shared.filter(
      (ex: Exercise) => ex?.metadata?.uuid !== payload
    )
  },

  /*
      ======================================================
                  A C T I V E  T O O L
      ======================================================
    */
  setActiveEx(state: PortalStore, payload: Exercise): void {
    state.ActiveEx = Object.assign({}, payload)
  },

  /*
      ======================================================
                  S E S S I O N
      ======================================================
  */

  setPortalLoaded(state: PortalStore, payload: boolean): void {
    state.portalLoaded = payload
  },
}

export default portalMutations
