import {
  AiEndpoint,
  AiGenerateFn,
  InjectGeneratorInput,
  InjectGeneratorReview,
} from '../ai-generator'
import EaseStore from '@/store'
import { User } from '@/types/interfaces/General'
import { fetchUser } from '@/mixins/api/users'
import { easeApi } from '@/api/ease.api'
import { Link } from '@/types/interfaces/EaseElement'

/**
 * @param aiEndpoint 'entity' to generate entities | 'document' to generate documents
 * @returns a function that consumes a prompt, settings, injectInfo and a unique identifier,
 *          and returns a promise with the response from /prompting/(document|entity)
 */
export const defaultAiGenerator =
  (
    aiEndpoint: AiEndpoint
  ): AiGenerateFn<InjectGeneratorInput, { template: string; links: Link[] }> =>
  async (prompt, settings, injectInfo, request_uuid: string) => {
    const url = `prompting/${aiEndpoint}`
    try {
      const res = await easeApi.post<{ template: string; links: Link[] }>(url, {
        prompt,
        ...injectInfo,
        settings,
        includeAttributes: settings.includeAttributes,
        request_uuid,
      })
      fetchUser().then((user: User) => {
        EaseStore.dispatch('setUser', user)
      })
      return res.data
    } catch (err) {
      console.error('Error, document could not be generated', err)
      EaseStore.dispatch('setAlert', {
        text: `Failed to generate document. Check VPN connection if using NextriaLLM.`,
        type: 'error',
      })
      return {
        template: '',
        links: [],
      }
    }
  }

export const submitLLMReview = async (reviewData: InjectGeneratorReview) => {
  return await easeApi.post('prompting/review', reviewData)
}
