import { getEaseConfig } from '@/easeConfig'
import axios, { AxiosInstance } from 'axios'

let easeApiV2: AxiosInstance

export function initEaseApiV2() {
  easeApiV2 = axios.create({
    baseURL: getEaseConfig().EASE_API_V2_URL,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' },
  })
}

export { easeApiV2 }
