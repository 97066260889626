
import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'

// types
import { User } from '@/types/interfaces/General'

// Components
import UserSettingsModal from '@/components/Nav/UserSettings/UserSettingsModal.vue'

// mixins
import { logOut } from '@/mixins/api/session'
import { clearEaseAppStore } from '@/mixins/general/store'
import { easeApi } from '@/api/ease.api'

@Component({ components: { UserSettingsModal } })
export default class UserSettings extends Vue {
  handleUserSettingsModal = false

  get user(): User {
    return this.$store.getters.getUser
  }

  exit(): void {
    clearEaseAppStore()
    easeApi.post('exercise/set', { exerciseUuid: null }).then(() => {
      router.push('/portal')
    })
  }

  handleUserSettings(): void {
    this.handleUserSettingsModal = true
  }

  handleLogout(): void {
    logOut()
  }

  closeModal(): void {
    this.handleUserSettingsModal = !this.handleUserSettingsModal
  }

  handleShowSettings = (): void => {
    this.$store.dispatch('setModal', {
      activeModal: 'Exercise Settings',
      modalVisible: true,
    })
  }
}
