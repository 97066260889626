// store
import Store from '@/store'

// types
import { EaseElement, ElementApiResponse } from '@/types/interfaces/EaseElement'

// npm
import { AxiosRequestConfig } from 'axios'
import { saveAs } from 'file-saver'
import { easeApi } from '@/api/ease.api'

/*
      ======================================================
                  A P I  -   E L E M E N T S
      ======================================================
*/

/**
 * Update the current elements from the API
 * a) get the current elements from the API
 * b) update the links
 * c) update the store
 */

export const fetchElements = async (config?: AxiosRequestConfig): Promise<ElementApiResponse> => {
  return easeApi.get<ElementApiResponse>('elements', config).then(res => {
    const elementsWithRelationships = res.data.elements.map(element => ({
      ...element,
      relationships: element.relationships || [], // Add relationships key if it doesn't exist (for old elements)
    }))

    return {
      ...res.data,
      elements: elementsWithRelationships,
    }
  })
}

export const mergeElementApiResponse = (
  elementRes: ElementApiResponse,
  currentElements: EaseElement[]
): EaseElement[] => {
  // remove deleted and updated elements
  // gather all updated/deleted UUIDs
  const elementRemovalList = [...elementRes.updated, ...elementRes.deleted]
  currentElements = currentElements.filter(
    (curElement: EaseElement) => !elementRemovalList.includes(curElement.metadata.uuid)
  )

  // combine existing up-to-date elements with added elements
  return [...currentElements, ...elementRes.elements]
}

/**
 *  Sends a request to the API to verify the existence of the files, notifying it
 *  to update them if they have been generated
 *
 * @param elements
 */
export const verifyElementFiles = async (elements: EaseElement[]): Promise<void> => {
  const uuidArr: string[] = []
  if (elements.length) {
    elements.forEach((element: EaseElement) => {
      if (element.template.metadata.init && !element.metadata.file?.exists) {
        uuidArr.push(element.metadata.uuid)
      }
    })
    if (uuidArr.length) {
      // fire off the API call here, just one, for all data in the uuidArr
      easeApi.post('files/verify', JSON.stringify(uuidArr)).catch(err => {
        console.error(`err: ${err}`)
      })
    }
    return
  }
}

/**
 * This function gets all the trashed elements for the exercise
 * and updates the store
 * @return {Promise<void>} The concluded Axios request
 */
export const fetchTrashedElements = (): Promise<EaseElement[]> => {
  return easeApi.get<EaseElement[]>('elements/trashed').then(res => res.data)
}

/**
 * Either post (new element) or put (edited element) an element to
 * EASE API. Calls the file generation API route if the element has files
 * to generate
 *
 * @param {EaseElement} element - the EaseElement to post or put
 * @param {boolean} quiet - if true, don't increment the exercise updates
 * @param {boolean} builder - if true, don't increment the exercise updates
 * @return {Promise<void>} The concluded Axios request
 */
export const submitOrUpdateElement = (
  element: EaseElement,
  quiet = false,
  builder = false
): Promise<void> => {
  let update = (element.metadata?.version ?? 0) > 1 || quiet

  if (builder) {
    update = !!Store.getters.getDndEntityEditorElement(element.metadata.uuid)
    if (!update) Store.dispatch('updateDndEntityEditorElements', element)
  }

  const method = update ? 'put' : 'post'
  const url = update ? 'element/update' : 'element'

  // send the element to the api
  // TODO: use axios.request instead of getting the method by prop access
  return easeApi[method](`${url}${quiet ? '?quiet=true' : ''}`, element)
    .then(() => {
      if (!quiet) {
        // only pass this Axios req if the template/element has files to generate
        element?.template?.metadata?.init &&
          easeApi
            .get(`files/generate/${element.metadata.uuid}`)
            .then(() => {
              Store.dispatch('setAlert', {
                text: `Element ${element.metadata.name} Generated.`,
                type: 'success',
              })
              Store.dispatch('incrementExUpdates')
            })
            .catch(error => {
              console.error('Error: EaseElement file creation faild!', error)
              Store.dispatch('setAlert', {
                text: `Error: EaseElement file creation failed!: ${error}`,
                type: 'error',
              })
            })
      }
    })
    .catch(error => {
      console.error(error)
      Store.dispatch('setAlert', {
        text: `Error: EaseElement could not be stored in the Database!: ${error}`,
        type: 'error',
      })
    })
}

/**
 * Send an element's UUID to EASE API to be trashed, which will remove it from the exercise but not the database.
 *
 * @param {EaseElement} element
 * @param {boolean} trashedState
 * @return {Promise<void>} The concluded Axios request
 */
export const setElementTrashed = async (element: EaseElement, trash: boolean): Promise<void> => {
  easeApi
    .put(`element/trash/${element.metadata.uuid}?trash=${trash}`)
    .then(() => {
      // remove the element from the store
      if (trash) Store.dispatch('removeElement', element?.metadata?.uuid)
      Store.dispatch('incrementExUpdates')

      // send an alert that the element was deleted
      Store.dispatch('setAlert', {
        // if {deleted: false, trashed: false} then it was restored
        // if {deleted: true, trashed: false} then it was permanently deleted
        // if {deleted: true, trashed: true} then it was trashed
        text: trash
          ? `Successfully Deleted ${element.metadata.name}`
          : `Successfully Restored ${element.metadata.name}`,
        type: 'success',
      })
    })
    .catch(() => {
      Store.dispatch('setAlert', {
        text: `Failed to Trash ${element.metadata.name}`,
        type: 'error',
      })
    })
}

/**
 * Send an element's UUID to EASE API to be deleted from the database entirely.
 *
 * @param {EaseElement} element
 * @param {boolean} deleteState
 * @return {Promise<void>} The concluded Axios request
 */
export const deleteElement = (element: EaseElement): void => {
  easeApi
    .delete(`element/delete/${element.metadata.uuid}`)
    .then(() => {
      // send an alert that the element was deleted
      Store.dispatch('incrementExUpdates')
      Store.dispatch('setAlert', {
        text: `Successfully Deleted Element: ${element.metadata.name}`,
        type: 'success',
      })
    })
    .catch(() => {
      Store.dispatch('setAlert', {
        text: `Failed to Delete: ${element.metadata.name}`,
        type: 'error',
      })
    })
}

/*
      ======================================================
                 H E L P E R S  -   E L E M E N T S
      ======================================================
*/

/**
 * Toggles the released key in an element to show if it has been released
 * to the PTA yet
 *
 * @param {string} elementUUID
 */
export const toggleReleasedStatus = async (elementUUID: string): Promise<void> => {
  return easeApi.put(`element/release/${elementUUID}`)
}

/**
 * Sends a request to EASE API to download an element file. The res data
 * is returned as a blob and saved
 *
 * @param {string} elementUuid
 * @param {string} fileType
 */
export const downloadElementFile = (elementUuid: string, fileType: string): void => {
  Store.dispatch('setAlert', {
    text: `Submitting download request for ${fileType} to the API...`,
    type: 'info',
  })

  // init a blank var to save the filename header
  let filename: string

  easeApi
    .get(`files/${elementUuid}/${fileType}`, { responseType: 'blob' })
    .then(res => {
      filename = res.headers['x-filename'].replace(/\s/g, '_') // replace spaces with underscores
      return new Blob([res.data])
    })
    .then(res => {
      saveAs(res, filename)
      Store.dispatch('setAlert', {
        text: `Successfully downloaded ${fileType} file!`,
        type: 'success',
      })
    })
    .catch(err => {
      console.error('Error, file could not be retrieved', err)
      Store.dispatch('setAlert', {
        text: `Failed to download ${fileType} file!`,
        type: 'error',
      })
    })
}

/**
 * Checks if all fields of an element are valid, returns true if valid,
 * false if not
 *
 * @param {boolean} log
 * @param {EaseElement} element
 * @return {boolean}
 */
export const elementValid = (element: EaseElement, log = true): boolean => {
  // validate all the element fields
  try {
    const data = element.data
    const metadata = element.metadata

    const validation: { [key: string]: boolean } = {
      // metadata:
      intent: !!metadata.excon.intent,
      exercise: !!metadata.exercise && typeof metadata.exercise === 'string',
      name: !!metadata.name && typeof metadata.name === 'string',
      owned: !!metadata.owned && typeof metadata.owned === 'string',
      uuid: !!metadata.uuid && typeof metadata.uuid === 'string',
      time: metadata?.time ? 'type' in metadata.time && 'start' in metadata.time : true,
      data: !!data.length,
    }

    // if every one of the above keys is true, then the element is valid
    let elementValid = Object.values(validation).every(value => !!value)

    // check rules on form based elements
    if (element.template.metadata.type.supertype !== 'Document') {
      // get the element validation status from the store
      const ruleValidationStatus = Store.getters.getActiveElementValidity

      // if the element is valid, but the rules are not, then the element is invalid
      if (!ruleValidationStatus) {
        // if the element is invalid, then the element is invalid
        elementValid = false
      }
    }

    if (element) {
      if (log && process.env.NODE_ENV === 'development') {
        !elementValid && console.warn('Element INVALID', element.metadata.name, validation)
      }
    }

    return elementValid
  } catch (error) {
    console.error(`Error validating Element, ${element?.metadata?.name} Error: ${error}`)
    return false
  }
}

/**
 * This function updates multiple elements' data by string silently on the API without adding an update to the element
 * @param {Array} elementsUpdates - an array of objects with the following structure:
 * {
 *   uuid: string,
 *   updates: [
 *     {
 *       path: string,
 *       value: string
 *     }
 *   ]
 * }
 * @returns {void}
 */
export const batchSilentUpdateElements = async (
  elementsUpdates: { uuid: string; updates: { path: string; value: string }[] }[]
): Promise<void> => {
  try {
    await easeApi.put(`element/batch-update/by-string`, elementsUpdates)
  } catch (error) {
    console.error(`Error updating batch elements: ${error}`)
    throw error
  }
}

/**
 * duplicateElement
 *
 * This function sends a POST request to duplicate an element identified by its UUID.
 * Upon a successful operation, a success alert is dispatched.
 * If there's an error, the error is logged to the console and an error alert is dispatched.
 *
 * @async
 * @function
 * @param {string} elementUuid - The UUID of the element to be duplicated.
 * @returns {Promise<void>} A Promise that resolves when the operation is complete.
 *
 *
 **/

export const duplicateElement = async (elementUuid: string): Promise<void> => {
  try {
    await easeApi.post(`element/duplicate/${elementUuid}`)
    Store.dispatch('setAlert', {
      text: 'Element Duplicated',
      type: 'success',
    })
  } catch (err) {
    console.error(err)
    Store.dispatch('setAlert', {
      text: 'Error duplicating element',
      type: 'error',
    })
  }
}
