
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { EaseElement } from '@/types/interfaces/EaseElement'
import { DEFAULT_DOCUMENT_STYLE, getElementStyle } from '@/modules/document-editor/util/styles'
import { DocumentStyleWithPaddingUnits } from '@/modules/document-editor/util/styles'

@Component
export default class DocumentContent extends Vue {
  @Prop() document!: EaseElement
  @Prop({ default: false }) enableZoom!: boolean

  get documentWidth(): { width: string; maxWidth: string } {
    const width = this.documentStyle.orientation === 'landscape' ? 1056 : 816
    return { width: `${width}px`, maxWidth: `${width}px` }
  }

  get documentStyle(): DocumentStyleWithPaddingUnits {
    return getElementStyle(this.document, DEFAULT_DOCUMENT_STYLE).withPaddingUnits()
  }

  observer!: ResizeObserver

  $refs!: {
    wrapper: HTMLDivElement
    page: HTMLDivElement
    content: HTMLDivElement
  }

  showDocumentPreview(): void {
    if (this.enableZoom) {
      this.$emit('showDocumentPreview')
    }
  }

  @Watch('document', { deep: true })
  onDocumentChange() {
    this.$nextTick(() => {
      this.resize()
    })
  }

  mounted(): void {
    this.observer = new ResizeObserver(() => this.resize())
    this.observer.observe(this.$refs.wrapper)
  }

  beforeDestroy() {
    // Clean up the observer
    this.observer.disconnect()
  }

  resize(): void {
    if (this.$refs.page && this.$refs.wrapper) {
      // get the wrapper, page and content elements
      const wrapper = this.$refs.wrapper
      const page = this.$refs.page

      // get the width the wrapper
      const wrapperWidth = wrapper.offsetWidth

      // get the width the page
      const pageWidth = page.offsetWidth
      const pageHeight = page.offsetHeight

      // the ratio is porportional to the width of the wrapper
      // but as the scale property affects height too we don't need to account for it
      const widthRatio = wrapperWidth / pageWidth

      // we need the ratio, to calculate the new height of the wrapper
      const ratio = parseFloat(Math.min(widthRatio, 1).toPrecision(4))

      const height = pageHeight * ratio

      // Check if scrollbar is present
      if (wrapper.scrollHeight > wrapper.clientHeight) {
        // Hide the scrollbar and show full content
        wrapper.style.overflowY = 'hidden'
      } else {
        // Show the scrollbar
        wrapper.style.overflowY = 'auto'

        // Apply the calculated height
        wrapper.style.height = `${height}px`
        wrapper.style.maxHeight = `${height}px`
      }
      page.style.transform = `scale(${ratio})`
    }
  }
}
