
import { Component, Prop, Vue } from 'vue-property-decorator'
import { EaseElement } from '@/types/interfaces/EaseElement'

// mixins
import { reformatDate } from '@/mixins/general/helpers'

// component
import ElementHistoryDetails from '@/components/Common/Elements/ElementHistoryDetails.vue'

@Component({ components: { ElementHistoryDetails } })
export default class ElementMetadata extends Vue {
  @Prop() easeElement!: EaseElement

  // calendar date (import reformatDate() from helpers.ts)
  reformatDate = reformatDate

  // show history dialog
  toggleHistoryDialog = false
  get ownerUsername(): string {
    return this.$store.getters.getExUserByUuid(this.easeElement.metadata.owned).username
  }
}
