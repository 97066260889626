import { render, staticRenderFns } from "./UserElementHistory.vue?vue&type=template&id=6ca29120&scoped=true&"
import script from "./UserElementHistory.vue?vue&type=script&setup=true&lang=ts&"
export * from "./UserElementHistory.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./UserElementHistory.vue?vue&type=style&index=0&id=6ca29120&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ca29120",
  null
  
)

export default component.exports