import crypto from 'crypto'
import { genUUID } from '@/mixins/general/helpers'

// H E L P E R S

/**
 * Get random string from an array of strings
 *
 * @param {string[]} list
 * @return {string} the randomly selected string
 */
const random = (list: string[]): string => {
  return list[Math.floor(Math.random() * list.length)]
}

/**
 * Convert an intereger to its corresping ascii character
 *
 * @param {number} num
 * @return {string} the corresponding ascii character
 */
const intToChar = (num: number): string => {
  const code = 'A'.charCodeAt(0)
  return String.fromCharCode(code + num)
}

/**
 * Gets a random number from 1-255
 */
const octet = (): string => genRandomNumber(1, 255)

/**
 * Get a random num between a specified min and max
 *
 * @param {number} min
 * @param {number} max
 * @return {string} the random number in the range as a string
 */
export const genRandomNumber = (min: number, max: number): string =>
  String(Math.floor(Math.random() * max) + min)

/**
 * Get a string of random characters of a specified length (default = 10)
 *
 * @param {number} length
 * @return {string} a string of length = length of random characters
 */
export const genRandomChars = (length = 10): string =>
  crypto.randomBytes(length / 2).toString('hex')

/**
 * Generate a random dark color hex code
 *
 * @return {string} the dark color's hex code as a string
 */
export const genRandomDarkColor = (): string => {
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 10)
  }
  return color
}

/**
 * Get a constant color by index from an array of pastel colors
 *
 * @param {number} index
 * @return {string} HEX color
 */
export const getColorByIndex = (index: number): string => {
  const colors = [
    '#2196F3',
    '#4CAF50',
    '#00796B',
    '#1DE9B6',
    '#FF6F00',
    '#6A1B9A',
    '#3F51B5',
    '#2E7D32',
    '#FDD835',
    '#00BCD4',
    '#795548',
    '#3949AB',
    '#00695C',
    '#43A047',
    '#FFB300',
    '#37474F',
    '#8E24AA',
    '#00C853',
    '#6200EA',
    '#5D4037',
  ]
  return colors[index % colors.length]
}

// D A T A   G E N E R A T O R S

/**
 * Generate a valid MAC address
 *
 * @return {string} MAC address (ex. AB:CD:EF:01:23:45)
 */
export const genMAC = (): string => {
  return [...Array(6).keys()]
    .map(() => genRandomChars(2))
    .join(':')
    .toUpperCase()
}

/**
 * Generate a valid IP address
 *
 * @return {string} IP address from 1.0.0.0 - 255.255.255.255
 */
export const genIP = (): string => {
  // gen a random octet from 1-255
  const octet = (): string => genRandomNumber(1, 255)

  // return four of them as an IP
  return `${octet()}.${octet()}.${octet()}.${octet()}`
}

/**
 * Generate a valid MAID
 *
 * @return {string} MAID (ex. 3f097372-f01e-4b64-984c-395ae5828ee6)
 */
export const genMAID = (): string => {
  return [8, 4, 4, 4, 12].map((count: number) => genRandomChars(count)).join('-')
}

/**
 * Generate a valid BSSID
 *
 * @return {string} BSSID (ex. 00:00:00:00:00)
 */
export const genBSSID = (): string => {
  // gets a number from 00-99
  const genDoubleDigit = (): string => `${genRandomNumber(0, 9)}${genRandomNumber(0, 9)}`
  return `${genDoubleDigit()}:${genDoubleDigit()}:${genDoubleDigit()}:${genDoubleDigit()}:${genDoubleDigit()}`
}

// Generates a random user agent string
export const genUA = (): string => {
  const os = random([
    'Windows NT 10.0; Win64; x64,Macintosh',
    'Intel Mac OS X 10_15_7, X11',
    'Linux x86_64; rv: 105.0',
  ])
  const browser = random([
    'Gecko/20100101 Firefox/106.0',
    'AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36',
    'AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36 Edg/91.0.864.59',
  ])

  return `Mozilla/5.0 (${os}) ${browser}`
}

/**
 * Generate a valid random MGRS string
 *
 * @return {string} MGRS (ex. 18TXR2649530601)
 */
export const genMGRS = (): string => {
  // Generate random character from A-Z which isn't I or
  const genCharacter = (): string => {
    let character = parseInt(genRandomNumber(3, 19))
    while (character == 8 || character == 14) {
      character = parseInt(genRandomNumber(3, 19))
    }
    return intToChar(character)
  }

  const coords = ['', '']
  for (let i = 0; i < 5; i++) {
    coords[0] += genRandomNumber(0, 9)
    coords[1] += genRandomNumber(0, 9)
  }

  return `${genRandomNumber(0, 59)}${genCharacter()}${genCharacter()}${genCharacter()} ${
    coords[0]
  } ${coords[1]}`
}

/**
 * Generate a dafault gateway
 *
 * @return {string} Gateway string (ex. 192.168.99.1)
 */
const genGate = () => {
  return `${octet()}.${octet()}.${octet()}.1`
}

// generator Engine!
export const GE = {
  genMAID: (): string => genMAID(),
  genRandomNumber: (args: { min: number; max: number }): string =>
    genRandomNumber(args.min, args.max),
  genMAC: (): string => genMAC(),
  genUUID: (): string => genUUID(),
  genUA: (): string => genUA(),
  genIP: (): string => genIP(),
  genBSSID: (): string => genBSSID(),
  genMGRS: (): string => genMGRS(),
  genGate: (): string => genGate(),
}
