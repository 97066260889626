import axios from 'axios'
import { EaseAppConfig, EaseConfig, EaseRemoteConfig } from '@/types/interfaces/EaseConfig'

let easeConfig: EaseConfig | null = null

/**
 * Get the EASE Config synchronously. This will throw an error if the config has not been loaded.
 *
 */
export function getEaseConfig(): EaseConfig {
  if (!easeConfig) {
    throw new Error(
      'EaseConfig needs to be loaded before rendering the app or before using it at the top level of any module'
    )
  }
  return easeConfig
}

/**
 * Load the config from the following sources: public/config.json
 *                                             <ease-api>/config
 *                                             env variables
 *
 * The latter take precedence, allowing shared configs to come from the API, and config variables to be overwritten
 * in environments where the config.json may not be accessible but env vars are.
 *
 * @returns a promise that resolves when the config is fetched
 */
export async function loadConfig(): Promise<void> {
  if (!!easeConfig) {
    return // config is already loaded
  }
  // Get the EASE Application Config
  const appConfig = await axios.get<EaseAppConfig>('/config.json').then(res => {
    if (!res.headers['content-type'].includes('application/json')) {
      throw new Error('Unexpected ease config content type. Expected application/json.')
    }
    return res.data
  })

  // Get the EASE Remote config
  let apiConfig: EaseRemoteConfig
  try {
    apiConfig = await axios
      .get<EaseRemoteConfig>(`${appConfig.EASE_API_URL}/config`)
      .then(r => r.data)
  } catch (e) {
    console.error('Unable to retrieve remote config')
    // If we can't get the remote config, we'll just use the default
    apiConfig = {
      FEATURE_AI: 'false',
    }
  }

  easeConfig = { ...appConfig, ...apiConfig }
}
