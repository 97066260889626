// Store
import Store from '@/store'

// types
import { DynamicField, Template } from '@/types/interfaces/Template'
import {
  EaseElement,
  ElementData,
  ElementDataImageArray,
  ElementDataStringObjArray,
} from '@/types/interfaces/EaseElement'

// mixins
import { genUUID, setFieldsValidity } from '@/mixins/general/helpers'
import { submitOrUpdateElement } from '@/mixins/api/elements'
import { easeApi } from '@/api/ease.api'
import { linkValueToObject } from '../general/linking'

/**
 * Initialize an element or update an element when editing
 * validates the element data and sets the correct modal.
 *
 * @param {Template} template
 * @param {EaseElement} element
 */
export const initActiveElement = (
  template: Template,
  element: EaseElement | null = null,
  builderMetadata: { x: number; y: number } | null = null
): void => {
  if (!builderMetadata) {
    Store.dispatch('setAlert', {
      text: `Loading Template: ${template.metadata.name}`,
      type: 'success',
    })
  }

  // get user data and merge the template with the new element
  if (!element) {
    // gen UUID and get template data from the store
    const elementUUID = genUUID()
    const shortClassification = Store.getters.getShortClassification
    const user = Store.getters.getUser
    const exercise = Store.getters.getEx

    // Use Object assign
    element = {
      template,
      data: [],
      metadata: {
        uuid: elementUUID,
        name: '',
        exercise: exercise.metadata.uuid,
        version: builderMetadata ? 0 : 1,
        builder: !!builderMetadata,
        owned: user.uuid,
        // if the template is supplemental ignore time
        time: null,
        excon: {
          intent: '',
          classification: '',
          source: '',
          released: false,
        },
        created: '',
        updated: '',
        valid: false,
        trashed: false,
        deleted: false,
        history: [],
        dndData: {
          x: 0,
          y: 0,
        },
      },
      links: [],
      relationships: [],
    }

    if (builderMetadata) {
      if (!element) return
      element.metadata.dndData = {
        x: builderMetadata.x,
        y: builderMetadata.y,
      }
    }

    // if the template is document generating, initalize the "file" key on the element
    if (template.metadata.init) {
      if (!element) return
      element.metadata.file = {
        condition: 'Not Processed',
        createdAt: undefined,
        exists: false,
        fileSize: undefined,
      }
    }

    // if the element is not a document, loop through the template fields
    // and copy template field properties to the new element
    if (template.metadata.type.supertype !== 'Document') {
      if (!template.fields) throw new Error('Template fields are undefined')
      if (!element) throw new Error('Element is undefined')
      element.data = template.fields.map((field: DynamicField, index) => {
        // build the data object
        return {
          uuid: genUUID(),
          type: field.fieldType,
          order: index,
          element: elementUUID,
          name: field.name,
          hidden: field.hidden ?? false,
          content: null,
          tags: field.tags ? field.tags : ['No Known Tags'],
          classification: field.classification ? shortClassification : null,
          valid: false,
          style: undefined,
        } as ElementData
      })
    } else {
      // make a copy of the element
      element = structuredClone(element)
      if (!element) throw new Error('Element is undefined')
      // for documents: add the docHTML to the data array with title name and content string value
      element.data = [
        {
          uuid: genUUID(),
          content:
            template.html?.content ??
            '<div style="font-family: Arial, Helvetica, sans-serif"></div>',
          element: element.metadata.uuid,
          type: 'html',
          order: 1,
          name: 'html',
          tags: [
            'HTML Document',
            element.template.metadata.type.supertype,
            element.template.metadata.type.type,
          ],
          classification: '',
          style: undefined,
        },
      ]
    }
    // if the Element was supplied and we're editing
  } else {
    // make a copy of the element
    element = structuredClone(element)
    // update the version number
    element.metadata.version = (element.metadata?.version ?? 2) + 1
  }

  if (!builderMetadata) {
    // after building an Element, send it to state
    Store.dispatch('setActiveElement', element)

    // if the element is form based then set the fields validity
    // now that the element is in state
    if (element.template.metadata.type.supertype !== 'Document') {
      setFieldsValidity()
    }

    // open the modal corresponding to the new element being created
    // if we're editing, and the supertype is "Document"
    if (
      element.metadata.version &&
      element.metadata.version <= 1 &&
      template.metadata.type.supertype === 'Document' &&
      template.metadata?.builtin
    ) {
      // If it's a builtin document template
      Store.dispatch('setModal', {
        activeModal: 'Element Editor',
        modalVisible: true,
      })
      // Ifs its a built in document and the type is data show Data Generation Modal
    } else if (
      element.metadata.version &&
      element.metadata.version <= 1 &&
      template.metadata?.builtin &&
      template.metadata.type.supertype === 'Data'
    ) {
      Store.dispatch('setModal', {
        activeModal: 'Data Generation',
        modalVisible: true,
      })
    } else {
      // otherwise, open the Element Editor
      Store.dispatch('setModal', {
        activeModal: 'Element Editor',
        modalVisible: true,
      })
    }
  } else {
    submitOrUpdateElement(element, true, true)
  }
  return
}

/**
 * Take the active element from the store and send it to the backend
 * for storage
 */

export const submitActiveElement = (clearActiveElement = true): void => {
  // build the element to be sent to the backend
  const element: EaseElement = Store.getters.getActiveElement

  // if the element supertype is a report
  submitOrUpdateElement(element, element.metadata.builder, element.metadata.builder).then(() => {
    if (clearActiveElement) {
      const currentActiveElement: EaseElement = Store.getters.getActiveElement
      if (currentActiveElement?.metadata.uuid === element.metadata.uuid) {
        Store.dispatch('clearActiveElement')
      }
    }
  })

  if (!element.metadata.builder) {
    Store.dispatch('setAlert', {
      text: `Submitting ${element.metadata.name}`,
      type: 'success',
    })
  }

  // close the modal, let the Element submit on its own
  Store.dispatch('setCloseModal')
}

/**
 * parse through all the documents in the exercise, parse their html, match any links
 * that refer to the entity being updated, update the link content, sent the update to the api
 *
 * @param {EaseElement} updatedEntity
 */
export const updateDocumentsWithEntityLink = async (updatedEntity: EaseElement): Promise<void> => {
  const entityUUID: string = updatedEntity.metadata.uuid // alias for easy access

  const exDocuments: EaseElement[] = Store.getters.getAllElements.filter(
    (e: EaseElement) => e.template.metadata.type.supertype === 'Document'
  )

  // helper
  const getFieldContentFromEntityByUUID = (
    uuid: string
  ): string | ElementDataStringObjArray[] | ElementDataImageArray[] | null => {
    for (const element of updatedEntity.data) {
      const datum = element
      if (datum.uuid === uuid) {
        return datum.content
      }
    }

    return null
  }

  // loop through all the documents
  for (const element of exDocuments) {
    const document: EaseElement = element
    const documentContent: string = document.data[0]?.content as string

    let updateCount = 0

    // check if document references the entity we have updated
    if (documentContent.match(entityUUID)) {
      // match all links in the document content
      const documentLinkRegex = /<span.+?data-link-id="(\{\{[^\s]+?\}\})".*?>(.+?)<\/span>/g
      const matches = [...documentContent.matchAll(documentLinkRegex)]

      // for each match...
      for (const element of matches) {
        const match = element // alias the match
        // we are only looking for links that reference the entity we just updated
        if (!match[1].includes(`element=${entityUUID}`)) {
          continue
        }

        // get the updated content for the link based on the link value from the capture group
        const linkObj = linkValueToObject(match[1])

        if (!linkObj.data) {
          console.error(`linkObj: ${linkObj}`)
          throw new Error(
            'Unexpected error when initializing the active element: link data should not be null'
          )
        }

        const updatedContent = getFieldContentFromEntityByUUID(linkObj.data) as string

        // replace the link content in the document
        if (updatedContent !== match[2]) {
          const newSpan = match[0].replace(match[2], updatedContent)
          document.data[0].content = (document.data[0].content as string).replace(match[0], newSpan)
          updateCount++
        }
      }

      // send update to the database
      if (updateCount > 0) {
        easeApi.put('element/update', document).catch(error => {
          console.error(error)
          Store.dispatch('setAlert', {
            text: `Error updating document
              ${document.metadata.name} with UUID ${document.metadata.uuid}}`,
            type: 'error',
          })
        })
      }
    }
  }
}
