// npm
// router
import router from '@/router'

// mixins
import { clearEaseAppStore } from '@/mixins/general/store'
import { easeApi } from '@/api/ease.api'

/**
 * Checks if the user has a valid session with the server
 * @returns {Promise<boolean>} is the session currently valid from the API
 */
export function validateSession(): Promise<boolean> {
  return easeApi
    .get('user/validate')
    .then((res: { data: { valid: boolean } }) => {
      return res.data.valid
    })
    .catch(err => {
      console.error('Validate Failed', err)
      return false
    })
}

// log the user out and destroy the session cookie
export function logOut(): void {
  clearEaseAppStore()
  easeApi.get('user/logout').finally(() => {
    console.log('Logging out!')
    // clear the user session cookie
    document.cookie = 'ease-session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    // redirect to login
    router.push('/login')
  })
}
