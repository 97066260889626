import { getEaseConfig } from '@/easeConfig'

export const logEaseAscii = (): void => {
  console.info(
    `
%c
▓█████  ▄▄▄        ██████ ▓█████
▓█   ▀ ▒████▄    ▒██    ▒ ▓█   ▀
▒███   ▒██  ▀█▄  ░ ▓██▄   ▒███
▒▓█  ▄ ░██▄▄▄▄██   ▒   ██▒▒▓█  ▄
░▒████▒ ▓█   ▓██▒▒██████▒▒░▒████▒
░░ ▒░ ░ ▒▒   ▓▒█░▒ ▒▓▒ ▒ ░░░ ▒░ ░
░ ░  ░  ▒   ▒▒ ░░ ░▒  ░ ░ ░ ░  ░
  ░     ░   ▒   ░  ░  ░    ░
  ░  ░      ░  ░      ░    ░  ░
%c
Copyright 2023 H2 Analytics
Support/feedback: dev@h2analytics.ca
H2A Website: www.h2analytics.ca
EASE APP Version: %c${getEaseConfig().EASE_VERSION}`,
    `
      color: #fca311;
      font: normal 900 .8rem monospace;
    `,
    `
      color: #fca311;
      font: condensed 500 .8rem math, serif;
    `,
    `background-color: #fca311;
    color: #000;
    font: 900 .8rem monospace, serif;
    padding: 0 .25rem;
    margin: 0 .15rem;
    border-radius: 5px;`
  )
}

export const logHeartBeat = (): void => {
  console.info(
    `%c♥·♫ %c${new Date()
      .toLocaleString('en', {
        timeZoneName: 'short',
        hour12: false,
        day: '2-digit',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(', ', '@')
      .replace('/', '|')} `,
    'color: #fca311; font-size: .8rem',
    'color: #4caf50; font-weight: bold; font-size: .8rem'
  )
}
