
import { Component, Vue } from 'vue-property-decorator'

import ArcGISMap from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'
import CoordinateConversion from '@arcgis/core/widgets/CoordinateConversion'
import Format from '@arcgis/core/widgets/CoordinateConversion/support/Format'

@Component({ components: { GeoMapBase: () => import('@/components/Common/Geo/GeoMapBase.vue') } })
export default class GeoCoordsPicker extends Vue {
  /**
   * The following properties are used to store the map and view objects
   * they are passed to the slot from the GeoMapBaseDialog component emission
   */
  map: ArcGISMap | null = null
  view: MapView | null = null
  graphicsLayer: GraphicsLayer | null = null

  /**
   * When the map loads, we store the map, view, and baseLayer objects
   * Then we create a point to demo the new functionality
   */
  handleMapInit(mapData: { map: ArcGISMap; view: MapView; graphicsLayer: GraphicsLayer }): void {
    this.map = mapData.map
    this.view = mapData.view
    this.graphicsLayer = mapData.graphicsLayer

    const ccWidget: CoordinateConversion = new CoordinateConversion({
      view: mapData.view,
      locationSymbol: {
        type: 'simple-marker',
        style: 'cross',
        color: 'red',
        size: '24px',
        outline: {
          // autocasts as new SimpleLineSymbol()
          color: [255, 0, 0],
          width: 1, // points
        },
      },
    })

    // when the ccWidget loads clear the formats we don't need
    // and format the coordinate string to be X, Y
    ccWidget.when(() => {
      // remove the conversions we don't want
      const toRemove = ccWidget.formats.filter(format => !['mgrs', 'xy'].includes(format.name))
      ccWidget.formats.removeMany(toRemove)

      ccWidget.formats.forEach((format: Format) => {
        if (format.name === 'xy') {
          format.currentPattern = 'Y, X'
          format.defaultPattern = 'Y, X'
        }
      })
    })

    // then add that sucker
    mapData.view.ui.add(ccWidget, 'bottom-right')
  }
}
