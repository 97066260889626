
import { Component, Vue } from 'vue-property-decorator'
import Nav from '@/components/Nav/Nav.vue'

import GeoCoordsPicker from '@/components/Common/Geo/GeoCoordsPicker.vue'

import Alert from '@/components/Common/Alert/Alert.vue'

export const EventBus = new Vue()
@Component({
  components: {
    Nav,
    Alert,
    GeoCoordsPicker,
  },
})
export default class App extends Vue {}
