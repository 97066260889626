
import { Component, Prop, Vue } from 'vue-property-decorator'
import VueComponent from 'vue'
import { ElementDataMatch } from '@/types/interfaces/Link'

@Component
export default class LinkSearchResultsList extends Vue {
  @Prop() items!: ElementDataMatch[]
  @Prop() highlightIndex!: number

  $refs!: {
    searchResultsList: VueComponent & { $refs: { input: HTMLElement } }
  }

  get scrollToCurrentIndex() {
    const itemHeight = 100
    const maxScrollBottom =
      (this.$refs.searchResultsList.$el as HTMLElement).scrollHeight -
      (this.$refs.searchResultsList.$el as HTMLElement).clientHeight
    return Math.min(this.highlightIndex * itemHeight, maxScrollBottom)
  }

  mounted() {
    // scroll to the appropriate position
    this.$refs.searchResultsList.$el.scrollTo({
      top: this.scrollToCurrentIndex,
      behavior: 'smooth',
    })
  }

  updated() {
    // scroll to the appropriate position
    this.$refs.searchResultsList.$el.scrollTo({
      top: this.scrollToCurrentIndex,
      behavior: 'smooth',
    })
  }
}
