var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-100 overflow-y-auto",attrs:{"color":"secondary"}},[_c('v-card',{staticClass:"pa-1 w-100 overflow-y-auto",attrs:{"height":"80vh","color":"primary"}},[_c('div',{staticClass:"d-flex flex-column ma-3"},[_c('v-card',{staticClass:"d-flex mb-5 align-center px-2",attrs:{"elevation":"4"}},[_c('div',{staticClass:"fb-3"},[_c('p',{staticClass:"text-overline font-weight-bold ml-1 mb-1 accent--text pa-1"},[_vm._v("Exercise Name")]),_c(_setup.ExerciseNameInput,{attrs:{"name":_setup.exerciseSettingsRefs['Name']},on:{"exerciseName":_setup.updateExerciseName}})],1),_c('div',{staticClass:"fb-3"},[_c('p',{staticClass:"text-overline font-weight-bold ml-1 mb-1 accent--text pa-1"},[_vm._v(" Exercise Start Date ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({ref:"startDateRef",attrs:{"dense":"","readonly":"","outlined":"","width":"30%","rules":_setup.startDateRule,"color":"accent","hint":"The starting date for the Exercise","persistent-hint":""},model:{value:(_setup.exerciseSettingsRefs['Start Date']),callback:function ($$v) {_vm.$set(_setup.exerciseSettingsRefs, 'Start Date', $$v)},expression:"exerciseSettingsRefs['Start Date']"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_setup.menuStatus.start.value),callback:function ($$v) {_vm.$set(_setup.menuStatus.start, "value", $$v)},expression:"menuStatus.start.value"}},[_c('v-date-picker',{on:{"input":function($event){_setup.menuStatus.start.value = false},"change":function($event){return _setup.updateExSettingFields({
                  'Start Date': _setup.exerciseSettingsRefs['Start Date'],
                })}},model:{value:(_setup.exerciseSettingsRefs['Start Date']),callback:function ($$v) {_vm.$set(_setup.exerciseSettingsRefs, 'Start Date', $$v)},expression:"exerciseSettingsRefs['Start Date']"}})],1)],1),_c('div',{staticClass:"fb-3"},[_c('p',{staticClass:"text-overline font-weight-bold ml-1 mb-1 accent--text pa-1"},[_vm._v(" Exercise End Date ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({ref:"endDateRef",attrs:{"dense":"","readonly":"","outlined":"","width":"30%","rules":_setup.endDateRule,"color":"accent","hint":"The ending date for the Exercise","persistent-hint":""},model:{value:(_setup.exerciseSettingsRefs['End Date']),callback:function ($$v) {_vm.$set(_setup.exerciseSettingsRefs, 'End Date', $$v)},expression:"exerciseSettingsRefs['End Date']"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_setup.menuStatus.end.value),callback:function ($$v) {_vm.$set(_setup.menuStatus.end, "value", $$v)},expression:"menuStatus.end.value"}},[_c('v-date-picker',{on:{"input":function($event){_setup.menuStatus.end.value = false},"change":function($event){return _setup.updateExSettingFields({
                  'End Date': _setup.exerciseSettingsRefs['End Date'],
                })}},model:{value:(_setup.exerciseSettingsRefs['End Date']),callback:function ($$v) {_vm.$set(_setup.exerciseSettingsRefs, 'End Date', $$v)},expression:"exerciseSettingsRefs['End Date']"}})],1)],1)]),_c('v-card',{staticClass:"fccc align-center px-2 w-100",attrs:{"elevation":"4"}},[_c('div',{staticClass:"w-100 px-4"},[_c('p',{staticClass:"text-overline font-weight-bold ml-1 accent--text pa-1"},[_vm._v(" Exercise Classification (Long Form) ")]),_c('v-combobox',{attrs:{"outlined":"","dense":"","attach":"","persistent-hint":"","items":_setup.classificationOptions.long,"item-color":"accent","color":"accent","rules":[_setup.rules?.isRequired()],"hint":"The Exercise classification, for example:\n                     'UNCLASSIFIED//FOR EXERCISE USE ONLY' or your own custom value","placeholder":"e.g. 'UNCLASSIFIED'"},on:{"change":function($event){return _setup.updateExSettingFields({
                Classification: _setup.exerciseSettingsRefs['Classification'],
              })}},model:{value:(_setup.exerciseSettingsRefs['Classification']),callback:function ($$v) {_vm.$set(_setup.exerciseSettingsRefs, 'Classification', $$v)},expression:"exerciseSettingsRefs['Classification']"}})],1),_c('div',{staticClass:"w-100 px-4"},[_c('p',{staticClass:"text-overline font-weight-bold ml-1 accent--text pa-1"},[_vm._v(" Short Classification ")]),_c('v-combobox',{attrs:{"outlined":"","items":_setup.classificationOptions.short,"dense":"","attach":"","item-color":"accent","clearable":"","persistent-hint":"","color":"accent","hint":"The Exercise classification in short, for example:\n                     'U//FOUO' or your own custom value","rules":[_setup.rules?.isRequired()]},on:{"change":function($event){return _setup.updateExSettingFields({
                'Short Classification': _setup.exerciseSettingsRefs['Short Classification'],
              })}},model:{value:(_setup.exerciseSettingsRefs['Short Classification']),callback:function ($$v) {_vm.$set(_setup.exerciseSettingsRefs, 'Short Classification', $$v)},expression:"exerciseSettingsRefs['Short Classification']"}})],1),_c('div',{staticClass:"w-100 px-4"},[_c('p',{staticClass:"text-overline font-weight-bold accent--text pa-1"},[_vm._v("Exercise Description")]),_c('v-textarea',{staticClass:"w-100",attrs:{"outlined":"","expandable":"","dense":"","rows":"5","clearable":"","color":"accent","rules":[_setup.rules?.isRequired()],"hint":"The aim of this Exercise is to... What is the narrative and the general storyline? What are the objectives?","persistent-hint":""},on:{"input":function($event){return _setup.updateExSettingFields({
                Description: _setup.exerciseSettingsRefs['Description'],
              })}},model:{value:(_setup.exerciseSettingsRefs['Description']),callback:function ($$v) {_vm.$set(_setup.exerciseSettingsRefs, 'Description', $$v)},expression:"exerciseSettingsRefs['Description']"}})],1)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }