// types
import {
  EaseElement,
  Link,
  ElementDataStringObjArray,
  ElementData,
} from '@/types/interfaces/EaseElement'

// Vue
import Vue from 'vue'

// mixins
import { submitActiveElement } from '@/mixins/api/activeElement'
import { deleteBuilderElement } from '@/mixins/api/dndEntityEditor'
import { setNestedKeyByString } from '@/mixins/general/helpers'

import { MutationTree } from 'vuex'
import { ElementStore } from '@/store/modules/elements/index'
import { DynamicField } from '@/types/interfaces/Template'

const elementMutations: MutationTree<ElementStore> = {
  /*
    ======================================================
                  DRAG AND DROP ENTITY EDITOR
    ======================================================
      @setDndEntityEditorElements: set the dnd elements
      @updateDndEntityEditorElements: add or update an existing D&D Entity Editor element
      @removeDndEntityEditorElement: removes a D&D Entity Editor element by its uuid
      @resetDndEntityEditor: resets the D&D Entity Editor
  */

  setDndEntityEditorElements(state, payload: EaseElement[]): void {
    state.dndEntityEditorElements = payload
  },

  updateDndEntityEditorElements(state, payload: EaseElement): void {
    // search for the index of the element using its uuid
    const elementIndex = state.dndEntityEditorElements.findIndex(
      (dndElement: EaseElement) => dndElement.metadata.uuid === payload.metadata.uuid
    )

    // if the search yeilds a result
    if (elementIndex !== -1) {
      // update existing element
      if (payload != state.dndEntityEditorElements[elementIndex])
        Vue.set(state.dndEntityEditorElements, elementIndex, payload)
    }
    // no result
    else {
      // add new element
      state.dndEntityEditorElements.push(payload)
    }
  },

  removeDndEntityEditorElementByUuid(state, elementUuid: string): void {
    const element = state.dndEntityEditorElements.find(
      (element: EaseElement) => element.metadata.uuid == elementUuid
    )
    if (element) {
      deleteBuilderElement(element)
    }
    state.dndEntityEditorElements = state.dndEntityEditorElements.filter((element: EaseElement) => {
      return element.metadata.uuid !== elementUuid
    })
  },

  resetDndEntityEditor(state): void {
    state.dndEntityEditorElements = []
  },

  /*
    ======================================================
                          D A T A
    ======================================================
      @removeElement: remove an element by uuid
  */

  setElementsUpdating(state, elementUuidsToUpdate: string[]): void {
    state.pendingElementUpdates = elementUuidsToUpdate
  },

  setElements(state, payload: EaseElement[]): void {
    state.elements = payload
  },

  removeElement(state, payload: string): void {
    state.elements = state.elements.filter(element => {
      return element && element.metadata && element.metadata.uuid !== payload
    })
  },

  setTrashedElementCount(state, payload: string): void {
    state.trashedElementCount = parseInt(payload)
  },

  /*
    ======================================================
                  A C T I V E   E L E M E N T
    ======================================================

    */

  addNewActiveElementField(state, payload: { value: DynamicField }): void {
    if (!state.activeElement) {
      throw new Error('Attempting to addNewActiveElementField while there is no activeElement')
    }
    state.activeElement.template.fields.push(payload.value)
  },

  addNewActiveElementFieldData(state, payload: { value: ElementData }): void {
    if (!state.activeElement) {
      throw new Error('Attempting to addNewActiveElementFieldData while there is no activeElement')
    }
    state.activeElement.data.push(payload.value)
  },

  updateActiveElementFieldValue(state, payload: { fieldIndex: number; value: DynamicField }): void {
    if (!state.activeElement) {
      throw new Error('Attempting to updateActiveElementFieldValue while there is no activeElement')
    }
    const updatedField = {
      ...state.activeElement.template.fields[payload.fieldIndex],
      ...payload.value,
    }
    Vue.set(state.activeElement.template.fields, payload.fieldIndex, updatedField)
  },

  updateActiveELementFieldData(state, payload: { fieldIndex: number; value: DynamicField }): void {
    if (!state.activeElement) {
      throw new Error('Attempting to updateActiveELementFieldData while there is no activeElement')
    }
    const updatedFieldData = {
      ...state.activeElement.data[payload.fieldIndex],
      ...payload.value,
    }

    Vue.set(state.activeElement.data, payload.fieldIndex, updatedFieldData)
  },

  shiftActiveElementField(state, payload: { fieldIndex: number; direction: number }) {
    if (!state.activeElement) {
      throw new Error('Attempting to shiftActiveElementField while there is no activeElement')
    }

    const targetFieldIndex = payload.fieldIndex + payload.direction
    const activeElementTemplateFields = state.activeElement.template.fields
    const activeElementData = state.activeElement.data

    if (targetFieldIndex >= 0 && targetFieldIndex < activeElementTemplateFields.length) {
      // Use a temporary variable to store the original field
      const tempTemplateFields = activeElementTemplateFields[targetFieldIndex]
      const tempactiveElementData = activeElementData[targetFieldIndex]

      // Use Vue.set to swap the fields in the array in template fields
      Vue.set(
        activeElementTemplateFields,
        targetFieldIndex,
        activeElementTemplateFields[payload.fieldIndex]
      )
      Vue.set(activeElementTemplateFields, payload.fieldIndex, tempTemplateFields)

      // Swap the fields in the activeElement data array
      Vue.set(activeElementData, targetFieldIndex, activeElementData[payload.fieldIndex])
      Vue.set(activeElementData, payload.fieldIndex, tempactiveElementData)
    }
  },

  deleteActiveElementField(state, payload: number): void {
    if (!state.activeElement) {
      throw new Error('Attempting to deleteActiveElementField while there is no activeElement')
    }
    state.activeElement.template.fields.splice(payload, 1)
  },

  deleteActiveElementFieldData(state, payload: number): void {
    if (!state.activeElement) {
      throw new Error('Attempting to deleteActiveElementFieldData while there is no activeElement')
    }
    state.activeElement.data.splice(payload, 1)
  },

  setActiveElement(state, element: EaseElement): void {
    state.activeElement = structuredClone(element)
  },

  setSelectedElement(state, element: EaseElement): void {
    state.selectedElement = structuredClone(element)
  },

  setActiveAttribute(state, payload: { name: string; comboUuid?: string }): void {
    state.activeAttribute = payload
  },

  submitActiveElement(): void {
    submitActiveElement()
  },

  updateActiveElementByPath(state, payload: { path: string; value: string }): void {
    if (!state.activeElement) {
      throw new Error('Attempting to updateActiveElementByPath while there is no activeElement')
    }
    state.activeElement = setNestedKeyByString(state.activeElement, payload.path, payload.value)
  },

  updateElementByPath(state, payload: { elementUuid: string; path: string; value: string }): void {
    const elementIndex: number = state.elements.findIndex(
      element => element.metadata.uuid === payload.elementUuid
    )
    if (elementIndex !== -1) {
      const updatedElement: EaseElement = setNestedKeyByString(
        state.elements[elementIndex],
        payload.path,
        payload.value
      )

      Vue.set(state.elements, elementIndex, updatedElement)
    }
  },

  updateActiveElementLink(state, link: Link): void {
    if (!state.activeElement) {
      throw new Error('Attempting to updateActiveElementLink while there is no activeElement')
    }
    Vue.set(state.activeElement, 'links', [...state.activeElement.links, link])
    // find the active element in elements and update its links to match
    for (let i = 0; i < state.elements.length; i++) {
      const element = state.elements[i]
      if (element.metadata.uuid === state.activeElement.metadata.uuid) {
        Vue.set(state.elements[i], 'links', state.activeElement.links)
      }
    }
    for (let i = 0; i < state.dndEntityEditorElements.length; i++) {
      const element = state.dndEntityEditorElements[i]
      if (element.metadata.uuid === state.activeElement.metadata.uuid) {
        Vue.set(state.dndEntityEditorElements[i], 'links', state.activeElement.links)
      }
    }
  },

  removeActiveElementLink(state, linkID: string): void {
    if (!state.activeElement) {
      throw new Error('Attempting to removeActiveElementLink while there is no activeElement')
    }
    const updatedLinks = state.activeElement.links.filter((link: Link) => link.id !== linkID)
    Vue.set(state.activeElement, 'links', updatedLinks)
    // find the active element in elements and update its links to match
    for (let i = 0; i < state.elements.length; i++) {
      const element = state.elements[i]
      if (element.metadata.uuid === state.activeElement.metadata.uuid) {
        Vue.set(state.elements[i], 'links', updatedLinks)
      }
    }
    for (let i = 0; i < state.dndEntityEditorElements.length; i++) {
      const element = state.dndEntityEditorElements[i]
      if (element.metadata.uuid === state.activeElement.metadata.uuid) {
        Vue.set(state.dndEntityEditorElements[i], 'links', updatedLinks)
      }
    }
  },

  resetActiveElementFieldLinks(state, payload: { fieldName: string; indexUuid: string }): void {
    if (!state.activeElement) {
      return
    }

    Vue.set(
      state.activeElement,
      'links',
      state.activeElement.links.filter((link: Link) => {
        for (const data of state.activeElement?.data ?? []) {
          // if the attribute is an array
          if (Array.isArray(data.content)) {
            // iterate through the array and find the string data
            for (const content of data.content) {
              // if the string data uuid matches the attribute uuid
              if (content.uuid === link.self.indexUUID) {
                return true
              }
            }
          } else if (link.self.attributeUUID === data.uuid) {
            return true
          }
        }
        return false
      })
    )
    if (payload.indexUuid) {
      Vue.set(
        state.activeElement,
        'links',
        state.activeElement.links.filter((link: Link) => link.self.indexUUID != payload.indexUuid)
      )
    } else {
      Vue.set(
        state.activeElement,
        'links',
        state.activeElement.links.filter(
          (link: Link) => link.self.attributeName != payload.fieldName
        )
      )
    }
  },

  // Set the activeDocumentHTML to the current HTML
  updateActiveDocumentHTML(state, payload: string): void {
    if (!state.activeElement) {
      throw new Error('Attempting to updateActiveDocumentHTML while there is no activeElement')
    }
    state.activeElement.data[0] = {
      ...state.activeElement.data[0],
      content: payload,
    }
  },

  // update and filter the form field
  updateActiveElementField(
    state,
    payload: { content: string | ElementDataStringObjArray[]; name: string }
  ): void {
    if (!state.activeElement) return

    Vue.set(
      state.activeElement,
      'data',
      state.activeElement.data.map(field => {
        if (field.name === payload.name) field.content = payload.content

        return field
      })
    )
  },

  setActiveElementInjectStyle(state, payload: { [key: string]: string }): void {
    if (!state.activeElement) {
      throw new Error('Attempting to setActiveElementInjectStyle while there is no activeElement')
    }
    Vue.set(state.activeElement.data[0], 'style', payload)
  },

  updateActiveElementValidity(state): void {
    if (!state.activeElement) {
      throw new Error('Attempting to updateActiveElementValidity while there is no activeElement')
    }

    // if the element is form based
    if (state.activeElement.template.metadata.type.supertype !== 'Document') {
      state.activeElement.metadata.valid = state.activeElement.data.every(field => field.valid)
    } else {
      // if the element is document based, just set it to true for now
      state.activeElement.metadata.valid = true
    }
  },

  updateActiveElementFieldValidity(state, payload: { name: string; valid: boolean }): void {
    if (!state.activeElement) return

    Vue.set(
      state.activeElement,
      'data',
      state.activeElement.data.map(field => {
        if (field.name === payload.name) field.valid = payload.valid

        return field
      })
    )
  },

  // updates the classification
  updateActiveElementClassification(
    state,
    payload: { classification: string; name: string }
  ): void {
    if (!state.activeElement) {
      throw new Error(
        'Attempting to updateActiveElementClassification while there is no activeElement'
      )
    }

    state.activeElement.data = state.activeElement.data.map(field => {
      if (field.name === payload.name) field.classification = payload.classification
      return field
    })
  },

  clearActiveElement(state): void {
    state.activeElement = null
  },

  /*
    ======================================================
                      T R A I N I N G
    ======================================================
      @updateElementObj: Update a specific Objectives rating
  */
}

export default elementMutations
