// types
import { ApiTemplates, DynamicField, Template, TemplateMetadata } from '@/types/interfaces/Template'
import { Type } from '@/types/interfaces/General'

import { MutationTree } from 'vuex'
import { TemplateStore } from '@/store/modules/templates/index'
import Vue from 'vue'

const templateMutations: MutationTree<TemplateStore> = {
  /*
  ======================================================
                  T E M P L A T E S
  ======================================================
  */
  // update the template
  updateTemplate(state, payload: { action: string; template: Template }): void {
    if (payload.action === 'leave' || payload.action === 'decline') {
      // remove template from state
      state.templates['shared'].forEach((template: Template, index: number) => {
        if (template.metadata.uuid === payload.template.metadata.uuid) {
          state.templates['shared'].splice(index, 1)
        }
      })
    } else if (payload.action === 'accept') {
      // update the template in state
      state.templates['shared'].forEach((template: Template, index: number) => {
        if (template.metadata.uuid === payload.template.metadata.uuid) {
          state.templates['shared'][index] = payload.template
        }
      })
    } else if (payload.action === 'edit') {
      //loop through the templates in owned, shared, built-in and update the template when its found
      Object.keys(state.templates).forEach((key: string) => {
        state.templates[key].forEach((template: Template, index: number) => {
          if (template.metadata.uuid === payload.template.metadata.uuid) {
            Vue.set(state.templates[key], index, payload.template)
          }
        })
      })
    }
  },

  deleteTemplate(state, payload: string): void {
    // remove the template from the templates object
    state.templates['owned'].forEach((template: Template, index: number) => {
      if (template.metadata.uuid === payload) {
        state.templates['owned'].splice(index, 1)
      }
    })
    state.templates['builtin'].forEach((template: Template, index: number) => {
      if (template.metadata.uuid === payload) {
        state.templates['builtin'].splice(index, 1)
      }
    })
  },
  // update active template field for the entity builder
  updateActiveTemplateField(
    state,
    payload: { fieldIndex: number; key: string; value: string | string[] | boolean }
  ): void {
    if (!state.activeTemplate?.fields) return
    state.activeTemplate.fields[payload.fieldIndex][payload.key] = payload.value
  },

  // update the field type field and delete the additional keys that aren't required when field type changed
  updateActiveTemplateFieldType(
    state,
    payload: { fieldType: DynamicField['fieldType']; fieldIndex: number }
  ): void {
    if (!state.activeTemplate?.fields && !state.activeTemplate) return
    Object.keys(state.activeTemplate.fields[payload.fieldIndex]).forEach((key: string) => {
      if (!['name', 'fieldType', 'tags', 'hint', 'placeholder', 'rules'].includes(key)) {
        delete state?.activeTemplate?.fields[payload.fieldIndex][key]
      }
    })
    state.activeTemplate.fields[payload.fieldIndex].fieldType = payload.fieldType
  },

  // delete a field from the entity builder template
  deleteActiveTemplateField(state, payload: number): void {
    // remove the field from the template
    state.activeTemplate?.fields.splice(payload, 1)
  },

  // shift a field in the entity builder template
  shiftActiveTemplateField(state, payload: { fieldIndex: number; direction: number }) {
    if (!state.activeTemplate?.fields) return
    const targetFieldIndex: number = payload.fieldIndex + payload.direction
    const targetIndexField = state?.activeTemplate.fields[targetFieldIndex]
    state.activeTemplate.fields[targetFieldIndex] = state.activeTemplate.fields[payload.fieldIndex]
    state.activeTemplate.fields[payload.fieldIndex] = targetIndexField
  },

  // add a field to the entity builder template
  addNewActiveTemplateField(state, payload: DynamicField): void {
    if (!state.activeTemplate?.fields) return
    state.activeTemplate.fields.push(payload)
  },

  setTemplates(state, payload: ApiTemplates): void {
    state.templates = Object.assign({}, payload)
  },

  // sets the active inject content when creating a new template
  setActiveInjectTemplate(state, payload: string): void {
    if (!state.activeTemplate?.html) return
    state.activeTemplate.html.content = payload
  },

  setActiveTemplate(state, payload: Template): void {
    state.activeTemplate = payload
  },

  setActiveTemplateFields(state, payload: DynamicField[]): void {
    if (!state.activeTemplate) return
    state.activeTemplate.fields = [...payload]
  },

  setActiveTemplateField(state, payload: TemplateMetadata): void {
    if (!state.activeTemplate) return
    state.activeTemplate.metadata = {
      ...state.activeTemplate.metadata,
      ...payload,
    }
  },

  setActiveTemplateDescriptionField(state, payload: { short: string; long: string }): void {
    if (!state.activeTemplate?.metadata?.description) return
    state.activeTemplate.metadata.description = {
      ...state.activeTemplate.metadata.description,
      ...payload,
    }
  },

  setActiveTemplateSubtypeField(state, payload: Type): void {
    if (!state.activeTemplate?.metadata?.type) return
    state.activeTemplate.metadata.type = {
      ...state.activeTemplate.metadata.type,
      ...payload,
    }
  },

  setActiveTemplateStylesField(state, payload: TemplateMetadata): void {
    if (!state.activeTemplate) return
    if (state.activeTemplate.metadata.style) {
      state.activeTemplate.metadata.style = {
        ...state.activeTemplate.metadata.style,
        ...payload,
      }
    }
  },

  // Inject.vue, handle how style is applied when changing it via the <Page Layout> button
  setActiveTemplateInjectStyle(state, payload: { [key: string]: string }): void {
    if (!state.activeTemplate) return
    if (state.activeTemplate.html) {
      Vue.set(state.activeTemplate.html, 'style', payload)
    }
  },
}

export default templateMutations
