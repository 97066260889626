
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// components
import ElementHistoryCard from '@/components/Common/Elements/ElementHistory/ElementHistoryCard.vue'

import CardTitleBar from '../General/CardTitleBar.vue'

// types
import { ElementHistory } from '@/types/interfaces/EaseElement'

// mixins
import { reformatDate } from '@/mixins/general/helpers'
import UserElementHistory from './ElementHistory/UserElementHistory.vue'

@Component({ components: { ElementHistoryCard, CardTitleBar, UserElementHistory } })
export default class ElementHistoryDetails extends Vue {
  @Prop() elementHistory!: ElementHistory[]

  // calendar date (import reformatDate() from helpers.ts)
  reformatDate = reformatDate

  // All the history
  allHistory: ElementHistory[] | null = null

  // The refined history to be displayed to modal
  refinedHistory: ElementHistory[] | null = null

  // The specfic change that is selected
  selectedHistory: ElementHistory | null = null

  //Toggles
  toggleSearchBanner = false
  toggleSearchUser = false

  //filters
  userFilter = []
  dateFilter = null

  userFilters = {
    user: [] as string[],
    dateFilter: null as string | null,
  }

  beforeMount(): void {
    this.refinedHistory = this.elementHistory
      .map((history: ElementHistory) => {
        return { ...history, user: this.$store.getters.getExUserByUuid(history.user).username }
      })
      .reverse()
    this.allHistory = this.refinedHistory
  }

  handleSeeChanges(history): void {
    this.selectedHistory = null
    this.selectedHistory = history
  }

  handleCloseChanges(): void {
    this.selectedHistory = null
  }
  handleFilters(): void {
    let newHistory = this.allHistory

    // Filters the selected users
    if (this.userFilters.user.length) {
      newHistory =
        this.refinedHistory?.filter(history => this.userFilters.user[0].includes(history.user)) ??
        null
    }

    //Filters by date
    if (this.userFilters.dateFilter) {
      const userSelectedDate = this.userFilters.dateFilter
      newHistory =
        this.refinedHistory?.filter(
          history => userSelectedDate === history.timestamp.split('T')[0]
        ) ?? null
    }

    // updates the refnided history
    this.allHistory = newHistory
  }

  @Watch('userFilters', { deep: true })
  onUserFilterChange() {
    if (this.userFilters.user.length === 0 && !this.userFilters.dateFilter) {
      this.allHistory = this.refinedHistory
    }
  }
}
