
import { Component, Prop, Vue } from 'vue-property-decorator'
import CardTitleBar from '@/components/Common/General/CardTitleBar.vue'
import GeoCoordsPicker from '@/components/Common/Geo/GeoCoordsPicker.vue'
import MiscMenuOptions from '@/components/Common/General/MiscMenuOptions.vue'
import ClosePopout from '@/components/Common/Popouts/ClosePopout.vue'
import EzDocGuide from '@/modules/common/components/DocGuide/EzDocGuide.vue'

@Component({
  components: { ClosePopout, CardTitleBar, GeoCoordsPicker, MiscMenuOptions, EzDocGuide },
})
export default class BaseModal extends Vue {
  @Prop() closePopoutEnabled!: boolean
  @Prop({ default: false }) popout!: boolean
  closePopoutOpen = false

  dirty = false
  setDirty(dirty?: boolean) {
    this.dirty = dirty ?? true
  }

  get activeModal(): string {
    return this.$store.getters.getActiveModal
  }

  close(): void {
    if (this.closePopoutEnabled && this.dirty) {
      this.closePopoutOpen = true
      return
    }
    this.closeModal()
  }

  closeModal(): void {
    this.$store.dispatch('setCloseModal').then(() => {
      window.scrollTo(0, this.$store.state.misc.misc.currentScrollY)
    })
  }

  stayOnModal(): void {
    this.closePopoutOpen = false
  }
}
