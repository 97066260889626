import { computed, onUnmounted, Ref, ref, shallowRef } from 'vue'
import { useTrainingStore } from '../../store/training-store'
import {
  TrainingAudience,
  TrainingGoal,
  TrainingObjective,
} from '@/modules/training/model/training-model'

type MaybeString<T> = T | string

type TrainingAssignment = [
  MaybeString<TrainingAudience>?,
  MaybeString<TrainingGoal>?,
  MaybeString<TrainingObjective>?
]

type ValidTrainingAssignment = [TrainingAudience, TrainingGoal, MaybeString<TrainingObjective>]

// we use a shallowRef here because it won't unwrap nested refs
const trainingAssignments = shallowRef<
  {
    elementUuid: string
    selection: Ref<TrainingAssignment>
  }[]
>([])

/**
 * This is a provider for the training assignment. The returned value can be bound to a combobox,
 * and it will be provided via the useTrainingAssignments composable.
 */
export function useTrainingAssignmentField(
  elementUuid: string,
  initialValue?: [TrainingAudience, TrainingGoal, TrainingObjective]
) {
  // The selection: [audience, goal, objective]. Since this is bound to a combobox, any of these can be a string
  const selection = ref<TrainingAssignment>(initialValue ?? [])

  const assignment = { elementUuid, selection }

  // since we're using a shallowRef, we need to replace the value instead of mutating it to trigger changes
  trainingAssignments.value = [...trainingAssignments.value, assignment]

  onUnmounted(() => {
    trainingAssignments.value = trainingAssignments.value.filter(a => a !== assignment)
  })

  return {
    selection,
  }
}

export function useTrainingAssignmentGroup(
  elementUuid: string,
  selection: Ref<TrainingAssignment>
) {
  const assignedObjectives = computed(() =>
    trainingAssignments.value
      .filter(t => t.elementUuid === elementUuid)
      .map(t => t.selection.value[2])
      .filter(o => o !== selection.value[2])
  )

  return {
    assignedObjectives,
  }
}

/**
 * This is a consumer for the training assignment. It can be used to get the training assignment,
 * it's validity, and to submit the training assignment.
 * @param elementUuid
 */
export function useTrainingAssignmentForm(elementUuid: string) {
  const store = useTrainingStore()

  const selections = computed(() => {
    return trainingAssignments.value
      .filter(t => t.elementUuid === elementUuid)
      .map(t => t.selection)
  })

  /**
   * The validity of the training assignments.
   */
  const valid = computed(
    () => !selections.value.map(s => isValidTrainingAssignment(s.value)).includes(false)
  )

  /**
   * Submit the training assignments if changed. This will create new objectives if needed.
   * This will also remove any assignments that are no longer selected.
   */
  const submit = async () => {
    // we shouldn't be trying to submit an invalid assignment
    if (!valid.value) {
      throw new Error('Cannot submit invalid training assignment')
    }

    for (const selection of selections.value) {
      // skip for empty selection
      if (!selection.value || selection.value.length === 0) {
        continue
      }

      const [audience, goal, objective] = selection.value as ValidTrainingAssignment

      // If the objective is a string, it is a new objective
      // submit it and replace the selection with the objective object
      if (typeof objective === 'string') {
        const newObjective = await store.addNewObjective(goal.uuid, objective)

        // we may as well update the selection with the actual object
        selection.value.splice(2, 1, newObjective)

        await store.assignElementTraining(elementUuid, {
          audienceUuid: audience.uuid,
          goalUuid: goal.uuid,
          objectiveUuid: newObjective?.uuid,
        })
      } else {
        const existingEvaluation = store.findEvaluation({
          audienceUuid: audience.uuid,
          goalUuid: goal.uuid,
          objectiveUuid: objective.uuid,
        })

        // only update the assignment if it doesn't already exist
        if (!existingEvaluation.sourceUuids.includes(elementUuid)) {
          await store.assignElementTraining(elementUuid, {
            audienceUuid: audience.uuid,
            goalUuid: goal.uuid,
            objectiveUuid: objective.uuid,
          })
        }
      }
    }

    // remove sourceUuids from evaluations that are no longer assigned
    const evaluations = store.evaluations.filter(e => e.sourceUuids.includes(elementUuid))
    for (const evaluation of evaluations) {
      const assigned = selections.value.some(s => {
        const [audience, goal, objective] = s.value as [
          TrainingAudience,
          TrainingGoal,
          TrainingObjective
        ]
        return (
          evaluation.audienceUuid === audience.uuid &&
          evaluation.goalUuid === goal.uuid &&
          evaluation.objectiveUuid === objective.uuid
        )
      })
      if (!assigned) {
        await store.unassignElementTraining(elementUuid, {
          audienceUuid: evaluation.audienceUuid,
          goalUuid: evaluation.goalUuid,
          objectiveUuid: evaluation.objectiveUuid,
        })
      }
    }
  }

  return {
    valid,
    submit,
  }
}

export function isValidTrainingAssignment(
  assignment: TrainingAssignment
): assignment is ValidTrainingAssignment {
  const [audience, goal, objective] = assignment

  // an empty selection is always valid
  if (!assignment || assignment.length === 0) {
    return true
  }

  // if one of audience, goal, or objective is missing, it is invalid
  if (!(audience && goal && objective)) {
    return false
  }

  // if audience or goal is a string, it is invalid as we don't create
  // those on the fly
  return !(typeof audience === 'string' || typeof goal === 'string')
}

/**
 * Used by an element with training assignments
 *
 * @param {string} elementUuid
 */
export function useTrainingAssignments(elementUuid: string) {
  const store = useTrainingStore()

  /**
   * Unassign this element. It will find all evaluations
   * linked to the element (via the elementUuid), and unassign the training for these evaluations.
   */
  async function unassignElement() {
    const evaluations = store.evaluations.filter(e => e.sourceUuids.includes(elementUuid))
    for (const evaluation of evaluations) {
      await store.unassignElementTraining(elementUuid, {
        audienceUuid: evaluation.audienceUuid,
        goalUuid: evaluation.goalUuid,
        objectiveUuid: evaluation.objectiveUuid,
      })
    }
  }

  return {
    unassignElement,
  }
}
