import { Module } from 'vuex'
import { EaseStore } from '@/store'
import exerciseState from '@/store/modules/exercise/exerciseState'
import exerciseGetters from '@/store/modules/exercise/exerciseGetters'
import exerciseMutations from '@/store/modules/exercise/exerciseMutations'
import exerciseActions from '@/store/modules/exercise/exerciseActions'
import { Exercise } from '@/types/interfaces/Exercise'

export interface ExStore {
  ex: Exercise
  // is the exercise loaded
  exLoaded: boolean
  // an update counter to track/state changes
  exUpdates: number
}

export const exerciseModule: Module<ExStore, EaseStore> = {
  state: exerciseState,
  getters: exerciseGetters,
  mutations: exerciseMutations,
  actions: exerciseActions,
}
