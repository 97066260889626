import { Module } from 'vuex'
import { EaseStore } from '@/store'
import portalState from '@/store/modules/portal/portalState'
import portalGetters from '@/store/modules/portal/portalGetters'
import portalMutations from '@/store/modules/portal/portalMutations'
import portalActions from '@/store/modules/portal/portalActions'

import { PortalStore } from '@/types/interfaces/Portal'

export const portalModule: Module<PortalStore, EaseStore> = {
  state: portalState,
  getters: portalGetters,
  mutations: portalMutations,
  actions: portalActions,
}
