import store from '@/store'
// import the default state for each module
import { defaultElementState } from '@/store/modules/elements/elementState'
import { defaultMiscState } from '@/store/modules/misc/miscState'
import { defaultExerciseState } from '@/store/modules/exercise/exerciseState'

export const clearEaseAppStore = (): void => {
  // @Jason Rooney!
  // TODO: short term solution to clearing state/store
  // issue: when leaving to go back to the portal the Pinia stores are not cleared
  // this causes issues when returning to the exercise
  // Setting the pinia stores to their default does not clear the initial state of the store
  // and causes issues when returning to the exercise

  Object.assign(store.state.exercise, defaultExerciseState)
  Object.assign(store.state.elements, defaultElementState)
  Object.assign(store.state.misc, defaultMiscState)
  Object.assign(store.state.user, { ...store.state.user, exUsers: [] })
  console.info('Cleared EASE Store for exercise, elements, misc and user...')
}
