import { Exercise } from '@/types/interfaces/Exercise'
import Store from '@/store'
import { easeApi } from '@/api/ease.api'

export const filterExercise = (filter: string, exercise: Exercise): boolean => {
  const params = filter.toLowerCase().split(' ')
  const exerciseString = JSON.stringify(exercise).toLowerCase()
  return params.every((p: string) => exerciseString.includes(p))
}

export const adminGetExerciseNameByUuid = (exerciseUUID: string): Promise<string> => {
  return easeApi.get(`admin/exercise/name/${exerciseUUID}`).then((res: { data: string }) => {
    return res.data
  })
}

export const adminGetExercises = (filter: string | null = null): Promise<Exercise[]> => {
  return easeApi.get('admin/exercises').then((res: { data: Exercise[] }) => {
    return filter
      ? res.data.filter((exercise: Exercise) => filterExercise(filter, exercise))
      : res.data
  })
}

export const adminUpdateExercise = (exerciseData: Exercise): Promise<void> => {
  return easeApi.put('admin/exercise/update', exerciseData).then(() => {
    Store.dispatch('setAlert', {
      text: 'Updated Exercise!',
      type: 'success',
    })
  })
}

export const adminDeleteExercise = (exerciseUUID: string): Promise<void> => {
  return easeApi.delete(`admin/exercise/delete/${exerciseUUID}`).then(() => {
    Store.dispatch('setAlert', {
      text: 'Successfully Deleted Exercise!',
      type: 'success',
    })
  })
}
