import { EaseStore } from '@/store'
import { GetterTree } from 'vuex'
import { MiscStore } from '@/store/modules/misc/index'

const miscGetters: GetterTree<MiscStore, EaseStore> = {
  /*
      ======================================================
                          G E O  P I C K E R
      ======================================================
        @getGeoPickerStatus: get the geo picker state
  */

  getGeoPickerStatus: (state): boolean => state.misc.geoPicker,

  /*
      ======================================================
                 E N T I T Y  F I E L D  M A N A G  E R
      ======================================================
        @getEntityFieldManagerStatus: get the entity field manager state
  */

  getEntityFieldManagerStatus: (state): boolean => state.misc.entityFieldManager,

  /*
      ======================================================
                  M O D A L S
      ======================================================
      @getModal: get the modal
      @getShowModal: get the current modals visibility
  */

  // gets the current modal : string
  getActiveModal: (state): string | null | undefined => state.modal.activeModal,
  // gets the current modals visibility : boolean
  getShowModal: (state): boolean => state.modal.modalVisible,

  getActiveTab: (state): string => state.activeTab,

  /*
    ======================================================
                          M I S C
    ======================================================
  */

  // gets the current Y axis of page
  getCurrentScrollY: (state): number => state.misc.currentScrollY,

  /*
  ======================================================
                 W I D T H
  ======================================================
  */
  getWindowWidth: (state): number => state.misc.windowWidth,
}

export default miscGetters
