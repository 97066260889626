// types
import { PortalStore } from '@/types/interfaces/Portal'

const store: PortalStore = {
  ActiveEx: null,
  ActiveTemplate: null,
  exercises: {
    owned: [],
    shared: [],
  },
  templates: {
    owned: [],
    shared: [],
    builtin: [],
  },
  alert: { text: null, color: null, type: null, counter: 0 },
  portalLoaded: false,
  misc: {
    config: null,
  },
}

export default store
