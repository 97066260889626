import { ActionTree } from 'vuex'
import { EaseStore } from '@/store'
import { TemplateStore } from '@/store/modules/templates/index'
import { ApiTemplates, DynamicField, Template, TemplateMetadata } from '@/types/interfaces/Template'

const templateActions: ActionTree<TemplateStore, EaseStore> = {
  /*
      ======================================================
                      T E M P L A T E S
      ======================================================
    */
  // update the template
  updateTemplate(context, payload: { action: string; template: Template }): void {
    context.commit('updateTemplate', payload)
  },

  deleteTemplate(context, payload: string): void {
    context.commit('deleteTemplate', payload)
  },

  setTemplates(context, payload: ApiTemplates): void {
    context.commit('setTemplates', payload)
  },

  // sets the active Smart Document Template when creating a new template
  setActiveInjectTemplate(context, payload: string): void {
    context.commit('setActiveInjectTemplate', payload)
  },

  setActiveTemplateFields(context, payload: DynamicField[]): void {
    context.commit('setActiveTemplateFields', payload)
  },

  setActiveTemplateField(context, payload: Partial<TemplateMetadata>): void {
    context.commit('setActiveTemplateField', payload)
  },

  setActiveTemplateInjectStyle(context, payload: { [key: string]: string }): void {
    context.commit('setActiveTemplateInjectStyle', payload)
  },

  setActiveTemplateDescriptionField(
    context,
    payload: Partial<TemplateMetadata['description']>
  ): void {
    context.commit('setActiveTemplateDescriptionField', payload)
  },

  setActiveTemplateSubtypeField(context, payload: Partial<TemplateMetadata['type']>): void {
    context.commit('setActiveTemplateSubtypeField', payload)
  },

  setActiveTemplateStylesField(context, payload: TemplateMetadata): void {
    context.commit('setActiveTemplateStylesField', payload)
  },

  updateActiveTemplateField(
    context,
    payload: { fieldIndex: number; key: string; value: string | string[] | boolean }
  ): void {
    context.commit('updateActiveTemplateField', payload)
  },

  updateActiveTemplateFieldType(context, payload: { fieldType: string; fieldIndex: number }): void {
    context.commit('updateActiveTemplateFieldType', payload)
  },

  deleteActiveTemplateField(context, payload: number): void {
    context.commit('deleteActiveTemplateField', payload)
  },

  shiftActiveTemplateField(context, payload: { fieldIndex: number; direction: number }) {
    context.commit('shiftActiveTemplateField', payload)
  },

  addNewActiveTemplateField(context, payload: DynamicField): void {
    context.commit('addNewActiveTemplateField', payload)
  },

  /*
      ======================================================
                  T E M P L A T E   E N T R Y
      ======================================================
    */

  setActiveTemplate(context, payload: Template): void {
    context.commit('setActiveTemplate', payload)
  },
}

export default templateActions
