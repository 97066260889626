// Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#222222',
        secondary: '#1c1a1a',
        tertiary: '#2a2a2a',
        accent: '#fca311',
        background: '#232323',
        background_light: '#383838',
        background_dark: '#242424',
        modal_background: '#222221',
        primary_white: '#ffffff',
        secondary_white: '#fff',
      },
    },
    options: {
      customProperties: true,
      // themeCache: {
      //   get: (key) => localStorage.getItem(key as any),
      //   set: (key, value) => localStorage.setItem(key as any, value),
      // },
    },
  },
})
