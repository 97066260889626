import Store from '@/store'

import { DateTime } from 'luxon'

// types
import { DynamicField, DynamicFieldRule } from '@/types/interfaces/Template'
import { Time } from '@/types/interfaces/Time'

// mixins
import { stripEaseLinkTags } from '@/mixins/general/linking'

// data
import { getRuleFnc } from '@/data/rules/rules'

// router
import router from '@/router'
import { Route } from 'vue-router'

// npm packages
import { v4 as uuidv4 } from 'uuid'
import { useEventBus } from '@vueuse/core'

/**
 * Take the exercise classification from the store and parse it into
 * the short classification
 *
 * @return {string} the short classification as a string
 */
export const parseShortClassification = (): string => {
  // to get the short classification for paragraphs
  // and reports we need the classification
  const classification = Store.getters.getEx.metadata.Classification
  // then split it on spaces or "//"
  let shortClassification = classification
    .split(/[\s//]+/)
    // then for each part, return the first letter or "X" if its "Exercise"
    .map(part => {
      if (part.toLowerCase() === 'exercise') {
        return 'X'
      } else {
        return part.substring(0, 1).toUpperCase()
      }
    })
    .join('')
  // and add the double slash "//"
  shortClassification = `${shortClassification.substring(0, 1)}//${shortClassification.substring(
    1
  )}`

  // return it
  return shortClassification
}

/**
 * Generate a universally unique id
 *
 * @return {string} UUID
 */
export const genUUID = (): string => uuidv4()

/**
 * Get today's date as an ISO string
 *
 * @return {string} ISO string of today's date
 */
export const getToday = (): string => new Date(Date.now()).toISOString()

export const isRequired = (field: DynamicField): boolean => {
  // if the field has a rules array
  if (field.rules) {
    return field.rules.find(rule => rule.name === 'required') !== undefined
  } else {
    return false
  }
}

export const setFieldsValidity = (): void => {
  // iterate through all the fields in the template
  Store.getters.getActiveElement.template.fields.forEach((field: DynamicField) => {
    // default to valid
    let validField = true

    // field has rules
    if (field.rules) {
      field.rules.forEach((rule: DynamicFieldRule) => {
        // get the content of the field
        let fieldContent: string | (string | number)[] | number | Blob | Time =
          Store.getters.getActiveElementDataByName(field.name).content || ''

        // if it's a custom EaseTextLinking field then strip the tags
        if (field.fieldType === 'text' && typeof fieldContent === 'string') {
          fieldContent = stripEaseLinkTags(fieldContent)
        }

        // get the function for the rule
        const ruleFnc = getRuleFnc(rule)

        // run the rule
        const result = ruleFnc(fieldContent)
        // if the result is a string then it is an error message
        if (typeof result === 'string') {
          validField = false
        }
      })
    }

    // set the field validity
    Store.commit('updateActiveElementFieldValidity', {
      name: field.name,
      valid: validField,
    })
  })

  // update the element validity
  Store.dispatch('updateActiveElementValidity')
}

/**
 * Get the number of times a substring occurs in a larger amount
 * of text
 *
 * @param {string} substring
 * @param {string} fullText
 * @return {number} The number of occurances
 */
export const getNumSubstringOccurrences = (substring: string, fullText: string): number => {
  if (substring.length <= 0) {
    return fullText.length
  }
  substring = substring.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  return (fullText.match(new RegExp(substring, 'gi')) ?? []).length
}

/**
 * Inserts a substring at a given index of another string
 *
 * @param {string} mainString
 * @param {string} substring
 * @param {number} index
 * @return {string} The mainString with inserted substring
 */
export const insertSubstringAtIndex = (
  mainString: string,
  substring: string,
  index: number
): string => {
  const before = mainString?.slice(0, index) || ''
  const after = mainString?.slice(index) || ''
  return before + substring + after
}

/**
 * Redirect the user to EASE-Login
 */
export const redirectToLogin = (): Promise<Route> => router.push('/login')

/**
 * Sets a value of nested key string descriptor inside a Object.
 * It DOES NOT change the passed object, remember to re-assign
 * @param {object} obj The object to set the value on.
 * @param {string} path The property to set.
 * @param {any} value The value to set.
 * @return {Object} The updated object.
 */
export const setNestedKeyByString = (obj: object, path: string, value: unknown) => {
  const [head, ...rest] = path.split('.')

  return {
    ...obj,
    [head]: rest.length ? setNestedKeyByString(obj[head], rest.join('.'), value) : value,
  }
}

/**
 * Get a color based on a rating scale for Training Objectives
 * @param {number} rating The rating to get the color for
 * @return {string} The color to use
 */
export const getTrgObjectiveColorByRating = (rating: number): string => {
  if (rating === 5) {
    return 'success'
  } else if (rating >= 4 && rating < 5) {
    return 'light-green'
  } else if (rating >= 3 && rating < 4) {
    return 'yellow darken-2'
  } else if (rating >= 2 && rating < 3) {
    return 'orange darken-1'
  } else if (rating >= 1 && rating < 2) {
    return 'error'
  }
  return ''
}

/**
 * Converts any date input into a formatted date string in the "YYYY MMM DD" format.
 *
 * @param {string | Date} dateInput - The date input to convert, accepted as a string or Date object.
 * @param {ReformatDateConfig} [config] - Configuration object for the formatting.
 * @param {string} [config.calTypeStr] - Optional parameter that informs the calendar type
 * @param {boolean} [config.displayTime=true] - Optional parameter that indicates whether to display time. Default is true.
 * @returns {string} - Formatted date string in "YYYY MMM DD" format, with time included based on `displayTime` parameter.
 */
interface ReformatDateConfig {
  calTypeStr?: string
  displayTime?: boolean
}
export const reformatDate = (
  dateInput: string | Date,
  { calTypeStr, displayTime = true }: ReformatDateConfig = {}
): string => {
  if (!dateInput) return ''

  let date: DateTime

  if (dateInput instanceof Date) {
    date = DateTime.fromJSDate(dateInput, { zone: 'local' })
  } else {
    const hasTime = dateInput.includes('T')
    date = DateTime.fromISO(dateInput, { zone: hasTime ? 'utc' : 'local' })
  }

  if (!date.isValid) {
    console.error('Invalid date provided to reformatDate:', dateInput)
    return ''
  }

  let formatStr: string

  switch (calTypeStr) {
    case 'diff-month-week': // zero-padded day, abbreviated month string
      formatStr = 'dd LLL'
      break
    case 'yyyy-mmm-dd': // year, short month string, zero-padded day
      formatStr = 'yyyy-MMM-dd'
      break
    case 'month': // abbreviated month string, abbreviated year
      formatStr = 'LLL yy'
      break
    default: // zero-padded day, abbreviated month string, abbreviated year
      formatStr = displayTime ? 'dd LLL yy, T' : 'dd LLL yy'
      break
  }

  return date.toFormat(formatStr)
}

/**
 * Format the file size for data
 * @param bytes: number
 * @param decimalPoint: number
 * @returns number
 */
export const formatFileSize = (bytes: number, decimalPoint: number): string => {
  if (bytes == 0) return '0 Bytes'
  const k = 1000,
    dm = decimalPoint || 0,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i]
}

/**
 * description: Checks if a date is in a given range and returns a boolean.
 *   *
 * @param date: the date to check
 * @param options.startDate: The start date. If not provided, any date before the end date will return true.
 * @param options.endDate: The end date. If not provided, any date after the start date will return true.
 * @param options.endDateInclusive Whether to check if the date is inclusive of the end date.
 *  This will coerce the end date time to 23:59:59.999 to make it inclusive. Defaults to true.
 * @returns boolean
 */
export const isDateInRange = (
  date?: string | Date,
  options?: {
    startDate?: string | Date
    endDate?: string | Date
    endDateInclusive?: boolean
  }
) => {
  // set defaults
  if (options && options?.endDateInclusive === undefined) {
    options.endDateInclusive = true
  }

  // normalize the dates
  date = typeof date === 'string' ? new Date(date) : date

  const startDate =
    typeof options?.startDate === 'string' ? new Date(options?.startDate) : options?.startDate

  const endDate =
    typeof options?.endDate === 'string' ? new Date(options?.endDate) : options?.endDate

  if (options?.endDateInclusive) {
    endDate?.setUTCHours(23, 59, 59, 999)
  }

  // no date provided
  if (!date) {
    return false
  }

  // no start/end provided...date must be in range
  if (!startDate && !endDate) {
    return true
  }

  // both dates provided, check full range
  else if (startDate && endDate) {
    return date >= startDate && date <= endDate
  }

  // only end date provided
  else if (endDate && !startDate) {
    return date <= endDate
  }

  // only start date provided
  else if (!endDate && startDate) {
    return date >= startDate
  }
}

// Reusable function to emit an event using EventBus
export const eventBusEmitter = (eventName: string, payload) => {
  const { emit } = useEventBus(eventName)
  emit(payload)
}

// Reusable function to listen for an event and execute a callback using EventBus
export const eventBusListener = (eventName: string, callback) => {
  const { on } = useEventBus(eventName)
  on(callback)
}
