
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class DeletePopout extends Vue {
  @Prop() showDeleteModal!: boolean
  @Prop() closeModal!: boolean
  @Prop({ default: false }) attached!: boolean

  //cancel delete
  cancelDelete(): void {
    if (this.closeModal) {
      this.$store.dispatch('setCloseModal')
    }
    this.$emit('cancelDelete')
  }
  // confirm delete
  confirmDelete(): void {
    this.$emit('confirmDeleteFunction')
  }
}
