// Data for IconPicker - List of icons to choose from
export const iconOptions = [
  'mdi-flash-triangle',
  'mdi-plane-car',
  'mdi-access-point-network',
  'mdi-account',
  'mdi-account-circle',
  'mdi-account-group',
  'mdi-account-multiple',
  'mdi-airplane',
  'mdi-airplane-clock',
  'mdi-alert',
  'mdi-antenna',
  'mdi-sign-language',
  'mdi-train-car-gondola-full',
  'mdi-phone-incoming-outgoing-outline',
  'mdi-phone-incoming-outgoing',
  'mdi-button-pointer',
  'mdi-bug-stop',
  'mdi-abacus',
  'mdi-file-table',
  'mdi-menorah',
  'mdi-medication',
  'mdi-medal-outline',
  'mdi-medal',
  'mdi-shield-alert',
  'mdi-shield-alert-outline',
  'mdi-shield-cross',
  'mdi-shield-cross-outline',
  'mdi-shield-crown',
  'mdi-shield-crown-outline',
  'mdi-shield-edit',
  'mdi-shield-half-full',
  'mdi-shield-home',
  'mdi-shield-key-outline',
  'mdi-shield-link-variant-outline',
  'mdi-shield-lock',
  'mdi-shield-moon',
  'mdi-shield-moon-outline',
  'mdi-shield-off',
  'mdi-cards-heart-outline',
  'mdi-cards-heart',
  'mdi-cards-club',
  'mdi-cards-diamond',
  'mdi-cards-diamond-outline',
  'mdi-cards-club-outline',
  'mdi-account-arrow-up-outline',
  'mdi-ship-wheel',
  'mdi-shimmer',
  'mdi-shield-sword-outline',
  'mdi-shield-sword',
  'mdi-shield-star',
  'mdi-shield-search',
  'mdi-shoe-print',
  'mdi-shower-head',
  'mdi-shovel',
  'mdi-fish',
  'mdi-fishbowl-outline',
  'mdi-fish',
  'mdi-firework',
  'mdi-firework-off',
  'mdi-fireplace-off',
  'mdi-fire-truck',
  'mdi-fire-circle',
  'mdi-fire-alert',
  'mdi-flag',
  'mdi-flag-checkered',
  'mdi-sign-direction',
  'mdi-sickle',
  'mdi-shuriken',
  'mdi-sign-language',
  'mdi-sigma',
  'mdi-sigma-lower',
  'mdi-sign-caution',
  'mdi-microphone',
  'mdi-message-text-outline',
  'mdi-flare',
  'mdi-flash',
  'mdi-cctv',
  'mdi-account-school',
  'mdi-account-school-outline',
  'mdi-cellphone-basic',
  'mdi-ceiling-light-outline',
  'mdi-ceiling-light',
  'mdi-castle',
  'mdi-flashlight',
  'mdi-flask',
  'mdi-chair-rolling',
  'mdi-adjust',
  'mdi-ski',
  'mdi-ski-water',
  'mdi-mine',
  'mdi-flower-tulip',
  'mdi-airplane-landing',
  'mdi-airplane-alert',
  'mdi-airplane-settings',
  'mdi-airplane-takeoff',
  'mdi-alarm',
  'mdi-alarm-light',
  'mdi-chart-scatter-plot-hexbin',
  'mdi-chart-timeline-variant',
  'mdi-snowflake',
  'mdi-chess-king',
  'mdi-chess-knight',
  'mdi-chess-pawn',
  'mdi-chess-queen',
  'mdi-chess-rook',
  'mdi-food-apple',
  'mdi-food',
  'mdi-church',
  'mdi-cigar',
  'mdi-spider-thread',
  'mdi-star-outline',
  'mdi-star-crescent',
  'mdi-star-david',
  'mdi-star-shooting-outline',
  'mdi-steering',
  'mdi-antenna',
  'mdi-stool',
  'mdi-nuke',
  'mdi-string-lights',
  'mdi-fruit-cherries',
  'mdi-subway-variant',
  'mdi-arrow-all',
  'mdi-sword-cross',
  'mdi-sword',
  'mdi-sync',
  'mdi-nutrition',
  'mdi-oar',
  'mdi-oil',
  'mdi-gift',
  'mdi-owl',
  'mdi-glasses',
  'mdi-tank',
  'mdi-tanker-truck',
  'mdi-palette-swatch',
  'mdi-taxi',
  'mdi-telescope',
  'mdi-temple-hindu-outline',
  'mdi-temple-buddhist-outline',
  'mdi-grill',
  'mdi-paragliding',
  'mdi-parachute-outline',
  'mdi-parachute',
  'mdi-hand-back-left-outline',
  'mdi-peace',
  'mdi-paw',
  'mdi-thermometer',
  'mdi-baby-bottle-outline',
  'mdi-baby-carriage',
  'mdi-thumb-up-outline',
  'mdi-tie',
  'mdi-cross-bolnisi',
  'mdi-cross',
  'mdi-phone-classic',
  'mdi-crown',
  'mdi-head-snowflake-outline',
  'mdi-head-remove-outline',
  'mdi-headphones',
  'mdi-timer',
  'mdi-plane-train',
  'mdi-home-city-outline',
  'mdi-battery-charging-medium',
  'mdi-track-light',
  'mdi-traffic-light-outline',
  'mdi-traffic-cone',
  'mdi-hook',
  'mdi-human-cane',
  'mdi-truck-plus',
  'mdi-truck-fast',
  'mdi-truck-snowflake',
  'mdi-image-filter-hdr',
  'mdi-blood-bag',
  'mdi-block-helper',
  'mdi-image-search-outline',
  'mdi-vanish-quarter',
  'mdi-radioactive',
  'mdi-island',
  'mdi-drama-masks',
  'mdi-key-outline',
  'mdi-eiffel-tower',
  'mdi-electron-framework',
  'mdi-elephant',
  'mdi-boom-gate-arrow-up',
  'mdi-boom-gate',
  'mdi-bookshelf',
  'mdi-border-color',
  'mdi-boomerang',
  'mdi-video-input-antenna',
  'mdi-video-vintage',
  'mdi-email-outline',
  'mdi-bow-arrow',
  'mdi-bowl-mix-outline',
  'mdi-ladder',
  'mdi-label-variant-outline',
  'mdi-label-variant',
  'mdi-knife-military',
  'mdi-emoticon-cool-outline',
  'mdi-wall',
  'mdi-rocket-launch',
  'mdi-rocket',
  'mdi-bus-school',
  'mdi-water',
  'mdi-eye-plus-outline',
  'mdi-water-pump',
  'mdi-factory',
  'mdi-sail-boat',
  'mdi-satellite-uplink',
  'mdi-calendar-star-outline',
  'mdi-lock',
  'mdi-wifi',
  'mdi-file-document',
  'mdi-clipboard-edit',
  'mdi-share-variant-outline',
  'mdi-transit-connection-horizontal',
  'mdi-square-circle',
  'mdi-tune-variant',
]
