
import { Component, Vue } from 'vue-property-decorator'

import UserEditing from '@/components/Portal/Portal/Panes/Admin/DataEditing/UserEditing.vue'
import ExerciseEditing from '@/components/Portal/Portal/Panes/Admin/DataEditing/ExerciseEditing.vue'
import JsonEditor from '@/modules/common/components/JsonEditor/JsonEditor.vue'
@Component({
  components: {
    UserEditing,
    ExerciseEditing,
    JsonEditor,
  },
})
export default class AdminPage extends Vue {
  // is the JSON editor open
  editingData = false

  activeDataButton = 'Users'
  dataButtons: string[] = ['Users', 'Exercises']
  // dataButtons: string[] = ['Users', 'Elements', 'Templates', 'Exercises']

  btnActive(button): boolean {
    return this.activeDataButton === button
  }

  activeTab = 'Users'

  emitActivity() {
    this.$emit('activity')
  }

  filter = ''
}
