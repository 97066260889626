// types
import { Alert } from '@/types/interfaces/General'

// mixins
import { MiscStore } from '@/store/modules/misc/index'
import { MutationTree } from 'vuex'

const miscMutations: MutationTree<MiscStore> = {
  /*
      ======================================================
                          G E O  P I C K E R
      ======================================================
        @setGeoPicker: open or close the geo picker view
  */

  setGeoPicker(state, option: boolean): void {
    state.misc.geoPicker = option
  },

  /*
      ======================================================
                E N T I T Y  F I E L D  M A N A G  E R
      ======================================================
        @setGeoPicker: open or close the entity field manager
  */

  setEntityFieldManager(state, option: boolean): void {
    state.misc.entityFieldManager = option
  },

  /*
      ======================================================
                          M O D A L S
      ======================================================
        @setModal: set the active modal name and shown status
  */
  setModal(state, payload: { activeModal: string; modalVisible: boolean }): void {
    // set the modal to the right type
    // and also set it to on
    if (payload.activeModal != '') {
      state.modal = Object.assign({}, payload)
      state.openModalStack.push(payload.activeModal)
    } else if (payload.activeModal === '' && state.openModalStack.length) {
      // pop the current active modal we want to close
      state.openModalStack.pop()

      if (state.openModalStack.length > 0) {
        // there's a next modal in the stack to open
        const nextModal = state.openModalStack.at(-1)
        state.modal = Object.assign({}, { activeModal: nextModal, modalVisible: true })
      } else {
        // close the modal using the payload
        state.modal = Object.assign({}, payload)
      }
    } else {
      state.modal = Object.assign({}, { activeModal: '', modalVisible: false })
      state.openModalStack = []
    }
  },
  /*
    ======================================================
                        A L E R T S
    ======================================================
    @setAlert: set the alert color, text
  */

  // alerts
  setAlert(state, payload: Alert) {
    // log the alert
    if (payload.type === 'success') {
      console.log(`\n%c${payload.text}\n`, 'color: #4caf50')
    } else if (payload.type === 'info') {
      console.log(`\n%cINFO: ${payload.text}\n`, 'color: #2196f3')
    } else if (payload.type === 'warning') {
      console.log(`\n%cWARN: ${payload.text}\n`, 'color: #fb8c00')
    } else if (payload.type === 'error') {
      console.log()
      console.log(`\n%cERROR: ${payload.text}\n`, 'color: #ff5252')
    }
    payload = { ...payload }

    state.alert = payload
  },

  /*
        ======================================================
                      M I S C E L L A N E O U S
        ======================================================
          @setCurrentScrollY: set the current scroll position
   */

  setCurrentScrollY(state, payload: number): void {
    state.misc.currentScrollY = payload
  },

  /*
        ======================================================
                              T O O L S
        ======================================================
    */

  setActiveTab(state, payload: string): void {
    state.activeTab = payload
  },

  /*
    ======================================================
                   W I N D O W   W I D T H
    ======================================================
    */

  setWindowWidth(state, payload: number): void {
    state.misc.windowWidth = payload
  },
}

export default miscMutations
