
import { Component, Vue, Prop } from 'vue-property-decorator'

// types
import { Exercise } from '@/types/interfaces/Exercise'
import { SharedUser } from '@/types/interfaces/General'
import { TemplateButtons } from '@/types/interfaces/Exercise'

// components

import Banner from '@/components/Portal/Portal/General/Banner.vue'

// mixins
import {
  handleAcceptExerciseInvite,
  handleDeclineExerciseInvite,
  handleArchiveExercise,
  leaveEx,
} from '@/mixins/portal/exercises'
import { showShareModal, showDeleteModal, showDuplicateModal } from '@/mixins/portal/modals'

@Component({
  components: {
    Banner,
  },
})
export default class ExerciseCardOptions extends Vue {
  @Prop() exerciseUuid!: string

  get userUUID(): string {
    return this.$store.getters.getUser.uuid
  }

  get exercise(): Exercise {
    return this.$store.getters.getExerciseByUuid(this.exerciseUuid)
  }

  get owned(): boolean {
    return this.exercise.metadata?.owned === this.userUUID
  }

  get archived(): boolean {
    return !!this.exercise.metadata?.archived
  }

  get acceptedShare(): boolean {
    if (!this.owned) {
      const sharedUser = this.exercise.metadata?.shared.find((sharedUser: SharedUser) => {
        return this.userUUID === sharedUser.uuid
      })
      return sharedUser ? sharedUser.accepted : false
    }
    return false
  }

  get templateButtons(): TemplateButtons[] {
    const templateButtons = [
      {
        name: 'Delete',
        icon: 'mdi-delete',
        color: 'error',
        condition: !!this.owned,
        on: { click: this.handleDeleteModal },
      },
      {
        name: 'Accept',
        icon: 'mdi-share',
        color: 'success',
        condition: !this.owned && !this.acceptedShare,
        on: { click: this.handleAcceptExerciseInvite },
      },
      {
        name: 'Decline',
        icon: 'mdi-share-off',
        color: 'error',
        condition: !this.owned && !this.acceptedShare,
        on: { click: this.handleDeclineExerciseInvite },
      },
      {
        name: 'Leave',
        icon: 'mdi-share-off',
        color: 'info',
        condition: !this.owned && this.acceptedShare,
        on: { click: this.handleLeaveExercise },
      },
      {
        name: 'Share',
        icon: 'mdi-share',
        color: 'info',
        condition: this.owned || this.acceptedShare,
        on: { click: this.handleShareModal },
      },
      {
        name: 'Archive',
        icon: 'mdi-archive',
        color: 'orange accent-2',
        condition: (this.owned || this.acceptedShare) && !this.archived,
        on: { click: this.handleArchiveExercise },
      },
      {
        name: 'Unarchive',
        icon: 'mdi-archive',
        color: 'orange accent-2',
        condition: !!this.archived,
        on: { click: this.handleArchiveExercise },
      },
      {
        name: 'Duplicate',
        icon: 'mdi-check',
        color: 'orange accent-2',
        condition: !!this.owned,
        on: { click: this.handleDuplicateModal },
      },
    ]
    return templateButtons.filter(button => button.condition)
  }

  handleDuplicateModal(): void {
    this.$store.dispatch('setActiveEx', this.exercise)
    showDuplicateModal()
  }

  handleAcceptExerciseInvite(): void {
    if (this.exercise.metadata?.uuid) handleAcceptExerciseInvite(this.exercise.metadata?.uuid)
  }

  handleDeclineExerciseInvite(): void {
    if (this.exercise.metadata?.uuid) handleDeclineExerciseInvite(this.exercise.metadata?.uuid)
  }

  handleArchiveExercise(): void {
    if (this.exercise.metadata?.uuid)
      handleArchiveExercise(this.exercise.metadata?.uuid, this.archived)
  }

  // show the share modal
  handleShareModal(): void {
    this.$store.dispatch('setActiveEx', this.exercise)
    showShareModal()
  }

  handleDeleteModal(): void {
    this.$store.dispatch('setActiveEx', this.exercise)
    showDeleteModal()
  }

  handleLeaveExercise(): void {
    this.$store.dispatch('setActiveEx', this.exercise)
    if (this.exercise.metadata?.uuid) leaveEx(this.exercise.metadata?.uuid)
  }
}
