import { MiscStore } from '@/store/modules/misc/index'

// we set a default store for misc, so that we can reset the store with this default
export const defaultMiscState: MiscStore = {
  // the string dictating the active modal
  modal: {
    activeModal: null,
    modalVisible: false,
  },

  openModalStack: [],

  // which Element Preview popouts are pinned
  // absolutely in EASE
  pinnedElementPreviews: [],

  //the active page on the dashboard
  activeTab: 'Dashboard',

  // the alert modal/banner
  alert: null,

  // miscellaneous
  misc: {
    // current y axis of page
    currentScrollY: 0,
    windowWidth: window.innerWidth,
    // last getElements request
    lastGetElementsRequest: null,
    // last getTemplates request
    lastGetTemplatesRequest: null,
    filters: [],
    geoPicker: false,
    entityFieldManager: false,
  },
  // stores dynamic ENV vars to be used in scripts
  config: null,
}

const miscState = { ...defaultMiscState }

export default miscState as MiscStore
