
import { Component, Vue, Prop } from 'vue-property-decorator'
//mixins
import { addNewExercise } from '@/mixins/portal/exercises'
import { genUUID } from '@/mixins/general/helpers'
import { updateExconSettings } from '@/mixins/api/exercises'
//types
import { ExerciseSettings, Exercise } from '@/types/interfaces/Exercise'

@Component
export default class SubmitExBtn extends Vue {
  @Prop() newExSettings!: ExerciseSettings
  @Prop() formValid!: boolean
  @Prop() exconSettings!: { email: string; uuid: string }[]

  submitButtonDisabled(): boolean {
    return !this.formValid
  }

  // submit and update the new settings
  submitExerciseSettings(): void {
    this.submitNewExercise()
  }

  //get the current active user uuid
  get activeUserUUID(): string {
    return this.$store.getters.getUser.uuid
  }

  // submit and update the new settings
  submitNewExercise(): void {
    const newExercise: Exercise = {
      metadata: {
        uuid: genUUID(),
        owned: this.activeUserUUID,
        shared: [],
        archived: false,
        updated: new Date().toISOString(),
        created: new Date().toISOString(),
      },
      settings: this.newExSettings,
    }

    // API call
    addNewExercise(newExercise).then(() => {
      this.$store.commit('addNewOwnedExercise', newExercise)
      updateExconSettings(newExercise.metadata.uuid, this.exconSettings)
    })
    // close the modal
    this.$store.dispatch('setCloseModal')

    this.$store.dispatch('setAlert', {
      type: 'info',
      text: `Exercise ${newExercise.settings.Name} is being created...`,
    })
  }
}
