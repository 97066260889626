import { render, staticRenderFns } from "./AIButton.vue?vue&type=template&id=323cd71a&scoped=true&"
import script from "./AIButton.vue?vue&type=script&setup=true&lang=ts&"
export * from "./AIButton.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323cd71a",
  null
  
)

export default component.exports