import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import Store from '@/store'
import { EaseElement } from '@/types/interfaces/EaseElement'

export const useDocumentPreviewStore = defineStore('documentContentPreview', () => {
  // STATE
  const injectUuid = ref<string | null>(null)

  // MUTATIONS
  const showDocumentPreview = (uuid: string) => {
    injectUuid.value = uuid
  }

  const hideDocumentPreview = () => (injectUuid.value = null)

  // COMPUTED PROPERTY
  const inject = computed<EaseElement>(() => {
    return Store.getters.getElementByUUID(injectUuid.value)
  })

  return {
    injectUuid,
    inject,
    showDocumentPreview,
    hideDocumentPreview,
  }
})
