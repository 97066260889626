import { DateTime } from 'luxon'

/**
 * Convert a date between two formats (ex. "yyyy-MM-dd" to "d LLLL y")
 *
 * @param {string} dateString
 * @param {string} inFormat
 * @param {string} outFormat
 * @return {string} the date string in the desired format
 */
export const dateFormatToFormat = (
  dateString: string,
  inFormat: string,
  outFormat: string
): string => {
  return DateTime.fromFormat(dateString, inFormat).toFormat(outFormat)
}

/**
 * Adds an amount of time to a given time string and converts to another
 * format if desired
 *
 * @param {string} dateString
 * @param {string} inFormat
 * @param {string} outFormat
 * @param {string} time
 * @param {number} units
 * @return {string} the modified date string in the desired format
 */
export const modifyTime = (
  dateString: string,
  inFormat: string,
  outFormat: string,
  time: string,
  units: number
): string => {
  return DateTime.fromFormat(dateString, inFormat)
    .plus({ [time]: units })
    .toFormat(outFormat)
}

/**
 * Convert an ISO time to a string
 *
 * @param {string} date
 * @return {string} the converted date string
 */
export const isoTimeToString = (date: string): string => {
  // https://moment.github.io/luxon/#/formatting?id=table-of-tokens
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const locale = navigator.language
  const datetime = DateTime.fromISO(date).setLocale(locale).setZone(timezone)
  return datetime.toFormat('dd T LLL yy')
}

/**
 * Convert an blob into a Base64 string
 *
 * @param {Blob} blob
 * @return {Promise<string | ArrayBuffer>} the converted Base64 string
 */
export const blobToBase64 = (blob: Blob): Promise<string | ArrayBuffer> => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result ?? '')
    reader.readAsDataURL(blob)
  })
}

/**
 * Convert a date into a desired format
 *
 * @param {string | Date} date
 * @param {string} format
 * @return {string} the input date converted into the desired format
 */
export const getDateInFormat = (
  date: string | Date = new Date(),
  format = 'yyyy-MM-dd'
): string => {
  const dt = typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date)
  return dt.toFormat(format)
}

/**
 * Extracts time from a timestamp.
 * @param {Date|string} timestamp - The timestamp from which to extract the time.
 * @return {string} A string representing the time in the format "HH:MM".
 */
export const extractTime = (timestamp: Date | string = new Date()): string => {
  return (new Date(timestamp).toString().match(/\d{2}:\d{2}/g) ?? [''])[0]
}
