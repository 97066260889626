
import { Component, Vue } from 'vue-property-decorator'

// T Y P E S
import { deleteExercise } from '@/mixins/portal/exercises'
import { Exercise } from '@/types/interfaces/Exercise'

@Component
export default class ConfirmDeleteModal extends Vue {
  // vars
  itemName = ''

  get activeEx(): Exercise {
    return this.$store.getters.getActiveEx
  }

  get activeExName(): string {
    return this.$store.getters.getActiveEx.settings['Name']
  }

  allowDelete(): boolean {
    const userInput = this.itemName ? this.itemName.toLowerCase() : ''
    const expected = this.activeExName ? this.activeExName.toLowerCase() : 'not the user input'

    return !(userInput === expected)
  }

  closeModal(): void {
    this.$store.dispatch('setCloseModal')
  }

  // if user chooses to delete the item.
  handleDelete(): void {
    if (!this.activeEx.metadata?.uuid) return
    deleteExercise(this.activeEx.metadata?.uuid)
    this.closeModal()
  }
}
