
import { Exercise } from '@/types/interfaces/Exercise'
import { Notification } from '@/types/interfaces/Notifications'
import { useNotificationStore } from '@/modules/notifications/store/notification-store'
import { User } from '@/types/interfaces/General'

import { handleOpenExercise } from '@/mixins/portal/exercises'
import { reformatDate } from '@/mixins/general/helpers'

import { getEaseConfig } from '@/easeConfig'
import { Component, Vue } from 'vue-property-decorator'
import Banner from '@/components/Portal/Portal/General/Banner.vue'

@Component({
  components: {
    Banner,
  },
})
export default class LandingPage extends Vue {
  store = useNotificationStore()

  goToDocs(): void {
    window.location.href = getEaseConfig().EASE_DOCS_URL
  }
  reformatDate(dateStr: string | undefined): string {
    if (!dateStr) return ''
    return reformatDate(dateStr)
  }

  get lastExercise(): Exercise {
    return this.exercises[0] || null
  }

  get exercises(): Exercise[] {
    return [...this.ownedExercises, ...this.sharedExercises].sort((a, b) => {
      if (!a.metadata?.updated) return 1
      if (!b.metadata?.updated) return -1
      if (a.metadata.updated < b.metadata.updated) {
        return 1
      }
      if (a.metadata.updated > b.metadata.updated) {
        return -1
      }
      return 0
    })
  }

  get ownedExercises(): Exercise[] {
    return this.$store.getters.getOwnerExercises
  }

  get sharedExercises(): Exercise[] {
    return this.$store.getters.getSharedExercises
  }

  get notifications(): Notification[] {
    return this.store.notifications
  }

  get user(): User {
    return this.$store.getters.getUser
  }

  handleOpenExercise(): void {
    if (!this.lastExercise.metadata?.uuid) return
    handleOpenExercise(this.lastExercise.metadata?.uuid)
  }
}
