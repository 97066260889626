import { getEaseConfig } from '@/easeConfig'
import axios, { AxiosInstance } from 'axios'

let easeSimServiceApi: AxiosInstance

export function initEaseSimServiceApi() {
  easeSimServiceApi = axios.create({
    baseURL: getEaseConfig().EASE_SIM_SERVICE_URL,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' },
  })
}

export { easeSimServiceApi }
