import { Module } from 'vuex'
import { EaseStore } from '@/store'
import templateState from '@/store/modules/templates/templateState'
import templateMutations from '@/store/modules/templates/templateMutations'
import templateActions from '@/store/modules/templates/templateActions'
import templateGetters from '@/store/modules/templates/templateGetters'
import { ApiTemplates, Template } from '@/types/interfaces/Template'

export interface TemplateStore {
  templates: ApiTemplates
  activeTemplate: Template | null
}

export const templatesModule: Module<TemplateStore, EaseStore> = {
  state: templateState,
  getters: templateGetters,
  mutations: templateMutations,
  actions: templateActions,
}
