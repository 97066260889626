// mixins
// types
import { MutationTree } from 'vuex'
import { ExStore } from '@/store/modules/exercise'
import { Exercise } from '@/types/interfaces/Exercise'

const exerciseMutations: MutationTree<ExStore> = {
  /*
    ======================================================
                    E X E R C I S E
    ======================================================
    @setEx: set an exercise from payload, set Ex loaded to true, and update
    @updateEx: update a key in the ex
  */

  setEx(state, payload: Exercise): void {
    state.ex = Object.assign({}, state.ex, payload)
    state.exLoaded = true
    state.exUpdates++
  },

  updateEx(state, payload: { [key: string]: string }) {
    state.ex.settings = Object.assign({}, state.ex.settings, {
      [payload.name]: payload.value,
    })
    state.exUpdates++
  },

  // update the exUpdates counter
  // which is used by other components to know when to refresh
  incrementExUpdates(state): void {
    state.exUpdates++
  },
}

export default exerciseMutations
