import { render, staticRenderFns } from "./UserEditing.vue?vue&type=template&id=120bcfd1&scoped=true&"
import script from "./UserEditing.vue?vue&type=script&setup=true&lang=ts&"
export * from "./UserEditing.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./UserEditing.vue?vue&type=style&index=0&id=120bcfd1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "120bcfd1",
  null
  
)

export default component.exports