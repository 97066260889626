
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class CardTitleBar extends Vue {
  @Prop({ default: false }) halo!: boolean
  @Prop({ default: false }) muted!: boolean
  @Prop({ default: 'black' }) color!: 'black' | 'white'

  haloColor(): string {
    return this.color === 'white' ? 'black' : 'white'
  }
}
