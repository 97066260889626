import { Module } from 'vuex'
import { EaseStore } from '@/store'
import { Alert } from '@/types/interfaces/General'
import miscState from '@/store/modules/misc/miscState'
import miscActions from '@/store/modules/misc/miscActions'
import miscGetters from '@/store/modules/misc/miscGetters'
import miscMutations from '@/store/modules/misc/miscMutations'
import { PinnedElementPreview } from '@/types/interfaces/EaseElement'

export interface MiscStore {
  // the alert modal/banner
  alert: Alert | null

  //Pinned Element UUIDs
  pinnedElementPreviews: PinnedElementPreview[]

  // the string dictating the active modal
  modal: {
    activeModal: string | undefined | null
    modalVisible: boolean
  }

  openModalStack: string[]

  // active component in the ElementModal
  activeTab: string // new * ADAM *

  misc: {
    // current y axis of page
    currentScrollY: number
    windowWidth: number
    lastGetElementsRequest: string | null
    lastGetTemplatesRequest: string | null
    filters: []
    geoPicker: boolean
    entityFieldManager: boolean
  }
  config: {
    [key: string]: string | boolean
  } | null
}

export const miscModule: Module<MiscStore, EaseStore> = {
  state: miscState,
  getters: miscGetters,
  mutations: miscMutations,
  actions: miscActions,
}
