import { ref } from 'vue'
import { defineStore } from 'pinia'
import easeStore from '@/store'
import { Notification } from '@/modules/notifications/model/notification-model'
import { api } from '@/modules/notifications/api/notification-api'

export const useNotificationStore = defineStore('notification', () => {
  // STATE
  const notifications = ref<Notification[]>([])

  // INIT
  api.fetchAllNotifications().then(notification => {
    notifications.value = notification.notifications
  })

  /**
   * Fetches all notifications
   */
  async function fetchNotifications(): Promise<void> {
    try {
      const newNotifications = await api.fetchAllNotifications()
      notifications.value = newNotifications.notifications
    } catch (e) {
      easeStore.dispatch('setAlert', {
        text: `Failed to fetch all notifications`,
        type: 'error',
      })
    }
  }

  /**
   * Creates a new random notificaton
   */
  async function addNewRandomNotifcation(): Promise<void> {
    try {
      await api.createNewNotification()
      await api.fetchAllNotifications()
    } catch (e) {
      easeStore.dispatch('setAlert', {
        text: `Failed to generate a random notification`,
        type: 'error',
      })
    }
  }

  /**
   * Adcknowledges a single notification
   */
  async function ackOne(notificationUUID: string): Promise<void> {
    try {
      await api.ackOne(notificationUUID)
    } catch (e) {
      easeStore.dispatch('setAlert'),
        {
          text: 'Failed to acknowledge the notification',
          type: 'error',
        }
    }
  }

  /**
   * Adcknowledges all notifications
   */
  async function ackAll(): Promise<void> {
    try {
      api.ackAll()
    } catch (e) {
      easeStore.dispatch('setAlert'),
        {
          text: 'Failed to acknowledge all of the notifications',
          type: 'error',
        }
    }
  }

  /**
   * Fetches all the accepted notifications
   */
  async function getAcceptedNotifications(): Promise<Notification[]> {
    try {
      const response = await api.getAcceptedNotifications()
      const newNotifications = await api.fetchAllNotifications()

      notifications.value = newNotifications.notifications
      return response
    } catch (e) {
      easeStore.dispatch('setAlert'),
        {
          text: 'Failed to fetch accepted notifications',
          type: 'error',
        }
      throw e
    }
  }

  return {
    notifications,
    addNewRandomNotifcation,
    ackOne,
    ackAll,
    getAcceptedNotifications,
    fetchNotifications,
  }
})
