import Vue from 'vue'
import Vuex from 'vuex'

import { elementsModule, ElementStore } from '@/store/modules/elements'
import { miscModule, MiscStore } from '@/store/modules/misc'
import { exerciseModule, ExStore } from '@/store/modules/exercise'
import { templatesModule, TemplateStore } from '@/store/modules/templates'
import { userModule, UserStore } from '@/store/modules/user'
import { portalModule } from '@/store/modules/portal'
import { PortalStore } from '@/types/interfaces/Portal'
import { initModule, InitStore } from '@/store/modules/init'

Vue.use(Vuex)

export interface EaseStore {
  exercise: ExStore
  templates: TemplateStore
  elements: ElementStore
  user: UserStore
  misc: MiscStore
  init: InitStore
  portal: PortalStore
}

export default new Vuex.Store<EaseStore>({
  modules: {
    init: initModule,
    exercise: exerciseModule,
    elements: elementsModule,
    templates: templatesModule,
    user: userModule,
    misc: miscModule,
    portal: portalModule,
  },
})
