import { render, staticRenderFns } from "./SupportPage.vue?vue&type=template&id=6253fe21&scoped=true&"
import script from "./SupportPage.vue?vue&type=script&lang=ts&"
export * from "./SupportPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6253fe21",
  null
  
)

export default component.exports