var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-fade-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.alertTimer),expression:"alertTimer"}],staticClass:"alert",style:({
        backgroundColor: _setup.alert?.bgColor,
        border: `1px solid var(--v-${_setup.alert?.type}-base)`,
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      }),attrs:{"color":_setup.alert?.type,"type":_setup.alert?.type}},[_c('v-progress-linear',{staticStyle:{"border-top-left-radius":"3px","border-top-right-radius":"3px","transition":"none"},attrs:{"value":_setup.alertProgress,"top":"","color":_setup.alert?.type}}),_c('div',{staticClass:"alert-content px-5 py-2 d-flex align-center justify-center",style:({
          color: `var(--v-${_setup.alert?.type}-base)`,
          borderLeft: `5px solid ${_setup.alert?.bgColor.slice(0, -2) + '30'}`,
        })},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_setup.alert?.type}},[_vm._v(" "+_vm._s(_setup.alert?.icon)+" ")]),_vm._v(" "+_vm._s(_setup.alert?.text)+" ")],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }