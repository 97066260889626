import { GetterTree } from 'vuex'
import { EaseStore } from '@/store'
import { ExStore } from '@/store/modules/exercise/index'
import { Exercise, ExerciseSettings } from '@/types/interfaces/Exercise'

const exerciseGetters: GetterTree<ExStore, EaseStore> = {
  /*
      ======================================================
                  E X E R C I S E   D A T A
      ======================================================

        @getEx: gets the whole ex (metadata, element, etc)
        @getExUUID: get just the ex_uuid
        @getExSettings: get just the ex metadata
        @getExSettingByName: gets just the ex metadata, by name
        @getExLoaded: gets if the exercise is loaded
        @getExUpdates: gets the current updates, used for refreshing
        @getShortClassification: gets just the short classification
  */

  // gets the whole ex (metadata, element, etc)
  getEx: (state): Exercise => state.ex,

  // get just the ex_uuid
  getExUUID: (state): string | null => state?.ex?.metadata?.uuid || null,

  // get just the ex name
  getActiveExName: (state): string => state.ex.settings.Name,

  // gets JUST the ex metadata
  getExSettings: (state): ExerciseSettings => state.ex.settings,

  // gets just the ex metadata, by name
  // autoTest || getExSettingByName || ElementData
  getExSettingByName:
    state =>
    (setting: string): ExerciseSettings => {
      return state.ex.settings[setting]
    },

  // gets if the exercise is loaded
  getExLoaded: (state): boolean => state.exLoaded,

  // gets the current updates, used for refreshing
  getExUpdates: (state): number => state.exUpdates,

  // gets just the short classification
  getShortClassification: (state): string | number | Blob =>
    state.ex.settings['Short Classification'],
}

export default exerciseGetters
