// V U E
import Vue from 'vue'

// M A I N   A P P
import App from '@/App.vue'

// V U E X
import store from '@/store'

// V U E T I F Y
import vuetify from '@/plugins/vuetify'

// R O U T E R
import router from './router'

// A X I O S
import axios from 'axios'

// E S R I
import esriConfig from '@arcgis/core/config'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { initCoordinateFormatter } from '@/mixins/general/geo'
import { loadConfig } from '@/easeConfig'
import { initEaseApi } from '@/api/ease.api'
import { initEaseApiV2 } from '@/api/ease-v2.api'
import { initEaseSimServiceApi } from '@/api/ease-sim-service.api'

// use local esri assets for production, instead of the CDN
if (process.env.NODE_ENV === 'production') {
  esriConfig.assetsPath = './esri/assets'
}

// Default Config for requests
axios.defaults.withCredentials = true

// disable annoying tooltip
Vue.config.productionTip = false

// load asynchronous resources before initializing the app
await Promise.all([loadConfig(), initCoordinateFormatter()])

initEaseApi()
initEaseApiV2()
initEaseSimServiceApi()

// pinia store loading
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

new Vue({
  store,
  router,
  pinia,
  vuetify,
  render: h => h(App),
}).$mount('#app')
