
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class Banner extends Vue {
  @Prop() text!: string
  @Prop({
    default: 'accent',
  })
  bannerColor!: string
  @Prop({
    default: 'secondary',
  })
  textColor!: string
}
