import Store from '@/store'
import { EaseElement, ElementData, Link } from '@/types/interfaces/EaseElement'
import { LinkInformation, linkValueObject } from '@/types/interfaces/Link'

import { genUUID } from './helpers'

/**
 * Create a new link object
 *
 * @param {ElementData} attributeData - The link data of what is being linked to
 * @param {string | null} attributeName - The element field name being linked from
 * @param {string | null} sourceIndexUuid - The uuid of the source attribute only if it is an array content (combo chip)
 * @param {string | null} selfIndexUuid - The uuid of the self attribute only if it is an array content (combo chip)
 * @param {string} direction - The direction of the link, one of: "to" | "from" | "both" | "unknown"
 * @returns {Link} link - The new link object
 */
export const createLinkObject = (
  attributeData: ElementData,
  attributeName: string | null,
  sourceIndexUuid: string | null,
  selfIndexUuid: string | null = null,
  direction: 'to' | 'from' | 'both' | 'unknown' = 'from'
): Link => {
  const activeElement: EaseElement = Store.getters.getActiveElement
  const selfAttributeUUID = activeElement.data.find(
    (elementAttribute: ElementData) => elementAttribute.name === attributeName
  )
  return {
    uuid: genUUID(),
    id: `${attributeData.element}_${attributeData.uuid}_${sourceIndexUuid ? sourceIndexUuid : ''}`,
    type: 'active',
    self: {
      attributeName,
      elementUUID: activeElement.metadata.uuid,
      attributeUUID: selfAttributeUUID?.uuid ?? null,
      indexUUID: selfIndexUuid ?? null,
    },
    source: {
      attributeName: attributeData.name,
      elementUUID: attributeData.element,
      attributeUUID: attributeData.uuid ?? null,
      indexUUID: sourceIndexUuid ?? null,
    },
    direction,
    style: {
      connector: {
        style: {
          strokeColor: 'white',
          fill: activeElement.template.metadata.style?.color ?? 'white',
          strokeWidth: 1,
        },
        type: 'straight',
      },
      node: {
        style: {
          fill: activeElement.template.metadata.style?.color ?? 'white',
          strokeColor: 'white',
          strokeWidth: 1,
        },
      },
    },
  }
}

/**
 * Initalize new link
 *
 * @param {ElementData} attributeData - The link data of what is being linked to
 * @param {string} attributeName - The element field name being linked from
 * @param {string} sourceIndexUuid - The uuid of the source attribute only if it is an array content (combo chip)
 * @param {string} selfIndexUuid - The uuid of the self attribute only if it is an array content (combo chip)
 * @param {string} direction - The direction of the link, one of: "to" | "from" | "both" | "unknown"
 * @returns {string} the html for the new link
 */
export const initActiveLink = (
  attributeData: ElementData,
  attributeName: string,
  sourceIndexUuid: string | null = null,
  selfIndexUuid: string | null = null,
  direction: 'to' | 'from' | 'both' | 'unknown' = 'from'
): string => {
  const link: Link = createLinkObject(
    attributeData,
    attributeName,
    sourceIndexUuid,
    selfIndexUuid,
    direction
  )
  // extract content based on whether its an array or not
  let dataContent: string | null = null

  if (Array.isArray(attributeData.content) && attributeData.content.length > 0) {
    dataContent = attributeData.content.find(combo => combo.uuid === sourceIndexUuid)?.data ?? null
  } else if (typeof attributeData.content === 'string') {
    dataContent = attributeData.content
  }

  Store.dispatch('updateActiveElementLink', link)
  if (attributeData.content && attributeData.type === 'image') {
    return `
    <span class="ease-link" data-link="${link.id}" contenteditable="false" >
    <img
    src="${dataContent}"
    contain 
    height = "120px" 
    />
    </span>
    `
  } else {
    return `<span class="ease-link" data-link="${
      link.id
    }" contenteditable="false">${parseTextFromContent(dataContent as string).trim()}</span>`
  }
}

/**
 * Reset the local links from the field passed in to match the exact
 * set of link spans in their data without duplicates
 *
 * @param {HTMLSpanElement[]} spanLinks - The .ease-link span elements in the field
 * @param {string} fieldName - The name of the active element field that is being validated
 * @param {string} indexUuid - uuid of the array data (combo chip) being linked from, if it exists
 */
export const validateLinks = (
  spanLinks: HTMLSpanElement[],
  fieldName: string,
  indexUuid: string | null = null
): void => {
  // Remove the links that correspond to the current field/array data (combo chip)
  Store.dispatch('resetActiveElementFieldLinks', { fieldName, indexUuid })
  // iterate through each .ease-link span
  spanLinks.forEach((linkSpan: HTMLSpanElement) => {
    // parse the link data
    const linkId = linkSpan.dataset.link?.split('_')
    if (!linkId) return
    const sourceElement = linkId[0]
    const sourceAttribute = linkId[1]
    const sourceIndex = linkId.length == 3 ? linkId[2] : ''

    // look for link from current field in the local links
    let link = undefined
    if (indexUuid) {
      link = Store.getters.getActiveElementLinks.find((link: Link) => {
        return (
          link.id === `${sourceElement}_${sourceAttribute}_${sourceIndex}` &&
          link.self.indexUUID == indexUuid
        )
      })
    } else {
      link = Store.getters.getActiveElementLinks.find((link: Link) => {
        return (
          link.id === `${sourceElement}_${sourceAttribute}_${sourceIndex}` &&
          link.self.attributeName == fieldName
        )
      })
    }

    // if the links does not exist, add it
    if (!link) {
      const linkData = Store.getters.getElementDataAttributeByUuid(sourceElement, sourceAttribute)
      initActiveLink(linkData, fieldName, sourceIndex, indexUuid)
    }
  })
}

/**
 * Parse the text from the content of the link
 * @param {string} linkText - The text to be parsed
 * @return {string} The parsed text
 */
export const parseTextFromContent = (linkText: string, result = ''): string => {
  const div: HTMLDivElement = document.createElement('div')
  div.innerHTML = linkText
  const linkSpan = div.querySelector('.ease-link')

  if (linkSpan) {
    linkSpan.outerHTML = linkSpan.innerHTML
    return result + parseTextFromContent(div.innerHTML)
  } else {
    return div.innerHTML
  }
}

/**
 * Render Element attributes as `html`
 *
 * @param {ElementData} elementData - The Element attribute to be rendered
 * @return {string} The rendered attribute
 */
export const renderElementData = (elementData: ElementData): string => {
  if (typeof elementData === 'string') {
    return `<div class="ease-link-render">${elementData}</div>`
  } else {
    // get the content
    const content = elementData.content

    let contentValue: string | null = ''

    // check for relational elements
    if (typeof content === 'string') {
      // get the relational content
      contentValue = content as string
    }

    // extract the content around the span tags
    const tempDiv: HTMLDivElement = document.createElement('div')
    tempDiv.innerHTML = contentValue

    // return the new string
    return `<div class="ease-link-render">${contentValue}</div>`
  }
}

/**
 * This is a helper function to get the element data from the element as a tooltip
 *
 * @returns a tooltip about a link
 */
export const getLinkInfo = (link: Link): LinkInformation | null => {
  // The source element the link is coming from if doesnt exist check the dnd editor
  const sourceElement =
    Store.getters.getElementByUUID(link.source.elementUUID) ||
    Store.getters.getDndEntityEditorElement(link.source.elementUUID)
  if (!sourceElement) return null

  // get the original field as well
  const sourceField: ElementData | null = sourceElement.data.find(
    data => data.name === link.source.attributeName
  )
  if (!sourceField) return null

  let sourceFieldText: string | null = ''
  if (Array.isArray(sourceField.content)) {
    if (sourceField.type == 'combo') {
      sourceFieldText =
        sourceField.content.find(item => item.uuid === link.source.indexUUID)?.data || ''
    } else if (sourceField.type == 'image' && 'description' in sourceField.content[0]) {
      sourceFieldText = sourceField.content[0]['description'] ?? ''
    }
  } else if (typeof sourceField.content === 'string') {
    sourceFieldText = sourceField.content
  }
  // turn the content to an element so we can select the text
  const div: HTMLDivElement = document.createElement('div')
  div.innerHTML = sourceFieldText

  return {
    color: sourceElement.template.metadata.style?.color ?? '#000000',
    templateName: sourceElement.template.metadata.name,
    intent: sourceElement.metadata.excon.intent,
    time: sourceElement.metadata.time ?? null,
    elementName: sourceElement.metadata.name,
    content: sourceFieldText,
    text: div.innerText,
    fieldName: sourceField.name,
  }
}

/**
 * Get an element by its UUID
 *
 * @param {string} elementUUID The desired element's UUID
 * @return {EaseElement} The element matching the given UUID
 */
export const getElementByUUID = (elementUUID: string): EaseElement => {
  if (/\{\{.+\}\}/.test(elementUUID)) {
    elementUUID = elementUUID.slice(2, -2)
  }
  // get the elements from the store so we can iterate/scan them
  let toReturn
  const elements: EaseElement[] = Store.getters.getAllElements
  elements.forEach((element: EaseElement) => {
    if (element.metadata.uuid === elementUUID) {
      toReturn = element
      return false
    }
  })
  return toReturn
}

/**
 * Converts a link value from string form to object form
 *
 * @param {string} linkValue The link value in string form
 * @return {linkValueObject} The link value as an object
 */
export const linkValueToObject = (linkValue: string): linkValueObject => {
  // remove any leading/traitling whitespace as well as curly braces
  linkValue = linkValue?.trim().replace(/[{}]/g, '')

  const linkInformation = {}
  linkValue
    ?.trim()
    ?.split('.')
    ?.forEach(searchParam => {
      const temp = searchParam?.trim().split('=')
      linkInformation[temp[0]?.trim()] = temp[1]?.trim()
    })
  return linkInformation
}

/**
 * Strips ease-link span tags from a string
 * @param {string} text The text to strip
 * @returns {string} The stripped text
 * @example
 * stripEaseLinkTags('<span class="ease-link" data-link="123_456">test</span>') // returns 'test'
 */
export const stripEaseLinkTags = (text: string): string => {
  // create a span element and set its innerHTML to the text
  const span = document.createElement('span')
  span.innerHTML = text

  // get all the ease-link spans and replace them with their text content
  const links = span.querySelectorAll('span.ease-link')
  links.forEach(link => {
    if (link.textContent) link.replaceWith(link.textContent)
  })

  return span.innerHTML
}

/**
 * Get the link object from the link id
 * @param {string} linkId  The id of the link to get
 * @returns {Link}  The link object
 */
export const getLink = (linkId: string): Link | undefined => {
  const fromElementLinks = (Store.getters.getElementLinks as Link[]).find(
    (link: Link) => link.id === linkId
  )

  if (fromElementLinks) {
    return fromElementLinks
  }

  const fromActiveElementLinks = (Store.getters.getActiveElementLinks as Link[]).find(
    (link: Link) => link.id === linkId
  )
  return fromActiveElementLinks
}

/**
 * Get the children of an element (the elements that link to it)
 * @param targetElement The element to get the children of
 */
export const getChildrenShallow = (targetElement: string): EaseElement[] => {
  return [...Store.getters.getElements, ...Store.getters.getDndEntityEditorElements].filter(
    (element: EaseElement) => {
      return element.links?.find((link: Link) => {
        return link.source.elementUUID === targetElement
      })
    }
  )
}

/**
 * Get the elements linked to an attribute (Field, chip, image etc)
 * @param uuid The uuid of the field to get the linked elements of
 */
export const getAttributeChildren = (uuid: string, isTextField = false): EaseElement[] => {
  const childrenElements = getChildrenShallow(Store.getters.getActiveElement.metadata.uuid)
  return childrenElements.filter(element =>
    element.links.some(link =>
      isTextField ? link.source.attributeUUID === uuid : link.source.indexUUID === uuid
    )
  )
}
