import { ElementStore } from '@/store/modules/elements/index'

export const defaultElementState: ElementStore = {
  // the active tool element
  activeElement: null,
  elements: [],
  // selected element UUID for dataViewer
  selectedElement: null,
  // active field in a template: USED BY THE LINKING TOOL
  activeAttribute: undefined,
  //
  trashedElementCount: 0,
  // dndEntityEditorElements: [] as { element: EaseElement; x: number; y: number; }[]
  dndEntityEditorElements: [],

  // track which elements are awaiting updates
  pendingElementUpdates: [],
}

const elementState = { ...defaultElementState }

export default elementState
