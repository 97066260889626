
import { Component, Vue } from 'vue-property-decorator'

// components
import ExerciseCard from '@/components/Portal/Portal/General/Cards/ExerciseCard.vue'

// types
import { User } from '@/types/interfaces/General'
import { Exercise, ApiExercises } from '@/types/interfaces/Exercise'
import BaseModal from '@/components/Modals/BaseModal.vue'

@Component({
  components: {
    ExerciseCard,
    BaseModal,
  },
})
export default class ExercisePage extends Vue {
  showOwnedExercises = true
  showSharedExercises = true
  showArchivedExercises = false
  exerciseFilter = ''

  closeModal() {
    this.$store.dispatch('setCloseModal')
  }

  // Modal logic, boolean to show it or hide it
  get showModal(): boolean {
    return this.$store.getters.getShowModal
  }

  // Modal logic, string to specify which modal
  get activeModal(): string {
    return this.$store.getters.getActiveModal
  }

  get user(): User {
    return this.$store.getters.getUser
  }

  get exerciseCount(): number {
    return [...this.exercises.recent, ...this.exercises.old].length
  }

  // sort modified within two-week exercises and old exercises into sections
  get exerciseSections(): { [key: string]: { title: string; exercises: Exercise[] } } {
    const sections: { [key: string]: { title: string; exercises: Exercise[] } } = {}

    if (this.exercises.recent.length) {
      sections.recent = {
        title: 'Exercises with recent updates',
        exercises: this.exercises.recent,
      }
    }
    if (this.exercises.old.length) {
      sections.old = {
        title: 'Exercises with no updates for two weeks or more',
        exercises: this.exercises.old,
      }
    }
    return sections
  }

  // filter functions
  // =================
  isArchived = (exercise: Exercise): boolean => !!exercise.metadata?.archived

  // has the exercise been updated in two weeks
  updatedWithinTwoWeeks = (exercise: Exercise) => {
    // two weeks ago in milliseconds
    const twoWeeksAgo = new Date().getTime() - 60 * 60 * 24 * 14 * 1000
    const updatedTime = new Date(exercise.metadata?.updated ?? new Date().toISOString()).getTime()
    return updatedTime >= twoWeeksAgo
  }

  // if exerciseFilter is not empty, return exercises that match the filter
  matchesNameFilter = (exercise: Exercise, searchParams: string[]) => {
    if (!searchParams.length) return true

    // if the exercise name contains the filter, return true
    const exName = exercise.settings.Name.toLowerCase()

    // if the exercise name contains all the terms, return true
    return searchParams.every(term => exName.includes(term))
  }

  filterExercises = (exercises: Exercise[], searchParams: string[]) =>
    exercises
      .filter(exercise => {
        // check if we're showing archived exercises and if the exercise is archived
        if (this.showArchivedExercises && !this.isArchived(exercise)) {
          return false
        }

        // check if we have a filter and if the exercise matches the filter
        if (searchParams && !this.matchesNameFilter(exercise, searchParams)) {
          return false
        }

        return true
      })
      .sort((xA: Exercise, xB: Exercise) => {
        if (!xA.metadata?.updated) return 1
        if (!xB.metadata?.updated) return -1
        const dA = new Date(xA.metadata?.updated)
        const dB = new Date(xB.metadata?.updated)

        // Comparison logic for ascending sort
        if (dB < dA) {
          return -1
        } else if (dB > dA) {
          return 1
        } else {
          return 0
        }
      })

  // get exercises from store and filter them
  get exercises(): { recent: Exercise[]; old: Exercise[] } {
    const apiExercises: ApiExercises = this.$store.getters.getExercises

    // init a flattened exercises container
    const flatExercises: Exercise[] = []

    // add owned exercises?
    if (this.showOwnedExercises) {
      flatExercises.push(...apiExercises.owned)
    }

    // add shared exercises?
    if (this.showSharedExercises) {
      flatExercises.push(...apiExercises.shared)
    }

    const searchParams = this.exerciseFilter.toLowerCase().split(' ')

    // filter the exercises for archived and name filters
    const filteredExercises = this.filterExercises(flatExercises, searchParams)

    // init a sorted exercises container
    const sortedExercises: { recent: Exercise[]; old: Exercise[] } = {
      recent: [],
      old: [],
    }

    // sort the exercises into recent and old
    filteredExercises.forEach(exercise => {
      this.updatedWithinTwoWeeks(exercise)
        ? sortedExercises.recent.push(exercise)
        : sortedExercises.old.push(exercise)
    })

    return sortedExercises
  }

  handleExCreation(): void {
    this.$store.dispatch('setModal', {
      activeModal: 'Exercise Settings',
      modalVisible: true,
    })
  }
}
