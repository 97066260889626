import { computed, readonly, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import Store from '@/store'
import { api, OmitServerProps } from '@/modules/training/api/training-api'
import {
  TrainingAudience,
  TrainingEvaluation,
  TrainingGoal,
  TrainingObjective,
} from '@/modules/training/model/training-model'
import { genUUID } from '@/mixins/general/helpers'

const exerciseUuid = computed<string>(() => Store.getters.getEx?.metadata?.uuid)

// TODO: might want to break this up into multiple stores, as it's getting a bit large

/**
 * Training store for managing training data: audiences, goals, objectives, and evaluations
 */
export const useTrainingStore = defineStore('training', () => {
  // STATE
  const audiences = ref<TrainingAudience[]>([])
  const goals = ref<TrainingGoal[]>([])
  const evaluations = ref<TrainingEvaluation[]>([])
  const loaded = ref(false)

  function $reset() {
    audiences.value = []
    goals.value = []
    evaluations.value = []
    loaded.value = false
  }

  async function loadTrainingData() {
    const trainingData = await api.fetchAllTrainingData()
    audiences.value = trainingData.audiences
    goals.value = trainingData.goals
    evaluations.value = trainingData.evaluations
    loaded.value = true
  }

  // reload the training data when the exercise changes
  // clear the state if there is no exercise
  // use immediate so that the data is loaded upon the initial load of the store
  watch(
    exerciseUuid,
    async exUuid => {
      exUuid ? await loadTrainingData() : $reset()
    },
    { immediate: true }
  )

  // GETTERS

  /**
   * Filters training audiences based on a given filter object.
   * If the filter object contains a goalUuid, it will return all audiences that have that goal.
   **/
  const filterAudiences = computed(
    () =>
      (filter: { goalUuid?: string; objectiveUuid?: string }): TrainingAudience[] => {
        if (filter.goalUuid) {
          return audiences.value.filter(
            a => filter.goalUuid && a.goalUuids.includes(filter.goalUuid)
          )
        } else if (filter.objectiveUuid) {
          const foundGoal = filterGoals.value({
            objectiveUuid: filter.objectiveUuid,
          })[0]
          return audiences.value.filter(a => a.goalUuids.includes(foundGoal.uuid))
        }
        return audiences.value
      }
  )

  /**
   * Find an audience by uuid. Returns an error if it cannot be found.
   */
  const findAudience = computed(() => (audienceUuid: string): TrainingAudience => {
    const audience = audiences.value.find(a => a.uuid === audienceUuid)

    if (!audience) {
      throw new Error(`Could not find audience with uuid ${audienceUuid}`)
    }

    return audience
  })

  /**
   * Find a goal by uuid. Returns undefined if no goal is found
   */
  const findGoal = computed(() => (goalUuid: string): TrainingGoal => {
    const goal = goals.value.find(g => g.uuid === goalUuid)

    if (!goal) {
      throw new Error(`Could not find goal with uuid ${goalUuid}`)
    }

    return goal
  })

  /**
   * Find an objective by uuid. Returns undefined if no objective is found
   */
  const findObjective = computed(() => (objectiveUuid: string): TrainingObjective => {
    const objective = goals.value.flatMap(g => g.objectives).find(o => o.uuid === objectiveUuid)

    if (!objective) {
      throw new Error(`Could not find objective with uuid ${objectiveUuid}`)
    }

    return objective
  })

  /**
   * Returns the evaluation matching the selector. If no evaluation is found, returns a new evaluation
   * that can be updated as if it were an existing one
   */
  const findEvaluation = computed(
    () =>
      (selector: {
        audienceUuid: string
        goalUuid: string
        objectiveUuid: string | undefined
      }): TrainingEvaluation => {
        const evaluation = evaluations.value.find(
          e =>
            e.audienceUuid === selector.audienceUuid &&
            e.goalUuid === selector.goalUuid &&
            e.objectiveUuid === selector.objectiveUuid
        )
        if (evaluation) return evaluation
        if (!selector.objectiveUuid) throw new Error('objectiveUuid is required')
        const newEvaluation: TrainingEvaluation = {
          audienceUuid: selector.audienceUuid,
          goalUuid: selector.goalUuid,
          objectiveUuid: selector.objectiveUuid,
          exerciseUuid: exerciseUuid.value,
          sourceUuids: [],
          grade: 0,
          comments: [],
        }
        return newEvaluation
      }
  )

  /**
   * Returns all evaluations matching the filter
   */
  const filterEvaluations = computed(
    () =>
      (filter: {
        audienceUuid?: string
        goalUuid?: string
        objectiveUuid?: string
      }): TrainingEvaluation[] =>
        evaluations.value.filter(
          e =>
            (!filter.audienceUuid || e.audienceUuid === filter.audienceUuid) &&
            (!filter.goalUuid || e.goalUuid === filter.goalUuid) &&
            (!filter.objectiveUuid || e.objectiveUuid === filter.objectiveUuid)
        )
  )

  /**
   * Returns all goals matching the filter
   */
  const filterGoals = computed(
    () =>
      (filter: {
        // filter goals assigned to audience
        audienceUuid?: string
        // filter goals containing the objective
        objectiveUuid?: string
      }): TrainingGoal[] =>
        goals.value
          .filter(
            g => !filter.objectiveUuid || g.objectives.some(o => o.uuid === filter.objectiveUuid)
          )
          .filter(
            g =>
              !filter.audienceUuid ||
              audiences.value.some(
                a => a.uuid === filter.audienceUuid && a.goalUuids.includes(g.uuid)
              )
          )
  )

  /**
   * Returns all objectives matching the filter
   */
  const filterObjectives = computed(
    () =>
      (filter: {
        // filter objective assigned to audience
        audienceUuid?: string
        // filter objectives belonging to goal
        goalUuid?: string
      }): TrainingObjective[] =>
        goals.value
          .filter(g => !filter.goalUuid || g.uuid === filter.goalUuid)
          .filter(
            g =>
              !filter.audienceUuid ||
              audiences.value.some(
                a => a.uuid === filter.audienceUuid && a.goalUuids.includes(g.uuid)
              )
          )
          .flatMap(g => g.objectives)
  )

  /**
   * Returns the performance of the audience, goal or objective
   */
  const getPerformance = computed(
    () => (filter: { audienceUuid?: string; goalUuid?: string; objectiveUuid?: string }) => {
      const grade = filterEvaluations.value(filter).reduce((acc, e) => acc + e.grade, 0)

      let maxGrade: number | null = 0

      // if we're getting performance for one objective, we can just get the maxGrade from that objective
      if (filter.objectiveUuid) {
        const objective = findObjective.value(filter.objectiveUuid)
        if (objective) {
          maxGrade = objective.maxGrade
        }
      }
      // if we're getting performance for one goal, we need to get the maxGrade from all audiences that have this goal
      else if (filter.goalUuid && !filter.audienceUuid) {
        maxGrade = audiences.value
          .filter(a => filter.goalUuid && a.goalUuids.includes(filter.goalUuid))
          .map(a =>
            filterObjectives
              .value({ audienceUuid: a.uuid, goalUuid: filter.goalUuid })
              .reduce((acc, o) => acc + o.maxGrade, 0)
          )
          .reduce((acc, o) => acc + o, 0)
      }
      // for all other cases, we can just get the maxGrade from all objectives
      else {
        maxGrade = filterObjectives.value(filter).reduce((acc, o) => acc + o.maxGrade, 0)
      }

      return {
        grade,
        maxGrade,
      }
    }
  )

  /**
   * Adds a new audience to the store and creates it on the server
   * If the server fails to create the audience, it is removed from the store
   * @param name
   */
  async function addNewAudience(name: string) {
    const newAudience: OmitServerProps<TrainingAudience> = {
      name,
      exerciseUuid: exerciseUuid.value,
      uuid: genUUID(),
      goalUuids: [],
    }
    audiences.value.push(newAudience)

    try {
      await api.createTrainingAudience(newAudience)
    } catch (e) {
      console.error(e)
      audiences.value = audiences.value.filter(a => a.uuid !== newAudience.uuid)
      Store.dispatch('setAlert', {
        text: `Failed to create Training Audience: ${name}`,
        type: 'error',
      })
    }
  }

  /**
   * Edits an audience to the store and updates the db
   * @param audienceUuid
   * @param newName
   */
  async function editAudience(audienceUuid: string, newName: string) {
    const updatedAudience = findAudience.value(audienceUuid)
    if (!updatedAudience) {
      return
    }
    updatedAudience.name = newName
    try {
      await api.updateTrainingAudience(updatedAudience)
    } catch (e) {
      Store.dispatch('setAlert', {
        text: `Failed to update Training Audience: ${newName}`,
        type: 'error',
      })
    }
  }

  /**
   * Edits an goal name to the store and updates the db
   * @param goalUuid
   * @param newName
   */
  async function editGoalName(goalUuid: string, newName: string) {
    const updatedGoal = findGoal.value(goalUuid)
    if (!updatedGoal) {
      return
    }
    updatedGoal.name = newName
    try {
      await api.updateTrainingGoal(updatedGoal)
    } catch (e) {
      Store.dispatch('setAlert', {
        text: `Failed to update Training Goal: ${newName}`,
        type: 'error',
      })
    }
  }

  /**
   * Edits an goal description to the store and updates the db
   * @param goalUuid
   * @param newDescription
   */
  async function editGoalDescription(goalUuid: string, newDescription: string) {
    const updatedGoal = findGoal.value(goalUuid)
    if (!updatedGoal) {
      return
    }

    updatedGoal.description = newDescription

    try {
      await api.updateTrainingGoal(updatedGoal)
    } catch (e) {
      Store.dispatch('setAlert', {
        text: `Failed to update Training Goal Description: ${newDescription}`,
        type: 'error',
      })
    }
  }

  /**
   * Deletes an audience to the store and updates the db
   * @param audienceUuid
   */
  async function deleteAudience(audienceUuid: string) {
    const audienceToDelete = findAudience.value(audienceUuid)
    if (!audienceToDelete) {
      return
    }
    try {
      await api.deleteTrainingAudience(audienceToDelete.uuid)
      // remove the audience from the store
      audiences.value = audiences.value.filter(a => a.uuid !== audienceToDelete.uuid)
      // remove any associated evaluations
      evaluations.value = evaluations.value.filter(e => e.audienceUuid === audienceToDelete.uuid)
      Store.dispatch('setAlert', {
        text: `Deleted Training Audience ${audienceToDelete.name}`,
        type: 'success',
      })
    } catch (e) {
      Store.dispatch('setAlert', {
        text: `Failed to create Training Audience: ${name}`,
        type: 'error',
      })
    }
  }

  /**
   * Deletes a goal to the store and updates the db
   * @param goalUuid
   */
  async function deleteGoal(goalUuid: string) {
    const goalToDelete = findGoal.value(goalUuid)
    if (!goalToDelete) {
      return
    }
    try {
      await api.deleteTrainingGoal(goalToDelete.uuid)
      // remove goal from all audiences
      audiences.value = audiences.value.map(a => {
        a.goalUuids = a.goalUuids.filter(g => g !== goalToDelete.uuid)
        return a
      })
      // remove the goal from the store
      goals.value = goals.value.filter(g => g.uuid !== goalToDelete.uuid)

      // remove all evaluations that have that goalUuid in the store
      evaluations.value = evaluations.value.filter(e => e.goalUuid !== goalToDelete.uuid)

      Store.dispatch('setAlert', {
        text: `Deleted Training Goal ${goalToDelete.name}`,
        type: 'success',
      })
    } catch (e) {
      Store.dispatch('setAlert', {
        text: `Failed to Delete Training Goal: ${name}`,
        type: 'error',
      })
    }
  }

  /**
   * Adds a new goal to the store and creates it on the server
   * If the server fails to create the goal, it is removed from the store
   * @param name
   */
  async function addNewGoal(name: string) {
    const newGoal: OmitServerProps<TrainingGoal> = {
      name,
      exerciseUuid: exerciseUuid.value,
      uuid: genUUID(),
      objectives: [],
      description: '',
    }
    goals.value.push(newGoal)

    try {
      await api.createTrainingGoal(newGoal)
    } catch (e) {
      console.error(e)
      goals.value = goals.value.filter(g => g.uuid !== newGoal.uuid)
      Store.dispatch('setAlert', {
        text: `Failed to create Training Goal: ${name}`,
        type: 'error',
      })
    }
  }

  /**
   * Create a new goal and assign it to the audience
   *
   * @param audienceUuid
   * @param goalName
   */
  async function assignNewGoal(audienceUuid: string, goalName: string) {
    const newGoal: OmitServerProps<TrainingGoal> = {
      uuid: genUUID(),
      name: goalName,
      exerciseUuid: exerciseUuid.value,
      objectives: [],
      description: '',
    }

    const audience = audiences.value.find(a => a.uuid === audienceUuid)
    if (!audience) throw new Error('audience not found')

    goals.value.push(newGoal)
    audience.goalUuids.push(newGoal.uuid)

    try {
      await api.createTrainingGoal(newGoal)
      await api.updateTrainingAudience(audience)
      evaluations.value = await api.fetchTrainingEvaluations()
    } catch (e) {
      console.error(e)
      goals.value = goals.value.filter(g => g.uuid !== newGoal.uuid)
      audience.goalUuids = audience?.goalUuids.filter(uuid => uuid !== newGoal.uuid)
      Store.dispatch('setAlert', {
        text: `Failed to create and assign Training Goal: ${goalName}`,
        type: 'error',
      })
    }
  }

  /**
   * Assign an existing goal to the audience
   */
  async function assignExistingGoal(audienceUuid: string, goalUuid: string) {
    const audience = audiences.value.find(a => a.uuid === audienceUuid)
    if (!audience) throw new Error('audience not found')

    audience.goalUuids.push(goalUuid)

    try {
      await api.updateTrainingAudience(audience)
      evaluations.value = await api.fetchTrainingEvaluations()
    } catch (e) {
      console.error(e)
      audience.goalUuids = audience?.goalUuids.filter(uuid => uuid !== goalUuid)
      Store.dispatch('setAlert', {
        text: `Failed to assign Training Goal`,
        type: 'error',
      })
    }
  }

  /**
   * Unassign an existing goal to the audience
   */
  async function unassignExistingGoal(goalUuid: string) {
    const audience = audiences.value.find(a => a.goalUuids.includes(goalUuid))
    if (!audience) throw new Error('audience not found')
    // remove the goal from the audience
    audience.goalUuids = audience?.goalUuids.filter(uuid => uuid !== goalUuid)

    try {
      await api.updateTrainingAudience(audience)
      evaluations.value = await api.fetchTrainingEvaluations()
    } catch (e) {
      console.error(e)
      audience.goalUuids = audience?.goalUuids.filter(uuid => uuid !== goalUuid)
      Store.dispatch('setAlert', {
        text: `Failed to unassign Training Goal`,
        type: 'error',
      })
    }
  }

  /**
   * Create a new objective and assign it to the goal
   */
  async function addNewObjective(goalUuid: string, name: string) {
    const goal = goals.value.find(g => g.uuid === goalUuid)
    if (!goal) throw new Error('goal not found')

    const newObjective: TrainingObjective = {
      uuid: genUUID(),
      name,
      maxGrade: 10,
    }

    goal?.objectives.push(newObjective)

    try {
      await api.updateTrainingGoal(goal)
      evaluations.value = await api.fetchTrainingEvaluations()
      return newObjective
    } catch (e) {
      console.error(e)
      goal.objectives = goal?.objectives.filter(o => o.uuid !== newObjective.uuid)
      // evaluations.value = evaluations.value.filter(a => newAssignments.includes(a))
      Store.dispatch('setAlert', {
        text: `Failed to create Training Objective: ${name}`,
        type: 'error',
      })
    }
  }

  /**
   * Edits an objective to the store and updates the db
   * @param objectiveUuid
   * @param goalUuid
   */
  async function editObjective(goalUuid: string, updatedObjective: TrainingObjective) {
    // find the goal and objective in the store
    const goal = findGoal.value(goalUuid)
    const objective = findObjective.value(updatedObjective.uuid)
    if (!goal || !objective) {
      return
    }
    // update the objective in the store
    goal.objectives = goal.objectives.map(o => {
      if (o.uuid === updatedObjective.uuid) {
        return updatedObjective
      }
      return o
    })

    try {
      await api.updateTrainingGoalObjective(goalUuid, updatedObjective)
    } catch {
      console.log('error')
      Store.dispatch('setAlert', {
        text: `Failed to Edit Training Objective`,
        type: 'error',
      })
    }
  }

  /**
   * Deletes an objective to the store and updates the db
   * @param objectiveUuid
   * @param goalUuid
   */
  async function deleteObjective(goalUuid: string, objectiveUuid: string) {
    const objectiveToDelete = findObjective.value(objectiveUuid)
    const goalToDelete = findGoal.value(goalUuid)
    if (!objectiveToDelete || !goalToDelete) {
      return
    }
    try {
      await api.deleteTrainingGoalObjective(goalToDelete.uuid, objectiveToDelete.uuid)
      // remove the objective from the store
      goals.value = goals.value.map(goal => {
        if (goal.uuid === goalUuid && goal.objectives) {
          // Filter out the deleted objective
          goal.objectives = goal.objectives.filter(objective => objective.uuid !== objectiveUuid)
        }
        return goal
      })

      evaluations.value = await api.fetchTrainingEvaluations()

      Store.dispatch('setAlert', {
        text: `Deleted Training Objective`,
        type: 'success',
      })
    } catch (e) {
      Store.dispatch('setAlert', {
        text: `Failed to Delete Training Objective`,
        type: 'error',
      })
    }
  }

  /**
   * Update an evaluation
   */
  async function updateEvaluation(evaluation: TrainingEvaluation) {
    const foundEvaluation = findEvaluation.value({
      audienceUuid: evaluation.audienceUuid,
      goalUuid: evaluation.goalUuid,
      objectiveUuid: evaluation.objectiveUuid,
    })
    const index = evaluations.value.indexOf(foundEvaluation)

    index === -1
      ? evaluations.value.push(evaluation)
      : evaluations.value.splice(index, 1, evaluation)

    try {
      return await api.updateTrainingEvaluation(evaluation)
    } catch (e) {
      console.error(e)

      Store.dispatch('setAlert', {
        text: `Failed to update Training Evaluation`,
        type: 'error',
      })
    }
  }

  /**
   * Assign an element to an audiences training objective
   *
   * @param elementUuid
   * @param selector
   */
  async function assignElementTraining(
    elementUuid: string,
    selector: {
      audienceUuid: string
      goalUuid: string
      objectiveUuid: string | undefined
    }
  ) {
    const evaluation = findEvaluation.value(selector)
    evaluation.sourceUuids.push(elementUuid)
    try {
      await updateEvaluation(evaluation)
    } catch (e) {
      console.error(e)
      Store.dispatch('setAlert', {
        text: `Failed to assign Training to Element`,
        type: 'error',
      })
    }
  }

  async function unassignElementTraining(
    elementUuid: string,
    selector: {
      audienceUuid: string
      goalUuid: string
      objectiveUuid: string
    }
  ) {
    const evaluation = findEvaluation.value(selector)

    evaluation.sourceUuids = evaluation.sourceUuids.filter(uuid => uuid !== elementUuid)

    try {
      await updateEvaluation(evaluation)
    } catch (e) {
      console.error(e)
      Store.dispatch('setAlert', {
        text: `Failed to unassign Training from Element`,
        type: 'error',
      })
    }
  }

  return {
    audiences: readonly(audiences),
    goals: readonly(goals),
    evaluations: readonly(evaluations),
    loaded: readonly(loaded),
    findAudience,
    findGoal,
    findObjective,
    findEvaluation,
    filterEvaluations,
    filterObjectives,
    filterAudiences,
    filterGoals,
    getPerformance,
    addNewAudience,
    editAudience,
    deleteAudience,
    addNewGoal,
    editGoalName,
    deleteGoal,
    editGoalDescription,
    addNewObjective,
    editObjective,
    deleteObjective,
    assignNewGoal,
    assignExistingGoal,
    unassignExistingGoal,
    updateEvaluation,
    assignElementTraining,
    unassignElementTraining,
  }
})
