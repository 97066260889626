import { ref } from 'vue'
import { defineStore } from 'pinia'
import { ElementHistory } from '@/types/interfaces/EaseElement'

export const useElementHistoryStore = defineStore('elementHistory', () => {
  // STATE

  const elementHistory = ref<ElementHistory[] | null>(null)

  // MUTATIONS
  const showElementHistory = (element: ElementHistory[]) => {
    elementHistory.value = element
  }

  const hideElementHistory = () => {
    elementHistory.value = null
  }

  return {
    elementHistory,
    showElementHistory,
    hideElementHistory,
  }
})
