import { computed, onBeforeMount, onMounted, onUnmounted, ref } from 'vue'
import { AxiosInstance, Method } from 'axios'
import { logHeartBeat } from '@/mixins/api/logging'

export interface useHeartbeatOptions {
  /**
   * URLs to ignore for the heartbeat.
   */
  ignoredUrls?: string[]

  /**
   * Whether to call the intervalFunction immediately.
   */
  immediate?: boolean

  /**
   * Attach interceptors to a custom axios instance
   */
  axiosInstance?: AxiosInstance
}

/**
 * Performs a heartbeat to ensure the data is up-to-date.
 * This should be called within the setup() function of the root EASE component.
 *
 * This will automatically destroy the heartbeat when the component is unmounted.
 */
export default function useHeartbeat(
  intervalFunction: (lastHeartbeatUtc: string) => Promise<void>,
  interval: number,
  options: useHeartbeatOptions = {}
) {
  // flag to indicate the heartbeat is destroyed
  // when destroyed, the heartbeat will not be restarted
  // this is used to prevent the heartbeat from being restarted when navigating to another page (e.g. portal)
  let destroyed = false

  // ids to clear the heartbeat interval and axios interceptors
  let intervalId: number
  let requestInterceptorId: number
  let responseInterceptorId: number

  // keep track of the last heartbeat as we want to send the last heartbeat time to the server
  // this ensures the server knows when the last heartbeat was received
  const lastHeartbeat = ref(new Date())
  const lastHeartbeatUtc = computed(() => lastHeartbeat.value.toISOString().split('.')[0] + 'Z')

  // ignore the heartbeat and training urls. We need to ignore files/verify as well since it
  // is called by the heartbeat and would cause a loop.
  const shouldPauseHeartbeat = (url?: string) => !options.ignoredUrls?.some(u => url?.includes(u))

  onBeforeMount(async () => {
    if (options.immediate) {
      await internalIntervalFunction()
    }
  })

  // start the heartbeat and set up request and response interceptors to trigger/pause the heartbeat when appropriate
  // this is done in onMounted() so that the interceptors are not set up until the component is mounted
  onMounted(async () => {
    if (options.axiosInstance) {
      requestInterceptorId = options.axiosInstance.interceptors.request.use(config => {
        if (shouldPauseHeartbeat(config.url)) {
          stopHeartbeat()
        }
        return config
      })

      responseInterceptorId = options.axiosInstance.interceptors.response.use(async response => {
        const methods: Method[] = [
          'POST',
          'PUT',
          'PATCH',
          'DELETE',
          'post',
          'put',
          'patch',
          'delete',
        ]

        if (shouldPauseHeartbeat(response.config.url)) {
          if (response.config.method && methods.includes(response.config.method)) {
            await internalIntervalFunction()
          }
          startHeartbeat()
        }
        return response
      })
    }

    startHeartbeat()
  })

  onUnmounted(() => {
    destroyHeartbeat()
  })

  // destroy the heartbeat and axios interceptors when the user navigates to portal or logs out
  function destroyHeartbeat() {
    destroyed = true
    window.clearInterval(intervalId)

    if (options.axiosInstance) {
      options.axiosInstance.interceptors.request.eject(requestInterceptorId)
      options.axiosInstance.interceptors.response.eject(responseInterceptorId)
    }
  }

  /**
   * Start the heartbeat.
   */
  function startHeartbeat() {
    if (destroyed) return

    if (intervalId) {
      window.clearInterval(intervalId)
    }
    intervalId = window.setInterval(async () => {
      await internalIntervalFunction()
    }, interval)
  }

  /**
   * Stop the heartbeat.
   */
  function stopHeartbeat() {
    if (intervalId) {
      window.clearInterval(intervalId)
    }
  }

  /**
   * Internal function to perform the heartbeat.
   * This will log the heartbeat and call the intervalFunction.
   */
  async function internalIntervalFunction(): Promise<void> {
    try {
      await intervalFunction(lastHeartbeatUtc.value)
      logHeartBeat()
      lastHeartbeat.value = new Date()
    } catch (err) {
      destroyHeartbeat()
    }
  }
}
