// npm packages
import { AxiosRequestConfig } from 'axios'

// types
import { User } from '@/types/interfaces/General'

//  mixins
import { redirectToLogin } from '@/mixins/general/helpers'

// store/state
import Store from '@/store'
import { easeApi } from '@/api/ease.api'

/**
 * Checks if the user has a valid session with the server
 * @returns {boolean} valid - if false, redirects to the portal if the user is not logged in
 */
export const checkValidSession = async (): Promise<boolean> => {
  const response = await easeApi.get<{ valid: boolean }>('user/validate')
  if (!response.data.valid) {
    redirectToLogin()
  }
  return response.data.valid
}

/**
 * Retrieve the current users information
 */
export const fetchUser = (config?: AxiosRequestConfig): Promise<User> => {
  return easeApi.get<User>('user', config).then(res => res.data)
}

/**
 * Get details of a specific user
 */
export const getUserDetails = (userUUID: string): Promise<User> => {
  return easeApi.get(`user/${userUUID}`).then(res => res.data)
}

const defaultViewerErrorMsg = 'Account type Viewer cannot access this resource'
/**
 * Check if the current user is in view only mode
 *
 * @param {string} errorOnFalse
 * @param {boolean} showAlert
 */
export const isCurrentUserViewer = (
  errorOnFalse: string = defaultViewerErrorMsg,
  showAlert = false
): boolean => {
  const userType = Store.getters.getUser.type

  if (userType === 'viewer') {
    showAlert &&
      Store.dispatch('setAlert', {
        text: errorOnFalse,
        type: 'error',
      })
  }

  return userType === 'viewer'
}

/**
 * Send updated user information for storage in the database,
 * then get the user object with updated information from EASE API
 *
 * @param {string} infoChange
 * @param {string} infoChangeType
 */
export const updateUserInfo = (infoChange: string, infoChangeType: string): void => {
  easeApi
    .post('user/update/information', {
      data: {
        infoChange: infoChange,
        infoChangeType: infoChangeType,
      },
    })

    .then(() => {
      // Get a new user object with the updated username
      fetchUser().then((user: User) => {
        // Update the store
        Store.dispatch('setUser', user)
        // create an alert
        Store.dispatch('setAlert', {
          text: 'Successfully updated your information',
          type: 'success',
        })
      })
    })
    .catch(() => {
      Store.dispatch('setAlert', {
        text: 'Failed to update your information',
        type: 'error',
      })
    })
}

/**
 * Send a users request to update a password to EASE API
 * and update the new password in the database
 *
 * @param {string} newPassword
 * @param {string} currentPasswordInput
 */
export const updatePassword = (currentPasswordInput: string, newPassword: string): void => {
  easeApi
    .post('user/update/password', {
      data: {
        currentPasswordInput,
        newPassword,
      },
    })

    .then(() => {
      // Create an alert
      Store.dispatch('setAlert', {
        text: 'Password Successfully Changed!',
        type: 'success',
      })
    })
    .catch(() => {
      Store.dispatch('setAlert', {
        text: 'Failed to update your password',
        type: 'error',
      })
    })
}
