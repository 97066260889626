import { render, staticRenderFns } from "./ExercisePage.vue?vue&type=template&id=082b1d1e&scoped=true&"
import script from "./ExercisePage.vue?vue&type=script&lang=ts&"
export * from "./ExercisePage.vue?vue&type=script&lang=ts&"
import style0 from "./ExercisePage.vue?vue&type=style&index=0&id=082b1d1e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "082b1d1e",
  null
  
)

export default component.exports