
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ExSettingsBtn extends Vue {
  get showSettings(): boolean {
    return this.$store.getters.getActiveModal && this.$store.getters.getShowModal
  }

  handleShowSettings = (): void => {
    this.$emit('close-notification')

    this.$store.dispatch('setModal', {
      activeModal: 'Exercise Settings',
      modalVisible: true,
    })
  }
}
