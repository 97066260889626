// npm packages
// Store
import Store from '@/store'

import router from '@/router'

// Mixins
import { initActiveElement } from '@/mixins/api/activeElement'
import { initActiveLink } from '@/mixins/general/linking'

// Types
import { EaseElement } from '@/types/interfaces/EaseElement'
import { ApiTemplates, Template } from '@/types/interfaces/Template'
import { Alert } from '@/types/interfaces/General'
import { easeApi } from '@/api/ease.api'
import { ElementDataMatch } from '@/types/interfaces/Link'

// Random Text
const randomStrings = ['Hello World', 'Random Text', 'Testing', 'Placeholder text']

/**
 * description: Generate a random alert within the acceptable range of types
 * @param {string} type - or pass a type for a specific alert
 */
export const generateRandomAlert = (type: Alert['type'] = undefined): void => {
  const textOpts = [
    "Long: You're good at English when you know the difference between a man eating chicken and a man-eating chicken.",
    'We will not allow you to bring your pet armadillo along.',
    'The hand sanitizer was actually clear glue.',
    "Separation anxiety is what happens when you can't find your phone.",
  ]

  const typeColorOpts = ['success', 'error', 'info', 'warning']

  // get a random index from an array
  const randomIndex = options => options[Math.floor(Math.random() * options.length)]

  Store.dispatch('setAlert', {
    text: randomIndex(textOpts),
    type: type || randomIndex(typeColorOpts),
  })
}

/**
 * Generate a random element, option to add one with a
 * valid link as well
 *
 * @param {String[]} type array of potential types for the random element
 * @param {ElementDataMatch} linkingMatch the link to insert if linking is desired
 */
export const generateRandomElement = (
  type = ['Entity', 'Document'],
  linkingMatch: ElementDataMatch | null = null
): void => {
  // get a random template
  const templates: ApiTemplates = Store.getters.getTemplates
  let templateArray: Template[] = [...templates.builtin, ...templates.owned, ...templates.shared]
  templateArray = templateArray.filter(template => type.includes(template.metadata.type.supertype))
  const randomTemplate = templateArray[Math.floor(Math.random() * templateArray.length)]

  // init an element based off the randomly selected template
  initActiveElement(randomTemplate)

  // set the name and intent of the element
  const randomName = `${randomTemplate.metadata.name}`
  const randomIntent = `test intent for -${randomName}`
  const namePath = 'metadata.name'
  const intentPath = 'metadata.excon.intent'
  Store.dispatch('updateActiveElementByPath', { value: randomName, path: namePath })
  Store.dispatch('updateActiveElementByPath', { value: randomIntent, path: intentPath })

  // set the start date of the element
  const exStartDate = Store.getters.getExSettingByName('Start Date')
  const time = {
    start: exStartDate,
    end: null,
    type: 'date',
  }
  Store.dispatch('updateActiveElementByPath', {
    path: 'metadata.time',
    value: time,
  })

  // set the content depending on if the element is a document or entity
  if (randomTemplate.metadata.type.supertype === 'Entity') {
    const textFields = randomTemplate.fields?.filter(field => field.fieldType == 'text')
    if (!textFields) return
    Store.dispatch('updateActiveElementField', {
      content: `${randomStrings[Math.floor(Math.random() * randomStrings.length)]}`,
      name: textFields[0].name,
    })

    // if the user wanted a linked element
    if (linkingMatch && linkingMatch.data) {
      const sourceUuid = linkingMatch?.data?.indexUuid ? linkingMatch.data.indexUuid : null
      if (!sourceUuid) throw new Error('No source uuid found')
      const linkHtml = initActiveLink(linkingMatch.data, textFields[0].name, sourceUuid)
      Store.dispatch('updateActiveElementField', {
        content: linkHtml,
        name: textFields[0].name,
      })
    }
  } else if (randomTemplate.metadata.type.supertype === 'Document') {
    Store.dispatch('updateActiveElementField', {
      content: `<p>${randomStrings[Math.floor(Math.random() * randomStrings.length)]}</p>`,
      name: 'html',
    })

    // if the user wanted a linked element
    if (linkingMatch && linkingMatch.data) {
      const linkHtml = initActiveLink(linkingMatch.data, 'html', linkingMatch.data.uuid)
      Store.dispatch('updateActiveElementField', {
        content: linkHtml,
        name: 'html',
      })
    }
  }
  // submit the random element
  Store.dispatch('submitActiveElement')
}

/**
 * Seed the database from the frontend
 */
export const devSeedDB = async (): Promise<void> => {
  const currentDate = new Date()
  const currentMinutes = String(currentDate.getMinutes()).padStart(2, '0')
  await easeApi.get(`dev/initialize/${currentMinutes}`)
  setTimeout(() => {
    router.go(0)
  }, 5000)
}

/**
 * Selects a valid ElementDataMatch to send to the generateRandomElement
 * function. Calls on generateRandomElement to create a linked element.
 */
export const linkRandomElements = (): void => {
  // get elements
  const elements: EaseElement[] = Store.getters.getElements
  // only entities can be linked to so filter
  let entities = elements.filter(
    (element: EaseElement) => element.template.metadata.type.supertype === 'Entity'
  )
  // shuffle to get random link options each funtion call
  entities = shuffle(entities)

  // if no elements throw error
  if (!entities.length) {
    console.error('NO ENTITIES TO LINK TO')
    return
  }

  generateRandomElement(['Document', 'Entity'])
}

/**
 * Checks if the store has at least one element
 *
 * @return {boolean} true if an entity exists, false otherwise
 */
export const entityExists = (): boolean => {
  const elements: EaseElement[] = Store.getters.getElements
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].template.metadata.type.supertype === 'Entity') return true
  }
  return false
}

/**
 * Fisher-Yates (aka Knuth) Shuffle algorithm
 *
 * @param {Array} array the input array to shuffle
 */
const shuffle = array => {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}
