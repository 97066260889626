
import { Component, Vue } from 'vue-property-decorator'

// C O M P O N E N T S
import AdminPage from '@/components/Portal/Portal/Panes/Admin/AdminPage.vue'
import LandingPage from '@/components/Portal/Portal/Panes/LandingPage.vue'
import SupportPage from '@/components/Portal/Portal/Panes/SupportPage.vue'
import GuidePage from '@/components/Portal/Portal/Panes/GuidePage.vue'
import ExercisePage from '@/components/Portal/Portal/Panes/ExercisePage.vue'

import { validatePassword } from '@/mixins/login/login'
import { useNotificationStore } from '@/modules/notifications/store/notification-store'

// types
import { PortalPages } from '@/types/interfaces/Portal'
import { User } from '@/types/interfaces/General'

@Component({
  components: {
    AdminPage,
    LandingPage,
    SupportPage,
    GuidePage,
    ExercisePage,
  },
})
export default class H2Portal extends Vue {
  // V A R S
  activeTab = 'Landing'
  validation = false
  password = ''
  wrongPassword = false
  timeout: NodeJS.Timer | null = null
  show = false

  countdown_amount = 300 // countdown in seconds
  countdown: number | null = null

  store = useNotificationStore()

  activity(): void {
    this.countdown_amount = 300 // reset timer to 5 min when activity is detected
  }

  // clears all intervals when active tab changes from 'admin'
  clearInterval(): void {
    if (this.timeout !== null) {
      clearInterval(this.timeout)
      this.timeout = null // reset it back to null after clearing
    }

    if (this.countdown !== null) {
      clearInterval(this.countdown)
      this.countdown = null // reset it back to null after clearing
    }

    // reset countdown to 5 minutes (300 seconds)
    this.countdown_amount = 300
  }

  validateAdmin(): void {
    validatePassword(this.password).then((res: boolean): void => {
      // when the password is correct, navigate to admin

      if (res) {
        //close validation dialogue when admin is active
        this.toggleAdminDialog()
        this.activeTab = 'admin'
        this.wrongPassword = false
        this.password = ''

        // set 5 min interval to remove admin access
        this.timeout = setInterval((): void => {
          if (this.activeTab === 'admin') {
            this.activeTab = 'Landing'

            if (this.timeout !== null) {
              clearInterval(this.timeout)
              this.timeout = null // reset it back to null after clearing
            }
            if (this.countdown !== null) {
              clearInterval(this.countdown)
              this.countdown = null // reset it back to null after clearing
            }
            this.countdown_amount = 300 // reset countdown to 5 minutes (300 seconds)
          }
        }, 300000) // 5 min interval in milliseconds
      } else {
        // if wrong password is entered, show error message
        this.wrongPassword = true
      }
    })
  }

  toggleAdminDialog(): void {
    this.validation = !this.validation
    this.password = ''
    this.wrongPassword = false
  }

  get user(): User {
    return this.$store.getters.getUser
  }

  pages: PortalPages[] = [
    {
      title: 'Landing',
      component: 'LandingPage',
      icon: 'mdi-account-box',
    },
    {
      title: 'Exercises',
      component: 'ExercisePage',
      icon: 'mdi-notebook',
    },
    // {
    //   title: 'Guide/FAQs',
    //   component: 'GuidePage',
    //   icon: 'mdi-frequently-asked-questions',
    // },
    // {
    //   title: 'Support',
    //   component: 'SupportPage',
    //   icon: 'mdi-account-question',
    // },
  ]

  mounted(): void {
    this.store.fetchNotifications()
  }
}
