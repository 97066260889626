// S T O R E
import Store from '@/store/index'

// N P M   P A C K A G E S

// mixins
import { getDateInFormat, modifyTime } from '@/mixins/general/converters'
import { reformatDate } from '@/mixins/general/helpers'

// I C S
import ical from 'ical-generator'

// D O W N L O A D
import { saveAs } from 'file-saver'

import { EaseElement } from '@/types/interfaces/EaseElement'
import { CalendarEvent } from '@/types/interfaces/Calendar'

/**
 * Convert elements into calendar events if they match the
 * filter criteria from the calendar searchbar
 *
 * @param {EaseElement[]} elements
 * @param {string} eventFilter
 * @return {CalendarEvent[]} an array of all the converted calendar events
 */
export const convertElementsToEvents = (
  elements: EaseElement[] = [],
  eventFilter?: string
): CalendarEvent[] => {
  const calendarEvents: CalendarEvent[] = []

  for (let elIndex = 0; elIndex < elements.length; elIndex++) {
    const element: EaseElement = elements[elIndex]
    if (element.metadata?.time) {
      // check if the element passes the filter
      if (
        eventFilter &&
        !eventFilter
          .toLowerCase()
          .split(' ')
          .every((searchParam: string) =>
            JSON.stringify({ ...element.metadata, ...element.data })
              .toString()
              .toLowerCase()
              .includes(searchParam.toLowerCase())
          )
      ) {
        continue
      }
      try {
        // get the start timestamp from the report
        // and add the end attr with luxon
        // TODO as try catch to handle if time is broken. Not to load
        const time = element.metadata.time
        let start, end

        if (time.type === 'date') {
          start = time.start
        } else if (time.type === 'datetime') {
          if (time.start) {
            start = time.start.replace('T', ' ')
          }
          // add ten minutes so the event has height on the calendar
          end = modifyTime(start, 'yyyy-MM-dd HH:mm', 'yyyy-MM-dd HH:mm', 'minutes', 10)
        } else if (time.type === 'range') {
          start = time.start
          end = time.end
        } else if (time.type === 'rangetime' && time.start && time.end) {
          start = time.start.replace('T', ' ')
          end = time.end?.replace('T', ' ')
        }

        // return the calendar event with attrs
        calendarEvents.push({
          start,
          ...(end && { end }),
          title: element.metadata.name || element.metadata.excon.intent,
          color: element.template.metadata.style?.color,
          element,
        })
      } catch (error) {
        console.warn(error)
      }
    }
  }

  return calendarEvents
}

/**
 * Export the calendar as a .ics file after converting all calendar calendarEvents
 * into ics format
 *
 * @param {EaseElement[]} elements
 * @param {string} eventFilter
 */
export const downloadCalendar = (): void => {
  const elements: EaseElement[] = Store.getters.getElements

  // iterate reports and return them as events
  const calendar = ical({ name: 'EASE Calendar' })

  // only update if there are events
  if (elements.length !== 0) {
    elements.forEach((element: EaseElement): void => {
      const time = element.metadata.time
      //check if an element was created with time
      if (time) {
        let start = ''
        // if the start time is NOT null:
        if (time?.start) {
          // make start readable by ical
          start = time.start.replace('T', ' ')
        }

        let end: string
        // check if end is null
        if (time?.end) {
          end = time.end.replace('T', ' ')
        }
        // if start has a time add 10min
        else {
          if (start?.includes(' ')) {
            end = modifyTime(start, 'yyyy-MM-dd HH:mm', 'yyyy-MM-dd HH:mm', 'minutes', 10)
          } else {
            // give end the same date as start
            end = start
          }
        }

        // Add ics event
        calendar.events([
          {
            start,
            end,
            //if no time given then make it an all-day event
            allDay: element.metadata.time ? !element.metadata.time.type.includes('time') : true,
            description: element.metadata.excon.intent,
            summary: element.metadata.name,
            id: element.metadata.uuid,
          },
        ])
      }
    })
    const date = new Date().toISOString()
    const dateString = getDateInFormat(date, 'yyMMdd-HHmm')
    const calendarEvents = calendar.toString()
    const blob = new Blob([calendarEvents], {
      type: 'text/plain;charset=utf-8',
    })
    saveAs(blob, `${dateString}-EASE-Calendar.ics`)
  }
}

/**
 * Converts a numeric date into a string date for the calendar toolbar
 *
 * @param {boolean} isMonth
 * @param {string} date
 * @return {string} the desired date string (ex. November 29th, 2022)
 */
export const displayDate = (date: string, isMonth: boolean): string => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  let month = ''
  if (date[5] === '1') {
    month = months[parseInt(date.substring(5, 7)) - 1]
  } else {
    month = months[parseInt(date.substring(6, 7)) - 1]
  }
  const day = date.substring(8, 10)
  const year = date.substring(0, 4)
  return isMonth
    ? `${year} ${month}`
    : reformatDate(`${month} ${day} ${year}`, { calTypeStr: undefined, displayTime: false })
}
