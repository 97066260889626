
import { Component, Prop, Vue } from 'vue-property-decorator'

// types
import { ElementData } from '@/types/interfaces/EaseElement'

// mixins
import { reformatDate } from '@/mixins/general/helpers'
import { renderElementData } from '@/mixins/general/linking'

@Component
export default class ElementDataContent extends Vue {
  @Prop() elementData!: ElementData

  elementContent = 'Loading...'

  hasLinkedElement = false

  // render our element data
  renderElementData(): void {
    // call the imported function here
    let renderedData: string = renderElementData(this.elementData)

    if (renderedData.includes('class="ease-link"')) {
      this.hasLinkedElement = true
    }

    if (this.elementData.type === 'date' && typeof this.elementData.content === 'string') {
      renderedData = reformatDate(this.elementData.content)
    }

    if (this.elementData.type === 'datetime' && typeof this.elementData.content === 'string') {
      renderedData = reformatDate(this.elementData.content)
    }

    if (this.elementData.type === 'select' && Array.isArray(this.elementData.content)) {
      renderedData = this.elementData.content.join(', ')
    }

    this.elementContent = renderedData
  }

  mounted(): void {
    this.renderElementData()
  }
}
