import { SharedUser, Type } from './General'

// T E M P L A T E S
export interface Template {
  metadata: TemplateMetadata
  fields: DynamicField[]
  html?: TemplateData
}

export interface TemplateData {
  content: string
  style?: DocumentStyle
}

export const MarginUnit = {
  INCHES: 'in',
  CENTIMETERS: 'cm',
} as const
export type MarginUnit = (typeof MarginUnit)[keyof typeof MarginUnit]

export interface DocumentStyle {
  marginUnit: MarginUnit
  paddingTop: `${number}`
  paddingLeft: `${number}`
  paddingRight: `${number}`
  paddingBottom: `${number}`
  orientation: 'portrait' | 'landscape'
}

// T E M P L A T E  M E T A D A T A
export interface TemplateMetadata {
  uuid: string
  name: string
  init?: string[] // for processing files in ease-api
  disabled?: string[] // does this template require a map
  supplemental?: boolean // does this template relate to something with time
  tags: string[] // ["Report", "Intelligence"]
  version: number // floating point
  builtin?: boolean
  owned: string
  shared: SharedUser[]
  description?: {
    short: string
    long: string
  }
  type: Type
  resources?: Resource[] // an array of API methods
  style: {
    // styling the resource
    color: string
    icon: string
  }
  origin?: string
  readonly created?: string
  readonly updated?: string
}

// T E M P L A T E  R E S O U R C E S (API METHODS)
export interface Resource {
  description: string // the label for a button/option
  label: string // the name of the api endpoint
  type: string
}

// T E M P L A T E  F I E L D  T Y P E S (IN DYNAMIC FIELDS)
export const FieldTypeOptions = {
  /**
   *Represents a combo field which stores an array of strings
   */
  COMBO: 'combo',
  /**
   * Represents a text field which stores a string
   */
  TEXT: 'text',
  /**
   *Represents a image field which stores an object
   */
  IMAGE: 'image',
  /**
   *Represents a date field which stores a string of the date
   */
  DATE: 'date',
  /**
   *Represents a html field which stores a string of html
   */
  HTML: 'html',
  /**
   *Represents a time field which stores a string of the time
   */
  TIME: 'time',
  /**
   * Represents a datetime field which stores a string of the datetime
   */
  DATETIME: 'datetime',
  /**
   * Represents a select field which stores a string of the selected option
   */
  SELECT: 'select',
  /**
   * Represents a gis-line field which stores an object
   */
  GIS_LINE: 'gis-line',
  /**
   * Represents a gis-point field which stores an object
   */
  GIS_POINT: 'gis-point',
  /**
   *  Represents a gis-polygon field which stores an object
   */
  GIS_POLYGON: 'gis-polygon',
  /**
   * Represents a textarea field which stores a string
   */
  TEXTAREA: 'textarea',
  /**
   * Represents an iconPicker field which stores a string of the selected icon
   */
  ICONPICKER: 'iconPicker', // convert to `icon` only
  /**
   * Represents a checkbox field which stores a boolean
   */
  CHECKBOX: 'checkbox',
  /**
   * Represents a file field which stores an object
   */
  FILE: 'v-file-input', // convert to `file` only

  /**
   * Represents a searchable dropdown field which stores a string of the selected elements value
   */

  SEARCHABLE_DROPDOWN: 'searchable-dropdown',
} as const

export type FieldTypeOption = (typeof FieldTypeOptions)[keyof typeof FieldTypeOptions]

// T E M P L A T E    F I E L D S

export interface DynamicField {
  // required
  name: string
  fieldType: FieldTypeOption | null
  tags: string[]
  // optional
  hint?: string
  classification?: boolean
  key?: string
  format?: string // handles date/time input
  type?: string
  opts?: (string | { [key: string]: string })[]
  value?: string
  accept?: string
  hidden?: boolean
  persistentHint?: boolean
  rules?: DynamicFieldRule[]
  label?: boolean
  multiple?: boolean
  step?: boolean
  chips?: boolean
  placeholder?: string
  generator?: {
    name: string
    arguments: string[]
  }
}

export interface DynamicFieldRule {
  name: string
  message?: string
  args?: Record<string, unknown>
}

// T E M P L A T E  M E T A D A T A  F I E L D S
export interface TemplateMetadataFields {
  name: string
  fieldType: FieldTypeOption
  hint: string
  key: string
  rules?: DynamicFieldRule[]
  tags: string[]
  multiple?: boolean
  opts?: string[] | { value: string; text: string }[]
  placeholder?: string
}

// this is like an enum that's also compatible with string values
// this is to ensure we have a strict set of keys for template metadata
export const TemplateMetadataFieldKeys = {
  NAME: 'name',
  TAGS: 'tags',
  SHORT_DESCRIPTION: 'shortDescription',
  LONG_DESCRIPTION: 'longDescription',
  SUB_TYPE: 'subtype',
  STYLE: 'style',
} as const

export type TemplateMetadataFieldKey =
  (typeof TemplateMetadataFieldKeys)[keyof typeof TemplateMetadataFieldKeys]

export interface TemplateMetadataField extends DynamicField {
  key: TemplateMetadataFieldKey
}

export interface ApiTemplates {
  owned: Template[]
  shared: Template[]
  builtin: Template[]
}

/*
      ======================================================
            E N T I T Y  B U I L D E R
      ======================================================
    */

export interface additionalField {
  name: string
  fieldType: FieldTypeOption
  items?: string[]
  key: string
}

export interface additionalOptions {
  [key: string]: additionalField[]
}

export interface TemplateCardButtons {
  name: string
  icon: string
  color: string
  disabled?: boolean
  on: {
    click: () => void | Promise<void>
  }
  condition: boolean
}

export interface TemplateResponse {
  updated: string[]
  templates: ApiTemplates
}
