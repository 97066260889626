import { render, staticRenderFns } from "./ElementDataContent.vue?vue&type=template&id=077c7c28&scoped=true&"
import script from "./ElementDataContent.vue?vue&type=script&lang=ts&"
export * from "./ElementDataContent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "077c7c28",
  null
  
)

export default component.exports