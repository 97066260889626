
import { Component, Vue, Watch } from 'vue-property-decorator'

// types
import { EaseElement } from '@/types/interfaces/EaseElement'
import { Time } from '@/types/interfaces/Time'
import { CalendarEvent } from '@/types/interfaces/Calendar'

// mixins
import { reformatDate } from '@/mixins/general/helpers'
import { convertElementsToEvents } from '@/mixins/calendar/calendar'
import { rules } from '@/data/rules/rules'

import { DateTime } from 'luxon'

@Component
export default class ReleaseDate extends Vue {
  // import rules object
  rules = rules

  // date object
  startDate: string | null = null
  startTime: string | null = null
  endDate: string | null = null
  endTime: string | null = null

  timeErrorMessage: string | null = null

  calendarItems: CalendarEvent[] = []

  // computed
  updateFieldInStore(): void | Time | null {
    const validTime = this.validTime
    this.$store.dispatch('updateActiveElementByPath', {
      path: 'metadata.time',
      value: validTime,
    })
    this.$emit('update:releaseDate', validTime)
    // update the simulation links time if the active element is a inject
    if (this.activeElement.template.metadata.type.supertype === 'Document') {
      this.updateSimulationLinksTime()
    }
  }

  updateSimulationLinksTime(): void {
    const injectEditor = document.querySelector('#editor') as HTMLElement
    const simulationLinks = injectEditor.querySelectorAll(
      '.simulation-link'
    ) as NodeListOf<HTMLSpanElement>

    for (const link of simulationLinks) {
      if (!this.validTime?.start) return

      const latestUpdateTime = DateTime.fromISO(this.validTime.start, { zone: 'local' }).minus({
        minutes: 15,
      })

      const tempTime = link.getAttribute('data-update-time')
      const updateTime = tempTime ? DateTime.fromISO(tempTime, { zone: 'local' }) : null

      if (updateTime && updateTime > latestUpdateTime) {
        link.style.borderColor = 'var(--v-error-base)'
        link.setAttribute('data-update-time', '**Set update time before inject release**')
        continue
      }
    }
  }

  isValidDate(date: string | null): boolean {
    if (date === null) return true
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/
    return dateRegex.test(date)
  }

  @Watch('activeElement')
  onStartDateChange(): void {
    if (this.activeElement?.metadata?.time?.start) {
      this.startDate = this.activeElement?.metadata?.time?.start.split('T')[0]
      this.startTime =
        this.activeElement?.metadata?.time?.start.split('T').length > 1
          ? this.activeElement?.metadata?.time?.start.split('T')[1]
          : this.startTime
    }
  }

  /*
      ======================================================
                      F O R M A T S
      ======================================================
  */

  // Format Date
  dateFormat(date: string | null): string {
    if (!date) return ''
    return reformatDate(date, { calTypeStr: 'yyyy-mmm-dd', displayTime: false })
  }
  // Format Time to 24hr
  timeFormat(timeInput: string | null): string | null {
    if (!timeInput) return ''
    return timeInput
      ? DateTime.fromISO(`${timeInput}:00.000Z`, { zone: 'utc' }).toFormat('HH:mm')
      : null
  }

  /*
    ======================================================
                    G E T T E R S  &&  S E T T E R S
    ======================================================
  */

  get exStartDate(): string | null {
    return this.startDate
  }

  set exStartDate(value: string | null) {
    if (this.isValidDate(value) || value === null) {
      this.startDate = value
    }
  }

  get exEndDate(): string | null {
    return this.endDate
  }

  set exEndDate(value: string | null) {
    if (this.isValidDate(value) || value === null) {
      this.endDate = value
    }
  }

  // get active element
  get activeElement(): EaseElement {
    return this.$store.getters.getActiveElement
  }

  // ex start date
  get exDates(): { start: string; end: string } {
    const dates = this.$store.getters.getExSettings
    const start = dates['Start Date']
    const end = dates['End Date']
    return { start, end }
  }

  get validTime(): Time | null {
    const timeRegex = /^\d{2}:\d{2}$/

    const isValidTime = (time: string) => timeRegex.test(time)

    const isStartDateTimeValid = () =>
      this.startDate &&
      this.isValidDate(this.startDate) &&
      this.startTime &&
      isValidTime(this.startTime)

    const isEndDateTimeValid = () =>
      this.endDate && this.isValidDate(this.endDate) && this.endTime && isValidTime(this.endTime)

    this.timeErrorMessage = null

    if (
      this.endDate &&
      this.isValidDate(this.endDate) &&
      this.startDate &&
      this.endDate < this.startDate &&
      this.isValidDate(this.startDate)
    ) {
      this.timeErrorMessage = 'End date cannot be before start date'
      return null
    }

    if (isStartDateTimeValid() && !this.endDate && !this.endTime) {
      return {
        start: `${this.startDate}T${this.startTime}`,
        end: null,
        type: 'datetime',
      }
    }

    if (
      this.startDate &&
      this.isValidDate(this.startDate) &&
      this.endDate &&
      this.isValidDate(this.endDate) &&
      (!this.startTime || !this.endTime)
    ) {
      return {
        start: this.startDate,
        end: this.endDate,
        type: 'range',
      }
    }

    if (
      this.startDate &&
      this.isValidDate(this.startDate) &&
      (!this.startTime || !this.endDate || !this.endTime)
    ) {
      return {
        start: this.startDate,
        end: null,
        type: 'date',
      }
    }

    if (isStartDateTimeValid() && isEndDateTimeValid()) {
      return {
        start: `${this.startDate}T${this.startTime}`,
        end: `${this.endDate}T${this.endTime}`,
        type: 'rangetime',
      }
    }

    return null
  }

  // set the release date/time message to user
  get releaseMessage(): string {
    if (!this.validTime) return 'No Release Date'
    let releaseMessage = 'Releasing On: '
    const startDate = this.dateFormat(this.validTime?.start)
    const startTime = this.timeFormat(this.validTime?.start)
    const endDate = this.dateFormat(this.validTime?.end)
    const endTime = this.timeFormat(this.validTime?.end)
    if (!this.validTime) {
      releaseMessage = 'No Release Date'
    } else if (this.validTime.type === 'date') {
      releaseMessage += startDate
    } else if (this.validTime.type === 'datetime') {
      releaseMessage += `${startDate}, ${startTime}`
    } else if (this.validTime.type === 'rangetime') {
      releaseMessage += `${startDate}, ${startTime} ending at ${endDate}, ${endTime}`
    } else if (this.validTime.type === 'range') {
      releaseMessage += `${startDate}, ending on ${endDate}`
    }
    return releaseMessage
  }

  /*
      ======================================================
            B U T T O N S  U N D E R  C A L E N D A R
      ======================================================
    */
  // handle the ex dates
  handleExSettingsDates(date: string, startOrEnd: string) {
    // is the ex settings start
    if (startOrEnd === 'start') {
      this.startDate = date
      this.$emit('update:startDate', date)
    } else {
      this.endDate = date
      this.$emit('update:endDate', date)
    }
    this.updateFieldInStore()
  }

  getTodayDate(startOrEnd: string): void {
    // get the date
    const now = new Date()
    const year = now.getFullYear()
    const month = (now.getMonth() + 1).toString().padStart(2, '0')
    const day = now.getDate().toString().padStart(2, '0')

    // set the date
    if (startOrEnd === 'start') {
      this.startDate = `${year}-${month}-${day}`
    } else {
      this.endDate = `${year}-${month}-${day}`
    }

    // update the store
    this.updateFieldInStore()
  }

  // refresh the calendar by turning the new elements into calendar events
  refreshCalendar(): void {
    const arrayOfEvents = convertElementsToEvents()
    arrayOfEvents.forEach(event => {
      event.start = event.start.split('-')[2].split(' ')[0]
    })
    this.calendarItems = arrayOfEvents
  }

  // what is the width of `this` components html?
  componentWidth = 0

  /**
   * On the resize of the component, update the componentWidth var
   */
  onResize(): void {
    this.componentWidth = this.$el.clientWidth
  }

  mounted(): void {
    // initialize a resize observer to get us the width of this component
    // declare the new observer, and tell it to call onResize on... resizes
    // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
    const resizer = new ResizeObserver(this.onResize)
    // then tell it to get to work observing
    resizer.observe(this.$el)

    this.refreshCalendar()
    if (this.activeElement?.metadata?.time?.start) {
      // Datetime is stored like this:
      // 2022-02-25T09:30. We need to split the date and the time.
      // "2022-02-25T09:30".split("T") => ["2022-02-25", "09:30"].
      // "2022-02-25".split("T") => ["2022-02-25"]
      const splitTime = this.activeElement.metadata.time.start.split('T')
      this.startDate = splitTime[0]
      this.startTime = splitTime[1] ? splitTime[1] : null
    }

    if (this.activeElement?.metadata?.time?.end) {
      const splitTime = this.activeElement.metadata.time.end.split('T')
      this.endDate = splitTime[0]
      this.endTime = splitTime[1] ? splitTime[1] : null
    }
  }
}
