
import { Component, Prop, Vue } from 'vue-property-decorator'

import ArcGISMap from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'

import { addPointToLayer, zoomToLayerExtent, zoomToPoint } from '@/mixins/general/geo'

import { ParsedElementCoord } from '@/types/interfaces/Geo'

@Component({ components: { GeoMapBase: () => import('@/components/Common/Geo/GeoMapBase.vue') } })
export default class GeoDisplayCoords extends Vue {
  @Prop() coords!: ParsedElementCoord[]

  selectedPoint: ParsedElementCoord | null = null

  $refs!: {
    viewer: HTMLDivElement & { $el: HTMLDivElement }
  }

  /**
   * The following properties are used to store the map and view objects
   * they are passed to the slot from the GeoMapBase component emission
   */
  map: ArcGISMap | null = null
  view: MapView | null = null
  graphicsLayer: GraphicsLayer | null = null

  zoomToPoint(pointID: string): void {
    this.selectedPoint =
      this.coords.find((point: ParsedElementCoord) => point.id === pointID) ?? null
    this.$nextTick(() => {
      this.$refs.viewer.$el
        ?.querySelector('.zoom-target')
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    })
    if (this.view && this.graphicsLayer) zoomToPoint(this.view, this.graphicsLayer, pointID)
  }

  // highlight the specific content on tooltip
  highlightContent(selectedPoint: ParsedElementCoord): string {
    const content = selectedPoint.content as string
    const matchedCoords = selectedPoint.raw as string
    // grab the elementPoint and replace with span tags and add styles
    return content?.replace(
      matchedCoords,
      `

      <span
        style="user-select: all;"
        class="font-weight-bold
               accent--text
               secondary
               py-1
               px-2
               rounded
               zoom-target
               text-decoration-underline">
        ${matchedCoords}
      </span>


      `
    )
  }

  /**
   * When the map loads, we store the map, view, and baseLayer objects
   * Then we create a point to demo the new functionality
   */
  handleMapInit(mapData: { map: ArcGISMap; view: MapView; graphicsLayer: GraphicsLayer }): void {
    this.map = mapData.map
    this.view = mapData.view
    this.graphicsLayer = mapData.graphicsLayer

    this.coords.forEach((point: ParsedElementCoord) => {
      addPointToLayer(mapData.graphicsLayer, point.x, point.y, point.id, point.color, point.id)
    })

    Vue.nextTick().then(() => {
      zoomToLayerExtent(mapData.view, mapData.graphicsLayer)
    })
  }
}
