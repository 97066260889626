import { ActionTree } from 'vuex'
import { EaseStore } from '@/store'
import { ElementStore } from '@/store/modules/elements/index'
import { EaseElement, ElementData } from '@/types/interfaces/EaseElement'
import { DynamicField } from '@/types/interfaces/Template'

const elementActions: ActionTree<ElementStore, EaseStore> = {
  /*
    ======================================================
                          D A T A
    ======================================================
      @removeElement: remove a tool by uuid
  */

  // payload: { dataUID: string }
  removeElement(context, payload: string): void {
    context.commit('removeElement', payload)
    context.rootState.exercise.exUpdates++
  },

  setElementsUpdating(context, elementUuidsToUpdate: string[]): void {
    context.commit('setElementsUpdating', elementUuidsToUpdate)
    context.rootState.exercise.exUpdates++
  },

  setElements(context, payload: EaseElement[]): void {
    context.commit('setElements', payload)
  },

  setTrashedElements(context, payload: EaseElement[]): void {
    context.commit('setTrashedElements', payload)
  },

  updateElementObj(
    context,
    payload: {
      elementUuid: string
      audienceUuid: string
      taskUuid: string
      objectiveUuid: string
      rating: number
      keyToUpdate: string
      newValue: string | number
    }
  ): void {
    context.commit('updateElementObj', payload)
  },

  /*
      ======================================================
                    A C T I V E   E L E M E N T
      ======================================================

    */

  addNewActiveElementField(context, payload: DynamicField): void {
    context.commit('addNewActiveElementField', payload)
  },

  addNewActiveElementFieldData(context, payload: ElementData): void {
    context.commit('addNewActiveElementFieldData', payload)
  },

  updateActiveElementFieldValue(
    context,
    payload: { fieldIndex: number; value: DynamicField }
  ): void {
    context.commit('updateActiveElementFieldValue', payload)
  },

  updateActiveELementFieldData(
    context,
    payload: { fieldIndex: number; value: DynamicField }
  ): void {
    context.commit('updateActiveELementFieldData', payload)
  },

  shiftActiveElementField(context, payload: { fieldIndex: number; direction: number }) {
    context.commit('shiftActiveElementField', payload)
  },

  deleteActiveElementField(context, payload: number): void {
    context.commit('deleteActiveElementField', payload)
  },

  deleteActiveElementFieldData(context, payload: number): void {
    context.commit('deleteActiveElementFieldData', payload)
  },

  setActiveElement(context, element: EaseElement): void {
    context.commit('setActiveElement', element)
  },

  setActiveAttribute(context, payload: { name: string; indexUUID?: string }): void {
    context.commit('setActiveAttribute', payload)
  },

  updateActiveElementLink(context, payload: EaseElement): void {
    context.commit('updateActiveElementLink', payload)
  },

  removeActiveElementLink(context, payload: string): void {
    context.commit('removeActiveElementLink', payload)
  },

  resetActiveElementFieldLinks(context, payload: { fieldName: string; selfUuid: string }): void {
    context.commit('resetActiveElementFieldLinks', payload)
  },

  setSelectedElement(context, element: EaseElement): void {
    context.commit('setSelectedElement', element)
  },

  setLogLength(context, length: number): void {
    context.commit('setLogLength', length)
  },

  setEditElement(context, element: EaseElement): void {
    const templateName = element.template.metadata.name
    // but if we're editing a template, pass the existing Element
    context.commit('setActiveElement', { templateName, element })
  },

  updateActiveElement(context, payload: EaseElement): void {
    context.commit('updateActiveElement', payload)
  },

  updateActiveElementByPath(context, payload: { value: unknown; path: string }): void {
    context.commit('updateActiveElementByPath', payload)
  },

  updateElementByPath(
    context,
    payload: { element: EaseElement; value: unknown; path: string }
  ): void {
    context.commit('updateElementByPath', payload)
  },

  updateActiveDocumentHTML(context, payload: string): void {
    context.commit('updateActiveDocumentHTML', payload)
  },

  // update the content in formField
  updateActiveElementField(context, payload: { content: string; name: string }): void {
    context.commit('updateActiveElementField', payload)
  },

  updateActiveElementValidity(context): void {
    context.commit('updateActiveElementValidity')
  },

  updateActiveElementFieldValidity(context, payload: { name: string; valid: boolean }): void {
    context.commit('updateActiveElementFieldValidity', payload)
    context.commit('updateActiveElementValidity')
  },

  setActiveElementInjectStyle(context, payload: { [key: string]: string }): void {
    context.commit('setActiveElementInjectStyle', payload)
  },

  // update the classification in formField
  updateActiveElementClassification(context, payload: string): void {
    context.commit('updateActiveElementClassification', payload)
  },

  /*
    ======================================================
                  DRAG AND DROP ENTITY EDITOR
    ======================================================
      @updateDndEntityEditorElements: add or update an existing D&D Entity Editor element
      @removeDndEntityEditorElement: removes a D&D Entity Editor element by its uuid
      @resetDndEntityEditor: resets the D&D Entity Editor
  */

  setDndEntityEditorElements(context, payload: EaseElement[]): void {
    context.commit('setDndEntityEditorElements', payload)
  },

  updateDndEntityEditorElements(context, payload: EaseElement): void {
    context.commit('updateDndEntityEditorElements', payload)
  },

  removeDndEntityEditorElementByUuid(context, payload: string): void {
    context.commit('removeDndEntityEditorElementByUuid', payload)
  },

  resetDndEntityEditor(context): void {
    context.commit('resetDndEntityEditor')
  },

  submitDndEntityEditor(context): void {
    context.commit('submitDndEntityEditor')
  },
}

export default elementActions
