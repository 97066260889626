import easeStore from '@/store'
import { computed, ComputedRef, Ref } from 'vue'
import {
  EaseElement,
  ElementData,
  ElementDataImageArray,
  ElementDataStringObjArray,
  Link,
} from '@/types/interfaces/EaseElement'
import store from '@/store'
import { ElementStore } from '@/store/modules/elements'
import { ElementDataMatch, ToolTip } from '@/types/interfaces/Link'
import { FieldTypeOption, FieldTypeOptions, TemplateMetadata } from '@/types/interfaces/Template'
import { useStorage } from '@vueuse/core'

/**
 * Linkable data types should be declared here. The rest of the code will pattern match and ensure
 * all linkable types are handled
 */
const LinkableDataType = {
  COMBO: 'combo',
  TEXT: 'text',
  IMAGE: 'image',
} as const satisfies Partial<typeof FieldTypeOptions>
type LinkableDataType = (typeof LinkableDataType)[keyof typeof LinkableDataType]

type LinkableData = ElementData & { type: LinkableDataType }

interface DataNode {
  type: LinkableDataType
  entityUuid: string
  attributeUuid: string
  comboUuid?: string
}

interface SearchableDataNode extends DataNode {
  entityName: string
  dataContent: string
  description?: string
  tags: string[]
  // store any additional info needed to populate the ElementDataMatch which
  // the rest of the app expects.
  // TODO: refactor this when we fix ElementDataMatch and downstream components
  matchMetadata: {
    template: TemplateMetadata
    data: ElementData
    tooltip: ToolTip
  }
}

interface WeightedDataNode extends SearchableDataNode {
  weight: number
}

interface LinkSearchOptions {
  maxResults: number
  minCharsForSearch: number
  numberOfRecentLinks: number
}

interface UseLinkSearchReturn {
  /**
   * A reactive list of results based on the search input. Only valid results are returned and circular links are
   * filtered out.
   */
  linkSearchResults: ComputedRef<ElementDataMatch[]>
  /**
   * Called when a link is inserted to save it in a list of recent links for subsequent searches
   * @param elementDataMatch
   */
  addRecentLink: (elementDataMatch: ElementDataMatch) => void
}

// SEARCH RULES

const DEFAULT_LINK_SEARCH_OPTIONS: LinkSearchOptions = {
  maxResults: 30,
  minCharsForSearch: 2,
  numberOfRecentLinks: 10,
}

/**
 * Searches data nodes and sorts by highest relevance
 */
function performSearch(search: string, dataNodes: SearchableDataNode[]): WeightedDataNode[] {
  const weightedMatches = dataNodes.map(node => {
    let weight = 0

    const searchTokens = search.toLowerCase().split(' ')

    // match on entity name
    if (searchTokens.every(token => node.entityName.toLowerCase().includes(token))) {
      weight = 10
    }
    // match on exact text match, but not on images since dataContent is just a url
    else if (node.type != 'image' && node.dataContent.toLowerCase().includes(search)) {
      weight = 8
    }
    // match on all tokens matching (out of order)
    else if (
      node.type != 'image' &&
      searchTokens.every(token => node.dataContent.toLowerCase().includes(token))
    ) {
      weight = 5
    }
    // match on tags
    else if (searchTokens.every(token => node.tags.map(tag => tag.toLowerCase()).includes(token))) {
      weight = 3
    }
    // match on out-of-order description
    else if (searchTokens.every(token => node.description?.toLowerCase().includes(token))) {
      weight = 2
    }
    // match on description
    else if (node.description?.toLowerCase().includes(search)) {
      weight = 1
    }

    return { ...node, weight }
  })

  return weightedMatches.filter(match => match.weight > 0).sort((a, b) => b.weight - a.weight)
}

/**
 * Takes in a search ref and returns reactive search results, which are sorted based on relevance. Invalid links
 * (i.e. circular, self) links are filtered out. Note that this composable will only consider the currently active
 * element and attribute when it is first called. It will not react to changes, and therefore should only be bound
 * to a component with a lifecycle that ends when the active element/attribute changes.
 *
 * @param search the reactive search model.
 * @param options options for this composable
 */
export function useLinkSearch(
  search: Ref<string>,
  options?: Partial<LinkSearchOptions>
): UseLinkSearchReturn {
  // Type predicate to ensure a data type is linkable. Used for pattern matching
  const isLinkableDataType = (dataType: FieldTypeOption): dataType is LinkableDataType =>
    Object.values(LinkableDataType).some(linkableType => linkableType === dataType)

  // Type predicate to ensure an element data type is linkable and that it has linkable content
  const isLinkableData = (data: ElementData): data is LinkableData => {
    if (!data.type || !data.content) {
      return false
    }

    if (!isLinkableDataType(data.type)) {
      return false
    }

    switch (data.type) {
      case LinkableDataType.COMBO:
      case LinkableDataType.TEXT:
        return true
      case LinkableDataType.IMAGE:
        // Allow linking images only from documents
        return activeElement.template.metadata.type.supertype === 'Document'
      default:
        return exhaustiveGuard(data.type)
    }
  }

  // provided options merged with defaults
  const mergedOptions: LinkSearchOptions = Object.assign({}, DEFAULT_LINK_SEARCH_OPTIONS, options)

  /**
   * PREPARE NECESSARY DATA
   */

  const isDnd = store.getters.getActiveTab === 'Drag and Drop Entity Editor'

  const activeElement: EaseElement = easeStore.getters.getActiveElement

  if (!activeElement) {
    throw new Error('Unable to find active element')
  }

  // build the active node
  let activeNode: DataNode
  {
    // TODO: activeAttribute type is lying and should be refactored. It can be a string.
    const activeAttribute: ElementStore['activeAttribute'] | string =
      easeStore.getters.getActiveAttribute

    const activeAttributeName =
      typeof activeAttribute === 'string' ? activeAttribute : activeAttribute?.name
    const type = typeof activeAttribute === 'string' ? 'text' : 'combo'
    const attributeUuid = activeElement.data.find(d => d.name === activeAttributeName)?.uuid
    const comboUuid = typeof activeAttribute === 'object' ? activeAttribute.comboUuid : undefined

    if (!attributeUuid) {
      throw new Error('Unable to find uuid of currently active element data node')
    }

    activeNode = { type, entityUuid: activeElement.metadata.uuid, comboUuid, attributeUuid }
  }

  // Get all entities except the active element
  let entities: EaseElement[]
  {
    entities = easeStore.state.elements.elements.filter(
      e =>
        e.metadata.uuid != activeElement.metadata.uuid &&
        !e.metadata.trashed &&
        e.template.metadata.type.supertype === 'Entity'
    )
    if (isDnd) {
      const dndEntities = easeStore.state.elements.dndEntityEditorElements.filter(
        e =>
          e.metadata.uuid != activeElement.metadata.uuid &&
          !e.metadata.trashed &&
          e.template.metadata.type.supertype === 'Entity'
      )
      entities.push(...dndEntities)
    }
  }

  const links = entities.flatMap(e => e.links)

  /**
   * COMPUTE SEARCHABLE NODES
   */

  // get a list of banned nodes that would create a circular link to the currently active data node
  // (either an attribute or a combo item)
  // We should only need to do this once
  const disallowedNodes = getDisallowedNodes(activeNode, links)

  // flatten all attributes and combos into a single list of searchable nodes for easy searching
  const searchableDataNodes: SearchableDataNode[] = entities
    .flatMap(entity =>
      entity.data.filter(isLinkableData).flatMap(data => {
        const linkableType = data.type
        switch (linkableType) {
          case 'combo':
            return (data.content as ElementDataStringObjArray[]).map(combo =>
              createSearchableNode(data, entity, combo)
            )
          case 'text':
            return createSearchableNode(data, entity)
          case 'image':
            return (data.content as ElementDataImageArray[]).map(image =>
              createSearchableNode(data, entity, image)
            )
          default:
            return exhaustiveGuard(linkableType)
        }
      })
    )
    // filter nodes that would cause circular links
    .filter(node => !disallowedNodes.some(badNode => badNode.attributeUuid === node.attributeUuid))

  /**
   * PERFORM LINK SEARCH ON USER INPUT
   */

  const linkSearchResults = computed<ElementDataMatch[]>(() => {
    if (search.value.length < mergedOptions.minCharsForSearch) {
      // return a list of recent links instead of nothing
      return recentLinks.value.slice(0, mergedOptions.numberOfRecentLinks)
    }
    let sortedMatches = performSearch(search.value, searchableDataNodes)

    if (mergedOptions.maxResults) {
      sortedMatches = sortedMatches.slice(0, mergedOptions.maxResults)
    }

    // map the result to the ElementDataMatch that the rest of the app expects
    return sortedMatches.map<ElementDataMatch>(mapSearchableNodeToElementDataMatch)
  })

  /**
   * RECENT LINKS
   */

  // store recent links per exercise, and keep DnD links separate
  const recentLinksKey = `recentLinks${isDnd ? 'Dnd' : ''}_${store.getters.getExUUID}`
  const recentLinkMeta = useStorage<DataNode[]>(recentLinksKey, [], localStorage, { flush: 'sync' }) // use sync flush to avoid losing state on dismount

  const recentLinks = computed(() =>
    recentLinkMeta.value
      .map(recentLinkMeta =>
        searchableDataNodes.find(
          node =>
            node.entityUuid === recentLinkMeta.entityUuid &&
            node.attributeUuid === recentLinkMeta.attributeUuid &&
            node.comboUuid === recentLinkMeta.comboUuid
        )
      )
      // filter out links that no longer exist. Use a type predicate to notify TS that undefined is being filtered out
      .filter((item): item is SearchableDataNode => item !== undefined)
      .map(mapSearchableNodeToElementDataMatch)
  )

  function addRecentLink(elementDataMatch: ElementDataMatch) {
    const linkType = elementDataMatch.data?.type === 'combo' ? 'combo' : 'text'

    if (!elementDataMatch.elementUuid) {
      throw new Error('elementUuid not present in link')
    }

    if (!elementDataMatch.data?.uuid) {
      throw new Error('attributeUuid not present in link')
    }

    const newRecentLinkMeta: DataNode = {
      type: linkType,
      entityUuid: elementDataMatch.elementUuid,
      attributeUuid: elementDataMatch.data.uuid,
      comboUuid: elementDataMatch.data.indexUuid,
    }

    // using a map with a unique key will remove duplicates and also ensure the new link is added to the top
    const recentLinksMap = new Map<string, DataNode>()
    ;[newRecentLinkMeta, ...recentLinkMeta.value].forEach(link => {
      const linkKey = `${link.entityUuid}_${link.attributeUuid}_${link.comboUuid}`
      if (!recentLinksMap.has(linkKey)) {
        recentLinksMap.set(linkKey, link)
      }
    })

    // this will store results back into localstorage
    recentLinkMeta.value = [...recentLinksMap.values()].slice(0, mergedOptions.numberOfRecentLinks)
  }

  return {
    linkSearchResults,
    addRecentLink,
  }
}

/**
 * Map SearchableDataNode to ElementDataMatch
 * TODO: ElementDataMatch should be refactored as it's a legacy schema and can be simplified
 */
const mapSearchableNodeToElementDataMatch = (node: SearchableDataNode): ElementDataMatch => ({
  linkType: 'element',
  elementName: node.entityName,
  elementUuid: node.entityUuid,
  content: node.dataContent,
  description: node.description,
  data: {
    ...node.matchMetadata.data,
    elementName: node.entityName,
    indexUuid: node.comboUuid,
  },
  templateMetaData: node.matchMetadata.template,
  tooltip: node.matchMetadata.tooltip,
  linkId: `${node.entityUuid}_${node.attributeUuid}_${node.comboUuid ?? ''}`,
})

/**
 * Get nodes that would result in circular links
 * @param rootNode
 * @param links
 */
function getDisallowedNodes(rootNode: DataNode, links: Link[]) {
  const disallowedResult: DataNode[] = []
  const visited = new Set()
  const stack = [rootNode]

  // This is a depth first search which traverses the graph from the attribute we're linking to
  // It will collect all invalid nodes along the way
  while (stack.length > 0) {
    const currentNode = stack.pop()

    // Stack is empty, we're done our search
    if (!currentNode) {
      continue
    }

    // If the node has already been visited, skip it
    if (visited.has(currentNode.attributeUuid)) {
      continue
    }

    // Add current node to visited set and disallowed result
    visited.add(currentNode.attributeUuid)
    disallowedResult.push(currentNode)

    // Find links linked to the current node
    const linksToCurrentAttribute =
      currentNode.type === 'combo'
        ? links.filter(link => link.source.indexUUID === currentNode.attributeUuid)
        : links.filter(link => link.source.attributeUUID === currentNode.attributeUuid)

    // Add linked nodes to the stack
    linksToCurrentAttribute.forEach(link => {
      if (!link.self.attributeUUID) {
        throw new Error(`Link missing attributeUUID`)
      }

      stack.push({
        type: link.self.indexUUID ? 'combo' : 'text',
        entityUuid: link.self.elementUUID,
        attributeUuid: link.self.indexUUID ?? link.self.attributeUUID,
      })
    })
  }

  return disallowedResult
}

/**
 * Map entity/data/combo to searchable node
 */
function createSearchableNode(
  data: LinkableData,
  entity: EaseElement,
  arrayData?: ElementDataStringObjArray | ElementDataImageArray
): SearchableDataNode {
  let dataContent = ''
  let description = ''

  switch (data.type) {
    case 'combo':
      if (!arrayData) {
        throw new Error('Combo data not provided to createSearchableNode')
      }
      dataContent = arrayData.data
      break
    case 'text':
      if (typeof data.content !== 'string') {
        throw new Error('Text data type is not a string')
      }
      dataContent = data.content
      break
    case 'image':
      if (!arrayData) {
        throw new Error('Image data is not provided')
      }
      if ('description' in arrayData) {
        description = arrayData.description ?? ''
      }
      dataContent = arrayData.data
      break
    default:
      exhaustiveGuard(data.type)
  }

  return {
    type: data.type,
    attributeUuid: data.uuid,
    comboUuid: arrayData?.uuid,
    entityName: entity.metadata.name,
    entityUuid: entity.metadata.uuid,
    dataContent,
    description,
    tags: [...data.tags],
    matchMetadata: {
      template: entity.template.metadata,
      data,
      tooltip: {
        name: entity.metadata.name,
        intent: entity.metadata.excon.intent,
        field: data.name,
        date: entity.metadata.time ?? undefined,
        template: entity.template.metadata.name,
        color: entity.template.metadata.style?.color,
      },
    },
  }
}

/**
 * Exhaustive guard for pattern matching
 */
const exhaustiveGuard = (_value: never): never => {
  throw new Error(
    `ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(_value)}`
  )
}
