// Get all available templates
import { GetterTree } from 'vuex'
import { EaseStore } from '@/store'
import { TemplateStore } from '@/store/modules/templates/index'
import { ApiTemplates, DynamicField, Template } from '@/types/interfaces/Template'

/*
    ======================================================
                  T E M P L A T E S
    ======================================================
      @getTemplates: get all available templates
      @getDocumentTemplates: get the templates that have the metadata.type.supertype of "Document"
      @getEntityTemplates: get the templates that don't have the metadata.type.supertype of "Document"
    */

const templateGetters: GetterTree<TemplateStore, EaseStore> = {
  // Get all available Templates
  getTemplates: (state): ApiTemplates => {
    return {
      builtin: state.templates.builtin.sort((a, b) =>
        a.metadata.name.localeCompare(b.metadata.name)
      ),
      shared: state.templates.shared.sort((a, b) => a.metadata.name.localeCompare(b.metadata.name)),
      owned: state.templates.owned.sort((a, b) => a.metadata.name.localeCompare(b.metadata.name)),
    }
  },
  getActiveTemplateFields: (state): DynamicField[] => {
    if (!state.activeTemplate) return []
    return state.activeTemplate.fields
  },

  getActiveTemplate: (state): Template | null => {
    return state.activeTemplate
  },

  getFlatEntityTemplates: (state): Template[] => {
    return [...state.templates.owned, ...state.templates.shared, ...state.templates.builtin].filter(
      (template: Template) => template.metadata.type.supertype === 'Entity'
    )
  },

  getDocumentTemplates: (state): ApiTemplates => {
    // Get the templates that have the metadata.type.supertype of "Document"
    return {
      builtin: state.templates.builtin
        .filter((template: Template) => template.metadata.type.supertype === 'Document')
        .sort((a, b) => a.metadata.name.localeCompare(b.metadata.name)),

      shared: state.templates.shared
        .filter((template: Template) => template.metadata.type.supertype === 'Document')
        .sort((a, b) => a.metadata.name.localeCompare(b.metadata.name)),

      owned: state.templates.owned
        .filter((template: Template) => template.metadata.type.supertype === 'Document')
        .sort((a, b) => a.metadata.name.localeCompare(b.metadata.name)),
    }
  },

  //** temp note: FLATTEN templates into one obj - ADAM **
  getFlatTemplates: (state): Template[] => {
    return [...state.templates.owned, ...state.templates.shared, ...state.templates.builtin]
  },
  //** temp note: FLATTEN templates into one obj - ADAM **

  getEntityTemplates: (state): ApiTemplates => {
    // Get the templates that don't have the metadata.type.supertype of "Document"
    return {
      builtin: state.templates.builtin
        .filter((template: Template) => template.metadata.type.supertype === 'Entity')
        .sort((a, b) => a.metadata.name.localeCompare(b.metadata.name)),

      shared: state.templates.shared
        .filter((template: Template) => template.metadata.type.supertype === 'Entity')
        .sort((a, b) => a.metadata.name.localeCompare(b.metadata.name)),

      owned: state.templates.owned
        .filter((template: Template) => template.metadata.type.supertype === 'Entity')
        .sort((a, b) => a.metadata.name.localeCompare(b.metadata.name)),
    }
  },

  getActiveElementBuilder: (state): Template | null => state.activeTemplate,

  // autoTest || getTemplateByName || Template
  getTemplateByName:
    state =>
    (templateName: string): Template | null => {
      const templates = [
        ...state.templates.shared,
        ...state.templates.owned,
        ...state.templates.builtin,
      ]
      return (
        templates.find((template: Template | null) => template?.metadata.name === templateName) ||
        null
      )
    },
  getTemplateByUUID:
    state =>
    (templateUUID: string): Template | null => {
      const templates = [
        ...state.templates.shared,
        ...state.templates.owned,
        ...state.templates.builtin,
      ]
      return templates.find((template: Template) => template.metadata.uuid === templateUUID) || null
    },

  getLastGetTemplatesRequestTime: (state, getters, rootState): string | null =>
    rootState.misc.misc.lastGetTemplatesRequest,
}

export default templateGetters
