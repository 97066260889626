
import { Component, Vue, Prop } from 'vue-property-decorator'
//mixins
import { submitUpdateExerciseSettings, updateExconSettings } from '@/mixins/api/exercises'
//types
import { ExerciseSettings } from '@/types/interfaces/Exercise'

@Component
export default class UpdateExBtn extends Vue {
  @Prop() exSettings!: ExerciseSettings
  @Prop() newExSettings!: ExerciseSettings
  @Prop() formValid!: boolean
  @Prop() exconSettings!: { email: string; uuid: string }[]

  updateButtonDisabled(): boolean {
    return !this.formValid
  }

  get exUuid() {
    return this.$store.getters.getEx.metadata.uuid
  }

  // submit and update the new settings
  updateExerciseSettings(): void {
    updateExconSettings(this.exUuid, this.exconSettings)
    submitUpdateExerciseSettings(this.exSettings, this.newExSettings)
  }
}
