// store
import Store from '@/store'

// types
import { EaseElement, ElementApiResponse } from '@/types/interfaces/EaseElement'

// npm
import { easeApi } from '@/api/ease.api'

/*
 * submit a batch of elements (for use by the D&D Entity Editor)
 */
export const submitDndEntityEditor = (): Promise<void> => {
  // submit the elements
  return easeApi
    .post('builder_elements/batch')
    .then(() => {
      Store.dispatch('resetDndEntityEditor')
      Store.dispatch('setAlert', {
        text: 'Batch Element Creation Succeeded! Please wait to see the changes',
        type: 'success',
      })
    })
    .catch(error => {
      Store.dispatch('setAlert', {
        text: `Batch Element Creation Failed -- ${error}`,
        type: 'error',
      })
    })
}

export const resetDndEntityEditor = (): Promise<void> => {
  return easeApi
    .delete('builder_elements/reset')
    .then(() => {
      Store.dispatch('setActiveElement', null)
      Store.dispatch('resetDndEntityEditor')
      Store.dispatch('setAlert', {
        text: 'Successfully Cleared Elements',
        type: 'success',
      })
    })
    .catch(error => {
      Store.dispatch('setAlert', {
        text: `Batch Element Creation Failed -- ${error}`,
        type: 'error',
      })
    })
}

export const fetchBuilderElements = async (): Promise<ElementApiResponse> => {
  return await easeApi.get(`builder_elements`).then(res => res.data)
}

export const updateNodePosition = async (element: EaseElement): Promise<void> => {
  await easeApi.post('builder_element_position', element).catch(err => {
    console.error('Error updating node position', err)
  })
}

export const deleteBuilderElement = async (element: EaseElement): Promise<void> => {
  await easeApi
    .delete(`builder_elements/delete/${element.metadata.uuid}`)
    .then(() => {
      Store.dispatch('setAlert', {
        text: `Successfully Deleted ${element.template.metadata.name}`,
        type: 'success',
      })
    })
    .catch(err => {
      console.error('Error deleting builder element', err)
    })
}
