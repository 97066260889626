import { ExStore } from '@/store/modules/exercise/'

// we set a default store for the exercise, so that we can reset the store with this default
// when a user logs out or returns to the portal
// TODO: it seems odd to have to store a bad exercise when we go back to portal since this store shouldn't be
// in use then. We should probably allow exercise to be undefined or null
export const defaultExerciseState: ExStore = {
  // the exercise element
  ex: {
    settings: {
      Classification: '',
      'Default MGRS Grid': '',
      Description: '',
      'End Date': '',
      Name: '',
      'Short Classification': '',
      'Start Date': '',
      gisLayers: [],
      personnel: [],
      dissemination: [],
    },
    metadata: {
      uuid: '',
      created: '',
      owned: '',
      updated: '',
      shared: [],
    },
  },
  // an update counter to track/state changes
  exUpdates: 0,
  // check if the exercise loaded
  exLoaded: false,
}

const exerciseState = { ...defaultExerciseState }

export default exerciseState
