
import { Component, Vue } from 'vue-property-decorator'
import Banner from '@/components/Portal/Portal/General/Banner.vue'

@Component({
  components: {
    Banner,
  },
})
export default class SupportPage extends Vue {
  tickets = [
    {
      title: 'Cannot share templates',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac massa rhoncus, interdum nulla id, fermentum massa. Nulla faucibus sapien sit amet elementum facilisis.',
    },
    {
      title: 'Cannot share templates',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac massa rhoncus, interdum nulla id, fermentum massa. Nulla faucibus sapien sit amet elementum facilisis.',
    },
    {
      title: 'Cannot share templates',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac massa rhoncus, interdum nulla id, fermentum massa. Nulla faucibus sapien sit amet elementum facilisis.',
    },
  ]

  apps = ['EASE']
}
