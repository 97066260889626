
import { Component, Vue, Prop } from 'vue-property-decorator'
import CardTitleBar from '@/components/Common/General/CardTitleBar.vue'

@Component({
  components: {
    CardTitleBar,
  },
})
export default class NotificationHistoryModal extends Vue {
  @Prop() isOpen!: boolean
  @Prop() acceptedNotifications!: Notification[]

  dialog = false
  search = ''

  headers = [
    {
      text: 'Created Date',
      align: 'start',
      sortable: true,
      value: 'created',
    },
    {
      text: 'Message',
      align: 'start',
      sortable: false,
      value: 'message',
      width: '80%',
    },
  ]
}
