// types
// import { User, Exercise } from "@/types"

// npm

// store
import store from '@/store'
import { easeApi } from '@/api/ease.api'

export function validatePassword(currentPasswordInput: string): Promise<boolean> {
  return easeApi
    .post('user/validate/password', {
      data: {
        currentPasswordInput,
      },
    })

    .then(() => {
      // Create an alert
      store.dispatch('setAlert', {
        text: 'Logged into Admin',
        type: 'success',
      })
      return true
    })
    .catch(() => {
      store.dispatch('setAlert', {
        text: 'Incorrect Password',
        type: 'error',
      })
      return false
    })
}
