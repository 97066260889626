
import { Component, Vue } from 'vue-property-decorator'
import ElementPreview from '@/components/Common/Elements/ElementPreview.vue'
import { useTrainingAssignments } from '@/modules/training/shared/composables/training-assignment'

import { deleteElement, fetchTrashedElements, setElementTrashed } from '@/mixins/api/elements'
import { reformatDate } from '@/mixins/general/helpers'
import { getDateInFormat } from '@/mixins/general/converters'

import { EaseElement, ElementHistory } from '@/types/interfaces/EaseElement'

@Component({ components: { ElementPreview } })
export default class LogoutBtn extends Vue {
  eventPreview: { toggle: boolean; element?: EaseElement } = {
    toggle: false,
  }

  trashedElements: EaseElement[] = []

  // filter the trashed elements
  elementFilter = ''

  get filteredTrashedElements(): EaseElement[] {
    return this.elementFilter
      ? this.trashedElements.filter((element: EaseElement) => {
          return element.metadata.name.toLowerCase().includes(this.elementFilter.toLowerCase())
        })
      : this.trashedElements
  }

  // trashed elements are not updated by heartbeat.
  get trashedCount(): number {
    return this.$store.getters.getTrashedElementCount || this.trashedElements.length
  }

  async refreshTrashedElements(): Promise<void> {
    this.trashedElements = await fetchTrashedElements()
  }

  toggleEventPreview(): void {
    this.eventPreview.toggle = false
  }

  reformatDate = reformatDate

  // gets the username of the user who deleted the element
  getUserName(element: EaseElement): EaseElement {
    return this.$store.getters.getExUserByUuid(this.getLastHistory(element).user).name
  }

  // Toggles the trashed key off from the backend and then incremenets exupdates
  removeFromTrashed(element: EaseElement): void {
    setElementTrashed(element, false)

    this.closeEventPreview(element)
    this.trashedElements = this.trashedElements.filter((el: EaseElement): boolean => {
      return el.metadata.uuid !== element.metadata.uuid
    })
  }

  openEventPreview(element: EaseElement): void {
    //Opens the preview and sets the event to the selected event
    this.eventPreview = { ...this.eventPreview, toggle: true, element: element }
  }

  permanentlyDelete(element: EaseElement): void {
    deleteElement(element)

    this.closeEventPreview(element)
    useTrainingAssignments(element.metadata.uuid).unassignElement()

    this.trashedElements = this.trashedElements.filter((el: EaseElement): boolean => {
      return el.metadata.uuid !== element.metadata.uuid
    })
  }

  // Closes the preview if the removed element is the same as the current preview
  closeEventPreview(element: EaseElement): void {
    this.eventPreview.element?.metadata.uuid === element.metadata.uuid &&
      (this.eventPreview.toggle = false)
  }

  //Gets the elements last history to be displayed on the card
  getLastHistory(element: EaseElement): ElementHistory {
    if (element.metadata.history.length > 0) {
      return element.metadata.history[element.metadata.history.length - 1]
    } else {
      return { user: 'none' } as ElementHistory
    }
  }
  getLastDate(element: EaseElement): string {
    return getDateInFormat(this.getLastHistory(element).timestamp)
  }

  mounted(): void {
    this.refreshTrashedElements()
  }
}
