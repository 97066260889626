import Store from '@/store/index'

export function showShareModal(): void {
  Store.dispatch('setModal', { activeModal: 'Manage Shared Users', modalVisible: true })
}

export function showUserSettingsModal(): void {
  Store.dispatch('setModal', { activeModal: 'Manage User Settings', modalVisible: true })
}

export function showDeleteModal(): void {
  Store.dispatch('setModal', { activeModal: 'Confirm Delete Exercise', modalVisible: true })
}

export function showDuplicateModal(): void {
  Store.dispatch('setModal', { activeModal: 'Confirm Duplicate Exercise', modalVisible: true })
}

export function showNewExerciseModal(): void {
  Store.dispatch('setModal', { activeModal: 'newExerciseModal', modalVisible: true })
}
