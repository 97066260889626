
import { Component, Vue } from 'vue-property-decorator'
import VueComponent from 'vue'

import router from '@/router'
import { easeApi } from '@/api/ease.api'

@Component
export default class LoginView extends Vue {
  password: string | null = null
  username: string | null = null
  loginDisabled = false

  apiError: boolean | null = null

  $refs!: {
    password: VueComponent & {
      $refs: VueComponent & {
        input: HTMLInputElement
      }
    }
  }

  handleSubmit(): void {
    console.log('Attempting Login...')
    easeApi
      .post('user/login', {
        username: this.username,
        password: this.password,
      })
      .then(() => {
        console.log('Login Successful!')
        router.push('/portal')
      })
      .catch(() => {
        this.$store.dispatch('setAlert', {
          type: 'error',
          text: 'Invalid Login Credentials',
        })
        this.loginDisabled = true
        setTimeout(() => {
          this.loginDisabled = false
        }, 3000)
      })
  }
}
