
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CloseModal extends Vue {
  @Prop() closePopoutOpen!: boolean

  closeModal(): void {
    this.$store.dispatch('setActiveTemplate', null)
    this.$emit('closeModal')
  }
  stayOnModal(): void {
    this.$emit('stayOnModal')
  }
}
