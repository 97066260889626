import { getEaseConfig } from '@/easeConfig'
import axios, { AxiosInstance } from 'axios'

let easeApi: AxiosInstance

export function initEaseApi() {
  easeApi = axios.create({
    baseURL: getEaseConfig().EASE_API_URL,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' },
  })
}

export { easeApi }
