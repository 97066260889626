import * as coordinateFormatter from '@arcgis/core/geometry/coordinateFormatter'
import SpatialReference from '@arcgis/core/geometry/SpatialReference.js'
import Point from '@arcgis/core/geometry/Point'
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer'
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'
import Map from '@arcgis/core/Map'
import { getColorByIndex } from '@/mixins/general/generators'

// types
import { EaseElement, ElementData } from '@/types/interfaces/EaseElement'
import { SimpleRenderer } from '@arcgis/core/renderers'
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol'
import TextSymbol from '@arcgis/core/symbols/TextSymbol'
import Graphic from '@arcgis/core/Graphic'
import MapView from '@arcgis/core/views/MapView'

import {
  ParsedElementCoord,
  GeoJSONFeature,
  GeoJSONFeatureCollection,
} from '@/types/interfaces/Geo'

// store
import Store from '@/store'

// initialize the coordinate Formatter
export async function initCoordinateFormatter() {
  await coordinateFormatter.load()
}

// types
interface VisualVariable {
  type: string
  field: string
  stops: { value: number; color: string }[]
}

// G E O   R E G E X

// MGRS Regex
const mgrsRe = /\b(\d{1,2}[A-Z]{1}\s?[A-Z]{2}\s?\d{4,5}\s?\d{4,5})\b/g
// LL Regex
const latlonRe =
  // eslint-disable-next-line max-len
  /\b-?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*-?(180(\.0+)?|1[0-7]\d(\.\d+)?|\d{1,2}(\.\d+)?)\b/g

/**
 * Convert MGRS to Lat Lon or vice-versa
 * @param {string} coords - The coords to be converted
 * @param {string} inCoordType - The format to convert FROM: "mgrs" or "latlon"
 * @return {string} - The converted coords
 */
export const translateMgrsAndLatlon = (coords: string, inCoordType: 'MGRS' | 'LL'): string => {
  if (inCoordType === 'MGRS') {
    const point: Point = coordinateFormatter.fromMgrs(coords, SpatialReference.WGS84, 'automatic')
    if (point) {
      return `${point.latitude}, ${point.longitude}`
    } else {
      Store.dispatch('setAlert', {
        text: 'Could not convert MGRS to Lat Lon',
        type: 'error',
      })
      console.error('Could not convert MGRS to Lat Lon')
      return ''
    }
  } else {
    const coordsArray = coords.split(',')
    const point: Point = new Point({
      latitude: parseFloat(coordsArray[1]),
      longitude: parseFloat(coordsArray[0]),
    })

    return coordinateFormatter.toMgrs(point, 'automatic', 5, true)
  }
}

// /** REWRITE
//  * Convert LatLon to various formats.
//  * The coordinates may use decimal degrees, degrees and decimal
// minutes, or degrees, minutes, and seconds format.
//  * @param {string} ll - The latitude longitude of the point to be converted
//  * @param {string} format - should the result be a "Point", or "xy":
// an object with x/y properties. Defaults to "xy"
//  * @return {Point | {x: number, y: number}} The new Point, or an object with x/y properties
//  */
// export const transformLatlon = (ll: string, format: "Point" | "xy"): string => {
//   const coords: Point = coordinateFormatter.fromLatitudeLongitude(ll)
//   try {
//     return coordinateFormatter.toMgrs(coords, "automatic", 5, true)
//   } catch {
//     return "Cannot parse coordinates, please try again."
//   }
// }

/**
 * Convert an MGRS coordinate to various formats
 * @param {string} mgrs - The MGRS coordinate to be converted
 * @param {boolean} format - should the result be a "Point",
 * "latlon", or "xy": an object with x/y properties. Defaults to "xy"
 * @return {string | Point | {x: number, y: number}} The new Point,
 * latlon, or object with x/y properties
 */
export const convertMgrs = (
  mgrs: string,
  format: 'latlon' | 'Point' | 'xy' = 'xy'
): Point | { x: number; y: number } | string | boolean => {
  try {
    const coordPoint: Point = coordinateFormatter.fromMgrs(
      mgrs,
      SpatialReference.WGS84,
      'automatic'
    )

    const lat = parseFloat(coordPoint.latitude.toFixed(5))
    const lon = parseFloat(coordPoint.longitude.toFixed(5))

    if (format === 'Point') {
      return coordPoint
    } else if (format === 'xy') {
      return { x: lat, y: lon }
    } else {
      return `${lat}, ${lon}`
    }
  } catch {
    return false
  }
}

/**
 * Get the type of coordinates from a string
 * @param {string} coords - The coordinates to parse
 * @return {string} The type of coordinates
 */
export const getCoordType = (coords: string): 'MGRS' | 'LL' | '' => {
  let coordType: 'MGRS' | 'LL' | '' = ''

  try {
    if (coords.match(mgrsRe)) {
      coordType = 'MGRS'
    } else if (coords.match(latlonRe)) {
      coordType = 'LL'
    }
    return coordType
  } catch {
    return ''
  }
}

/**
 * Add a Grpahics Marker (pin) and Text label to an Esri map layer
 * @param {GraphicsLayer} graphicsLayer - The graphics layer to add the point to
 * @param {number} lat - The latitude of the point to be added (+ for N, - for S of the equator)
 * @param {number} lon - The longitude of the point to be added
 * (+ for E, - for W of the prime meridian)
 * @param {string} label - The text label to be added to the point. Max 2 characters
 * @param {string} pointColor - The color of the point. Defaults to red
 * @param {string} id - The ID of the point. Defaults to "point"
 * @returns { Graphic[] } The graphic points added to the layer. [0] is the point, [1] is the label
 * @example
 *
 * ```ts
 * addPointToLayer(this.graphicsLayer, -75, 45.45, "A1", "#FF5252", "A1")
 * ```
 *
 */
export const addPointToLayer = (
  graphicsLayer: GraphicsLayer,
  lon: number,
  lat: number,
  label: string,
  pointColor = 'red',
  id = 'point',
  attributes: { element: EaseElement; uuid?: string } | null = null
): Graphic[] => {
  // create the marker symbol

  const markerSymbol: SimpleMarkerSymbol = new SimpleMarkerSymbol({
    color: pointColor,
    size: '45px',
    // eslint-disable-next-line max-len
    path: 'M25.015 2.4c-7.8 0-14.121 6.204-14.121 13.854 0 7.652 14.121 32.746 14.121 32.746s14.122-25.094 14.122-32.746c0-7.65-6.325-13.854-14.122-13.854z',
    outline: {
      color: [255, 255, 255],
      width: '1.5px',
    },
    yoffset: 20,
  })

  // and the text symbol to go over it

  const textSymbol = new TextSymbol({
    color: 'white',
    haloSize: '1px',
    text: label,
    xoffset: 0,
    yoffset: 20,
    font: {
      size: 10,
      family: 'Arial',
      weight: 'bold',
    },
  })

  // and init its geometry
  const point = new Point({
    latitude: lat,
    longitude: lon,
  })

  const pointGraphicMarker = new Graphic({
    geometry: point,
    symbol: markerSymbol,
    attributes: {
      id: `${id}`,
    },
  })

  const textGraphicMarker = new Graphic({
    geometry: point,
    symbol: textSymbol,
    attributes: {
      id: `${id}`,
    },
  })

  if (attributes) {
    pointGraphicMarker.attributes = { ...pointGraphicMarker.attributes, ...attributes }
    textGraphicMarker.attributes = { ...textGraphicMarker.attributes, ...attributes }
  }

  graphicsLayer.add(pointGraphicMarker)
  graphicsLayer.add(textGraphicMarker)
  return [pointGraphicMarker, textGraphicMarker]
}

const convertToGeoJSON = (element: ParsedElementCoord): GeoJSONFeature => {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [element.x, element.y],
    },
    properties: {
      name: element.element.metadata.name,
      id: element.element.metadata.uuid,
      color: element.element.template.metadata.style.color,
    },
  }
}

export const createGeoJSONFeatureCollection = (
  elements: ParsedElementCoord[]
): GeoJSONFeatureCollection => {
  return {
    type: 'FeatureCollection',
    features: elements.map(convertToGeoJSON),
  }
}

export const addGeoLayer = (geoJsonData: GeoJSONFeatureCollection, map: Map): GeoJSONLayer => {
  // transform the javascript geo data object into a URL for the GeoJSONLayer
  const blob = new Blob([JSON.stringify(geoJsonData)], { type: 'application/json' })
  const url = URL.createObjectURL(blob)

  const symbol = new SimpleMarkerSymbol({
    size: 15,
    color: '$feature.color',
    outline: {
      width: 0.5,
      color: 'white',
    },
  })

  const renderer = new SimpleRenderer({
    symbol,
  })

  const geoJSONLayer = new GeoJSONLayer({
    url,
    renderer,
    labelingInfo: [
      {
        labelPlacement: 'center-right',
        where: '1=1', // This means "always true", so labels will be shown for all features
        labelExpressionInfo: {
          expression: '$feature.name',
        },
        symbol: {
          type: 'text',
          color: 'white',
          haloColor: 'black',
          haloSize: '1px',
          font: {
            family: 'Roboto',
            size: '10px',
            weight: 'bold',
            style: 'italic',
          },
          xoffset: '-5px',
        },
      },
    ],
    outFields: ['*'],
    featureReduction: {
      type: 'cluster',
      clusterMinSize: '30px',
      clusterMaxSize: '40px',
      clusterRadius: '40px',
      renderer: new SimpleRenderer({
        symbol: new SimpleMarkerSymbol({
          size: 15,
          color: 'blue', // Default color, overridden by visualVariables
          outline: {
            width: 0.5,
            color: 'white',
          },
        }),
        visualVariables: [
          {
            type: 'color',
            field: 'cluster_count',
            stops: [
              { value: 2, color: '#2096f3' },
              { value: 3, color: '#81C784' },
              { value: 7, color: '#81C784' },
              { value: 8, color: '#FFC107' },
              { value: 9, color: '#FFC107' },
              { value: 10, color: '#D32F2F' },
            ],
          } as VisualVariable,
        ],
      }),
      labelingInfo: [
        {
          deconflictionStrategy: 'none',
          labelExpressionInfo: {
            expression: "Text($feature.cluster_count, '#,###')",
          },
          symbol: {
            type: 'text',
            color: '#FFFFFF',
            font: {
              family: 'Roboto',
              size: '10px',
            },
          },
          labelPlacement: 'center-center',
        },
      ],
    },
  })

  map.add(geoJSONLayer)

  return geoJSONLayer
}

/**
 * Zoom to a layer's extent of its graphics
 * @param {MapView} view - The Esri map view
 * @param {GraphicsLayer} graphicsLayer - The graphics layer to zoom to
 */
export const zoomToLayerExtent = (view: MapView, graphicsLayer: GraphicsLayer): void => {
  // first bail out if there's nothing to be done
  if (!graphicsLayer || !graphicsLayer?.graphics?.length) return

  view.goTo(graphicsLayer.graphics.toArray()).catch(error => {
    if (error.name === 'view:goto-interrupted') {
      console.log('Goto action was interrupted')
    } else {
      console.error('Goto action failed', error)
    }
  })
}

/**
 * Zoom to a point by referencing its ID
 * @param {MapView} view - An Esri map view
 * @param {GraphicsLayer} layer - The graphics layer to zoom to
 * @param {string} pointID - The ID of the point to zoom to
 */
export const zoomToPoint = (view: MapView, graphicsLayer: GraphicsLayer, pointID: string): void => {
  view.goTo(graphicsLayer.graphics.toArray().find(graphic => graphic.attributes.id === pointID))
}

export const parseElementGeo = (element: EaseElement): ParsedElementCoord[] => {
  // this is dumb but we need it
  const alphabet: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

  // we'll be storing the element coordinates here
  const elementCoords: ParsedElementCoord[] = []

  // begin scanning the element
  element?.data.forEach((data: ElementData, dataIndex: number) => {
    // if the data type is hidefield stop function
    if (data.hidden) return

    if (data.content && typeof data.content === 'string') {
      // look for MGRS or LatLon coords
      // const geoMatches = [...new Set(data.content?.match(mgrsRe))]
      const geoMatches: string[] = []

      const content = data.content.replace(/&nbsp;/g, ' ')

      // match lat lng
      const llMatches = new Set(content.match(latlonRe))

      // match mgrs
      const mgrsMatches = new Set(content.match(mgrsRe))

      if (llMatches) geoMatches.push(...llMatches)
      if (mgrsMatches) geoMatches.push(...mgrsMatches)
      // if we find any...
      if (geoMatches?.length) {
        try {
          geoMatches.forEach((match: string, matchIndex: number) => {
            // get the type of coordinate so we can fill the other
            const matchType = getCoordType(match)

            // init some points
            // casting the init to Point type since the value will be casted to Point anyways
            let point = {} as Point
            let x = 0
            let y = 0
            let mgrs = ''

            // we need to break down the coords either way
            if (matchType === 'MGRS') {
              mgrs = match
              point = convertMgrs(mgrs, 'Point') as Point
              if (!point) {
                console.warn(`Invalid Coordinates: ${match}`)
                return
              }
              y = point.latitude
              x = point.longitude
            } else if (matchType === 'LL') {
              const coords = match.split(',').map((coordString: string) => coordString.trim())
              mgrs = translateMgrsAndLatlon(match, 'LL')
              point = convertMgrs(mgrs, 'Point') as Point

              y = parseFloat(coords[0])
              x = parseFloat(coords[1])
            }

            const coordData: ParsedElementCoord = {
              name: data.name,
              content: data.content as string,
              id:
                geoMatches.length > 1
                  ? `${alphabet[dataIndex]}${matchIndex + 1}`
                  : alphabet[dataIndex],
              // color will loop after 20 data attributes, but... Well if someone
              // has >20 data attributes all with geo, they can deal with it
              color: getColorByIndex(dataIndex),
              mgrs,
              point,
              x,
              y,
              type: matchType,
              raw: match,
              element,
            }

            elementCoords.push(coordData)
          })
        } catch (e) {
          console.warn(e)
        }
      }
    }
  })
  return elementCoords
}

/**
 * Convert MGRS From Default MGRS Grid in EX Settings Set Map Center
 * @return {[number, number]} - The converted coords in lat and long
 */
export const mapCenterFromExSettings = (): [number, number] => {
  try {
    // get the default MGRS grid from the ex settings
    const exSettingsMGRS = Store.getters.getExSettings['Default MGRS Grid']

    // convert mgrs to lat long
    const exSettingsLatLon = convertMgrs(exSettingsMGRS, 'Point') as Point

    // return the lat long
    return [
      parseFloat(exSettingsLatLon.longitude.toFixed(5)),
      parseFloat(exSettingsLatLon.latitude.toFixed(5)),
    ]
  } catch {
    return [-73, 43]
  }
}

/**
 * Validates a given MGRS (Military Grid Reference System) string.
 *
 * @param {string} mgrs - The MGRS string to validate.
 * @returns {boolean} - Returns `true` if the MGRS string is valid, `false` otherwise.
 */
export const validateMgrsGrid = (mgrs: string): boolean => {
  const MGRSregex = new RegExp(mgrsRe)
  return MGRSregex.test(mgrs)
}
