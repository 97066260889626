// types
import { ApiExercises, Exercise } from '@/types/interfaces/Exercise'

// npm

// store
import Store from '@/store'

// router
import router from '@/router'
import { easeApi } from '@/api/ease.api'

// pull the users exercises
// either owned by the user
// or shared to the user

export async function handleDuplicateExercises(
  exerciseUUID: string,
  exerciseName: string
): Promise<void> {
  // Let the user know we are duplicating the exercise
  Store.dispatch('setAlert', {
    text: 'Duplicating Exercise, please wait...',
    type: 'info',
  })

  try {
    await easeApi.get(`exercise/clone/${exerciseUUID}?name=${exerciseName}`)

    // nullify the activeEx
    await Store.dispatch('setActiveEx', null)

    // retrieve the new exercise list from the api, with the duplicated exercise
    const exercises: ApiExercises = await getExercises()
    await Store.dispatch('setExercises', exercises)

    // Set an alert to let the user know the exercise was duplicated
    Store.dispatch('setAlert', {
      text: 'Successfully duplicated Exercise',
      type: 'success',
    })
  } catch (error) {
    console.error(error)
    Store.dispatch('setAlert', {
      text: 'Failed to duplicate exercise',
      type: 'error',
    })
  }
}

export function getExercises(): Promise<ApiExercises> {
  return easeApi.get('exercises').then((res: { data: ApiExercises }) => {
    return res.data
  })
}

export function handleOpenExercise(exerciseUuid: string): void {
  // first tell the api which exercise UUID should be set in session
  easeApi
    .post('exercise/set', { exerciseUuid: exerciseUuid })
    // then redirect to ease-app
    .then(() => router.push('/ease'))
    .catch(error => {
      console.error(error)
      Store.dispatch('setAlert', {
        text: 'Failed to open exercise',
        type: 'error',
      })
    })
}

export function handleAcceptExerciseInvite(exerciseUUID: string): void {
  easeApi.put(`exercises/share/accept/${exerciseUUID}`).then(() => {
    getExercises().then((exercises: ApiExercises) => {
      Store.dispatch('setExercises', exercises)
      Store.dispatch('setAlert', {
        text: 'Successfully accepted Exercise Invite',
        type: 'success',
      })
    })
  })
}

export function handleDeclineExerciseInvite(exerciseUUID: string): void {
  easeApi.put(`exercises/share/decline/${exerciseUUID}`).then(() => {
    getExercises().then((exercises: ApiExercises) => {
      Store.dispatch('setExercises', exercises)
      Store.dispatch('setAlert', {
        text: 'Successfully declined Exercise invite',
        type: 'success',
      })
    })
  })
}

export function handleArchiveExercise(exerciseUUID: string, archived: boolean): void {
  easeApi.put(`exercises/archive/${exerciseUUID}`).then(() => {
    getExercises().then((exercises: ApiExercises) => {
      Store.dispatch('setExercises', exercises)
      Store.dispatch('setAlert', {
        text: !archived ? 'Exercise Successfully Archived' : 'Exercise Successfully Unarchived',
        type: 'success',
      })
    })
  })
}

/**
 * Transfer ownership of an exercise to another user
 * @param {string} userUUID - the user to transfer ownership to
 * @param {string} exerciseUUID - the exercise to transfer ownership of
 * @returns {void}
 */
export function transferOwnership(userUUID: string, exerciseUUID: string): void {
  easeApi
    .post(`exercise/transfer`, {
      data: {
        user_uuid: userUUID,
        exercise_uuid: exerciseUUID,
      },
    })
    .then(() => {
      // retrieve the exercises again
      getExercises().then((exercises: ApiExercises) => {
        Store.dispatch('setExercises', exercises)
        // Set the active exercise to the new exercise
        Store.dispatch(
          'setActiveEx',
          exercises.owned.find(ex => ex?.metadata?.uuid === exerciseUUID)
        )

        // Set an alert to let the user know the exercise was deleted
        Store.dispatch('setAlert', {
          text: 'Successfully transferred ownership',
          type: 'success',
        })
      })
    })
    .catch(error => {
      console.error(error)
      Store.dispatch('setAlert', {
        text: error.response.data,
        type: 'error',
      })

      // Get the exercises again to refresh the list
      getExercises().then((exercises: ApiExercises) => {
        // Update the exercises in state
        Store.dispatch('setExercises', exercises)
        Store.dispatch(
          'setActiveEx',
          exercises.owned.find(ex => ex?.metadata?.uuid === exerciseUUID)
        )
      })
    })
}

// Add User to an Exercise
export function addUsersToEx(username: string[], exerciseUUID: string): void {
  easeApi
    .post(`exercise/share/add`, {
      username: username,
      exercise_uuid: exerciseUUID,
    })
    .then(() => {
      // Set alert
      Store.dispatch('setAlert', {
        text: 'Exercise Invitation(s) Sent to Valid Account Username',
        type: 'success',
      })
    })
    .catch(error => {
      console.error(error)
      // Set alert
      Store.dispatch('setAlert', {
        text: 'Failed to Add Users To Exercise',
        type: 'error',
      })
    })
}

// Remove a user from an exercises
export function removeUserFromEx(userUUID: string, exerciseUUID: string): void {
  easeApi
    .post('exercise/share/remove', {
      data: {
        user_uuid: userUUID,
        exercise_uuid: exerciseUUID,
      },
    })
    .then(() => {
      // retrieve the exercises again
      getExercises().then((exercises: ApiExercises) => {
        // and close the modal
        Store.dispatch('setExercises', exercises)
        // Set the active exercise to the new exercise
        Store.dispatch(
          'setActiveEx',
          exercises.owned.find(ex => ex?.metadata?.uuid === exerciseUUID)
        )

        // Set an alert to let the user know the exercise was deleted
        Store.dispatch('setAlert', {
          text: 'Successfully removed user from exercise',
          type: 'success',
        })
      })
    })
    .catch(error => {
      console.error(error)
      // Set an alert to show the error
      Store.dispatch('setAlert', {
        text: 'Failed to remove user from exercise',
        type: 'error',
      })
    })
}

export function deleteExercise(exerciseUUID: string): void {
  // Let the user know we are deleting the exercise
  Store.dispatch('setAlert', {
    text: 'Deleting Exercise, please wait...',
    type: 'info',
  })
  // Remove the exercise from the front end
  Store.dispatch('removeExercise', exerciseUUID)

  easeApi
    .delete(`exercises/delete/${exerciseUUID}`)
    .then(() => {
      // retrieve the new exercise list from the api, with the deleted exercise removed
      getExercises().then((exercises: ApiExercises) => {
        Store.dispatch('setExercises', exercises)
      })

      // Set an alert to let the user know the exercise was deleted
      Store.dispatch('setAlert', {
        text: 'Successfully deleted exercise',
        type: 'success',
      })

      // pop the exercise from the
    })
    .catch(error => {
      console.error(error)
      // Check to see if the exercise was removed
      getExercises().then((exercises: ApiExercises) => {
        Store.dispatch('setExercises', exercises)
      })

      // Set an alert to show the error
      Store.dispatch('setAlert', {
        text: 'Failed to delete exercise',
        type: 'error',
      })
    })
}

export function leaveEx(exerciseUUID: string): void {
  easeApi
    .post(`exercises/leave/${exerciseUUID}`, {
      data: {
        exercise_uuid: exerciseUUID,
      },
    })
    .then(() => {
      // nullify the activeEx in state so state changes
      Store.dispatch('setActiveEx', null)
      // retrieve the exercises again
      getExercises().then((exercises: ApiExercises) => {
        Store.dispatch('setExercises', exercises)

        // Set an alert to let the user know the exercise was deleted
        Store.dispatch('setAlert', {
          text: 'Successfully left Exercise',
          type: 'success',
        })
      })
    })
    .catch(error => {
      console.error(error)
      // Set an alert to show the error
      Store.dispatch('setAlert', {
        text: 'Failed to leave exercise',
        type: 'error',
      })
    })
}

export function addNewExercise(newExercise: Exercise): Promise<void> {
  return easeApi
    .post('exercise', newExercise)
    .then(() => {
      getExercises().then((exercises: ApiExercises) => {
        Store.dispatch('setExercises', exercises)
      })
    })
    .catch(err => console.error(err))
}
