import { DocumentStyle, MarginUnit, Template } from '@/types/interfaces/Template'
import { EaseElement } from '@/types/interfaces/EaseElement'

export interface DocumentStyleWithPaddingUnits {
  paddingTop: `${number}${MarginUnit}`
  paddingLeft: `${number}${MarginUnit}`
  paddingBottom: `${number}${MarginUnit}`
  paddingRight: `${number}${MarginUnit}`
  orientation: DocumentStyle['orientation']
}

export const DEFAULT_DOCUMENT_STYLE: DocumentStyle = {
  paddingTop: '1',
  paddingRight: '1',
  paddingLeft: '1',
  paddingBottom: '1',
  marginUnit: 'in',
  orientation: 'portrait',
}

function getElementStyleProp<K extends keyof DocumentStyle>(
  element: EaseElement,
  styleProp: K,
  defaultValue: DocumentStyle[K]
) {
  return (
    element.data[0].style?.[styleProp] ?? element.template.html?.style?.[styleProp] ?? defaultValue
  )
}

function getTemplateStyleProp<K extends keyof DocumentStyle>(
  template: Template,
  styleProp: K,
  defaultValue: DocumentStyle[K]
) {
  return template?.html?.style?.[styleProp] ?? defaultValue
}

export function getElementStyle(element: EaseElement, defaultStyle: DocumentStyle) {
  const style = {
    marginUnit: getElementStyleProp(element, 'marginUnit', defaultStyle.marginUnit),
    paddingLeft: getElementStyleProp(element, 'paddingLeft', defaultStyle.paddingLeft),
    paddingTop: getElementStyleProp(element, 'paddingTop', defaultStyle.paddingTop),
    paddingRight: getElementStyleProp(element, 'paddingRight', defaultStyle.paddingRight),
    paddingBottom: getElementStyleProp(element, 'paddingBottom', defaultStyle.paddingBottom),
    orientation: getElementStyleProp(element, 'orientation', defaultStyle.orientation),
  }

  return {
    style,
    withPaddingUnits: () => combineMarginUnitsWithPadding(style),
  }
}

export function getTemplateStyle(template: Template, defaultStyle: DocumentStyle) {
  const style = {
    marginUnit: getTemplateStyleProp(template, 'marginUnit', defaultStyle.marginUnit),
    paddingLeft: getTemplateStyleProp(template, 'paddingLeft', defaultStyle.paddingLeft),
    paddingTop: getTemplateStyleProp(template, 'paddingTop', defaultStyle.paddingTop),
    paddingRight: getTemplateStyleProp(template, 'paddingRight', defaultStyle.paddingRight),
    paddingBottom: getTemplateStyleProp(template, 'paddingBottom', defaultStyle.paddingBottom),
    orientation: getTemplateStyleProp(template, 'orientation', defaultStyle.orientation),
  }

  return {
    style,
    withPaddingUnits: () => combineMarginUnitsWithPadding(style),
  }
}

function combineMarginUnitsWithPadding(style: DocumentStyle): DocumentStyleWithPaddingUnits {
  return {
    paddingTop: `${style.paddingTop}${style.marginUnit}`,
    paddingBottom: `${style.paddingBottom}${style.marginUnit}`,
    paddingLeft: `${style.paddingLeft}${style.marginUnit}`,
    paddingRight: `${style.paddingRight}${style.marginUnit}`,
    orientation: style.orientation,
  }
}
