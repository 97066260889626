/*
 * This file contains all the regex expressions and rules for attribute validation
 */

import { DynamicFieldRule } from '@/types/interfaces/Template'
import { Time } from '@/types/interfaces/Time'

import { isRegExp } from 'lodash'

const reMGRS =
  /\b\d{1,2}[A-Za-z]{1}\s{0,1}[A-Za-z]{1,2}\s{0,1}[A-Za-z]\s{0,1}\d{3,5}\s{0,1}\d{3,5}\b/gi

const reDD =
  /\b^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$\b/gi

const reLat = /\b^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$\b/gi

const reLng = /\b^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$\b/gi

const reIP =
  /\b^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$\b/gi

const reSourceGrading = /\b^[A-F][1-6]$\b/gi

const reMACAddress = /\b^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$\b/gi

const reUA = /\b([^:]+):\s*(\w+),\s*([^:]+):\s*(.*?),\s*Client:\s*(\w+)\b/gi

const reMAID = /\b^[\w\d]{8}-[\w\d]{4}-[\w\d]{4}-*[\w\d]{4}-[\w\d]{12}$\b/gi

const reDate = /\b^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$\b/gi

const reTime = /\b^([01][0-9]|2[0-3]):([0-5][0-9])$\b/gi

const reDateTime =
  /\b^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])T([01][0-9]|2[0-3]):([0-5][0-9])$\b/gi

/// Rule Codes:
///   regex
///   required
///   conditionallyRequired
///   nat
///   greaterEqual
///   lessEqual
///   time
///   date
///   datetime
///   sourceGrading
///   mgrs
///   lat
///   lng
///   ip
///   macAddress
///   ua
///   maid
///   dd

/*
 * Returns the function that corresponds to the rule name
 *
 * @param {DynamicFieldRule} rule
 * @return {Function} the function that corresponds to the rule name
 * @throws {Error} if the rule is not found
 */
export function getRuleFnc(
  rule: DynamicFieldRule
): (arg: string | number | Blob | Time | (string | number)[]) => boolean | string {
  // iterate through the args and check if any of them are fieldRefs (we don't handle these yet)
  // this also handles conditionallyRequired cases
  for (const key in rule.args) {
    if (Object.prototype.hasOwnProperty.call(rule.args[key], 'fieldRef')) {
      // return a function that takes a value and returns true regardless
      return () => true
    }
  }

  switch (rule.name) {
    case 'required':
      return rules.isRequired(rule.message)

    case 'regex':
      if (typeof rule.args?.regex === 'string') {
        const regex = new RegExp(rule.args.regex)
        if (isRegExp(regex)) {
          return rules.matchesRegex(regex, rule.message)
        }
      }
      break
    // case 'conditionallyRequired':
    //   if (typeof rule.args?.condition === 'boolean') {
    //     return rules.isConditionallyRequired(rule.args.condition, rule.message)
    //   }
    //   break
    case 'nat':
      return rules.isNat(rule.message)
    case 'greaterEqual':
      if (typeof rule.args?.num === 'number') {
        return rules.isGreaterEqual(rule.args?.num, rule.message)
      }
      break
    case 'lessEqual':
      // store the string as a number
      if (typeof rule.args?.num === 'number') {
        return rules.isLessEqual(Number(rule.args?.num), rule.message)
      }

      break
    case 'time':
      return rules.isTime(rule.message)
    case 'date':
      return rules.isDate(rule.message)
    case 'datetime':
      return rules.isDateTime(rule.message)
    case 'sourceGrading':
      return rules.isSourceGrading(rule.message)
    case 'mgrs':
      return rules.isMGRS(rule.message)
    case 'dd':
      return rules.isDD(rule.message)
    case 'lat':
      return rules.isLat(rule.message)
    case 'lng':
      return rules.isLng(rule.message)
    case 'ip':
      return rules.isIP(rule.message)
    case 'macAddress':
      return rules.isMACAddress(rule.message)
    case 'ua':
      return rules.isUA(rule.message)
    case 'maid':
      return rules.isMAID(rule.message)
    default:
      console.warn('Rule not found', rule)
      return () => true
  }
  return () => true
}

export const rules = {
  matchesRegex: (pattern: RegExp, message?: string) => value => {
    // test the value against the regex
    return !value || pattern.test(value) || message || 'Must match regex pattern ' + pattern
  },

  maxLength:
    (max: number, message?: string) =>
    (value: string): boolean | string => {
      if (value) {
        return value.length <= max || message || `The max characters of ${max} has been reached.`
      }
      return true
    },

  isRequired: (message?: string) => value => {
    // if the value is an array, check if it has any values
    if (Array.isArray(value)) {
      return value.length > 0 || message || 'Required'
    }
    return !!value || message || 'Required'
  },

  isConditionallyRequired: (condition: boolean, message?: string) => value => {
    return condition ? !!value || message || 'Required' : true
  },

  isNat: (message?: string) => value => {
    const isNumber = Number.isInteger(parseInt(value))

    return !value || (isNumber && parseInt(value) > 0)
      ? true
      : message || 'Must be a number greater than 0'
  },

  isLessEqual: (comparison: number, message?: string) => value => {
    const isNumber = Number.isInteger(parseInt(value))

    return !value || (isNumber && parseInt(value) <= comparison)
      ? true
      : message || `Must be a number less than or equal to ${comparison}`
  },

  isGreaterEqual: (comparison: number, message?: string) => value => {
    const isNumber = Number.isInteger(parseInt(value))

    return !value || (isNumber && parseInt(value) >= comparison)
      ? true
      : message || `Must be a number greater than or equal to ${comparison}`
  },

  isTime: (message?: string) => value => {
    return !value || new RegExp(reTime).test(value) || message || 'Must match HH:MM'
  },

  isDate: (message?: string) => value => {
    return !value || new RegExp(reDate).test(value) || message || 'Must match YYYY-MM-DD'
  },

  isDateTime: (message?: string) => value => {
    return !value || new RegExp(reDateTime).test(value) || message || 'Must match YYYY-MM-DDTHH:MM'
  },

  isSourceGrading: (message?: string) => value => {
    return !value || new RegExp(reSourceGrading).test(value) || message || 'Must match A1-F6'
  },

  isMGRS: (message?: string) => value => {
    return (
      !value ||
      new RegExp(reMGRS).test(value) ||
      message ||
      'Must match MGRS coordinate. Ex: 18TVR 44185 29464'
    )
  },

  isDD: (message?: string) => value => {
    return (
      !value ||
      new RegExp(reDD).test(value) ||
      message ||
      'Must match a Latitude, Long coordinate in Decimal Degrees format. Ex: 45.123, -45.321'
    )
  },

  isLat: (message?: string) => value => {
    return (
      !value ||
      new RegExp(reLat).test(value) ||
      message ||
      'Must match a Latitude in Decimal Degrees format. Ex: 45.123'
    )
  },

  isLng: (message?: string) => value => {
    return (
      !value ||
      new RegExp(reLng).test(value) ||
      message ||
      'Must match a Longitude in Decimal Degrees format. Ex: -45.321'
    )
  },

  isIP: (message?: string) => value => {
    return (
      !value ||
      new RegExp(reIP).test(value) ||
      message ||
      'Must match an IP address. Ex: 192.168.10.0'
    )
  },

  isMACAddress: (message?: string) => value => {
    return (
      !value ||
      new RegExp(reMACAddress).test(value) ||
      message ||
      'Must match a MAC address. Ex: 00:11:22:33:44:55'
    )
  },

  isUA: (message?: string) => value => {
    return (
      !value ||
      new RegExp(reUA).test(value) ||
      message ||
      'Must match a User Agent. Ex: Mozilla/5.0 (Windows NT 11.0; Win64; x64)'
    )
  },

  isMAID: (message?: string) => value => {
    return (
      !value ||
      new RegExp(reMAID).test(value) ||
      message ||
      'Must match a MAID. Ex: 3f097372-f01e-4b64-984c-395ae5828ee6'
    )
  },

  isBeforeDate: (date: string) => value => {
    const beforeDate = new Date(date)
    const valueDate = new Date(value)
    return !value || valueDate < beforeDate || `Must be before end date`
  },

  isAfterDate: (date: string) => value => {
    const afterDate = new Date(date)
    const valueDate = new Date(value)
    return !value || valueDate > afterDate || `Must be after start date`
  },
}
