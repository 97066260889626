
import { Component, Vue } from 'vue-property-decorator'
import Banner from '@/components/Portal/Portal/General/Banner.vue'

@Component({
  components: {
    Banner,
  },
})
export default class GuidePage extends Vue {
  apps = [{ name: 'EASE', icon: 'mdi-notebook' }]
  activeTab = 'EASE'
  tickets = [
    {
      title: 'Cannot share templates',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac massa rhoncus, interdum nulla id, fermentum massa. Nulla faucibus sapien sit amet elementum facilisis.',
    },
    {
      title: 'Cannot share templates',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac massa rhoncus, interdum nulla id, fermentum massa. Nulla faucibus sapien sit amet elementum facilisis.',
    },
    {
      title: 'Cannot share templates',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac massa rhoncus, interdum nulla id, fermentum massa. Nulla faucibus sapien sit amet elementum facilisis.',
    },
    {
      title: 'Cannot share templates',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac massa rhoncus, interdum nulla id, fermentum massa. Nulla faucibus sapien sit amet elementum facilisis.',
    },
  ]

  videos = [
    { placeholder: 'Video 1', title: 'Make an element' },
    { placeholder: 'Video 2', title: 'Make an template' },
    { placeholder: 'Video 3', title: 'Linking to document' },
    { placeholder: 'Video 4', title: 'Using the data visualizer' },
  ]
}
