import { Module } from 'vuex'
import { EaseStore } from '@/store'
import { User } from '@/types/interfaces/General'

export interface UserStore {
  // user data
  user: User
  exUsers: User[]
}

export const userModule: Module<UserStore, EaseStore> = {
  state: {
    // user
    user: {} as User,

    // The email, username, and uuid of all the users in the exercise
    exUsers: [],
  },
  getters: {
    /*
      ======================================================
                  U S E R
      ======================================================
         @getUser: get current user
         @getExUserByUuid: get user by uuid
    */
    getUser: (state): User => state.user,
    getUserSettings: (state): User['settings'] | null => state.user.settings,

    getExUserByUuid: state => (uuid: string) => {
      // Filters the users by if there key equals the value
      return (
        state.exUsers.filter(user => user !== null).find((user: User) => user.uuid === uuid) || {
          uuid: 'N/A',
          name: 'USER NOT FOUND',
          username: 'USER NOT FOUND',
          email: 'USER NOT FOUND',
        }
      )
    },

    getExUsers: (state): User[] => state.exUsers,
  },
  mutations: {
    /*
       ======================================================
                             U S E R
       ======================================================
   */

    setUser(state, payload: User): void {
      state.user = payload
    },

    setExUsers(state, payload: User[]): void {
      state.exUsers = payload
    },
  },
  actions: {
    /*
        ======================================================
                              U S E R
        ======================================================
    */

    setUser(context, payload: User): void {
      context.commit('setUser', payload)
    },

    setCurrentScrollY(context, payload: number): void {
      context.commit('setCurrentScrollY', payload)
    },

    setExUsers(context, payload: User[]): void {
      context.commit('setExUsers', payload)
    },
  },
}
